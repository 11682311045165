/*----------------------------------------------------------------------------
									FONTS
----------------------------------------------------------------------------*/
@font-face{
    font-family: 'cuprumregular';
    src: url('fonts/cuprum-regular-webfont-webfont.eot');
    src: url('fonts/cuprum-regular-webfont-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/cuprum-regular-webfont-webfont.woff') format('woff'),
         url('fonts/cuprum-regular-webfont-webfont.ttf') format('truetype'),
         url('fonts/cuprum-regular-webfont-webfont.svg#_.regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
  src: url("fonts/roboto-light.eot");
  src: url("fonts/roboto-light.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-light.woff") format("woff"), url("fonts/roboto-light.ttf") format("truetype"), url("fonts/roboto-light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0009-FFFD;
}
@font-face {
    font-family: 'Roboto';
  src: url("fonts/roboto-regular.eot");
  src: url("fonts/roboto-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-regular.woff") format("woff"), url("fonts/roboto-regular.ttf") format("truetype"), url("fonts/roboto-regular.svg#Roboto-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0009-FFFD;
}
@font-face {
    font-family: 'Roboto';
  src: url("fonts/Roboto-Medium.eot");
  src: url("fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium.woff2") format("woff2"), url("fonts/Roboto-Medium.woff") format("woff"), url("fonts/Roboto-Medium.ttf") format("truetype"), url("fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
    font-family: 'Roboto';
  src: url("fonts/roboto-bold.eot");
  src: url("fonts/roboto-bold.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-bold.woff") format("woff"), url("fonts/roboto-bold.ttf") format("truetype"), url("fonts/roboto-bold.svg#Roboto-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD;
}
@font-face {
	font-family: 'PFDinTextCompPro';
	src: url('fonts/PFDinTextCompPro-Regular.eot');
	src: url('fonts/PFDinTextCompPro-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/PFDinTextCompPro-Regular.woff') format('woff'),
		url('fonts/PFDinTextCompPro-Regular.ttf') format('truetype'),
		url('fonts/PFDinTextCompPro-Regular.svg#PFDinTextCompPro-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'PFDinTextCompPro';
	src: url('fonts/PFDinTextCompPro-Medium.eot');
	src: url('fonts/PFDinTextCompPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/PFDinTextCompPro-Medium.woff') format('woff'),
		url('fonts/PFDinTextCompPro-Medium.ttf') format('truetype'),
		url('fonts/PFDinTextCompPro-Medium.svg#PFDinTextCompPro-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}


/*----------------------------------------------------------------------------
									BODY
----------------------------------------------------------------------------*/
ul.menu li {
	margin: 0;
	padding: 0;
	list-style: none;
}
input.form-submit {
	cursor: pointer;
	-webkit-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;
}
input.form-submit:hover{
	opacity: 0.8;
}
input{
	outline: none;
	-webkit-appearance: none;
	appearance: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}
select{
	-webkit-appearance: none;
	appearance: none;
}
input.error,
textarea.error,
select.error,
input[type="text"].error,
input[type="password"].error{
	border:1px solid red !important;
}
a {
	color: #3b4047;
	outline: none;
	cursor: pointer;
	text-decoration: underline;
}
a:hover {
	text-decoration: none;
}
tbody,
img {
	border: none;
}
input {
	vertical-align: middle;
}
img{
	max-width: 100%;
	height: auto;
}
* {
	margin: 0;
	padding: 0;
	outline: none;
}
p {
	margin: 0 0 18px;
}
html {
	height: 100%;
	min-width: 1200px;
}
body {
	font: 400 14px 'Roboto', sans-serif;
	width: 100%;
	height: 100%;
	line-height: 1.3125;
	color: #3b4047;
}
.wrapper {
	width: 100%;
	margin: 0 auto;
	min-height: 100%;
	height: auto !important;
	height: 100%;
	overflow: hidden;
}
/*----------------------------------------------------------------------------
									HEADER
----------------------------------------------------------------------------*/
@mixin button_bg($color, $bg_img){
	text-transform: uppercase;
	color:$color;
	min-width: 189px;
	overflow: hidden;
	padding: 0 7px 0 36px;
	text-align: center;
	border:2px solid $color;
	box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.3);
	height: 36px;
	background: $bg_img;
	font:500 18px/36px PFDinTextCompPro, sans-serif;
	box-sizing: border-box;
}
@mixin submit{
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
	color: #fff;
	font: 500 20px "PFDinTextCompPro", sans-serif;
	text-transform: uppercase;
	background-color: #17bb49;
	height: 45px;
	cursor: pointer;
	padding: 0 24px;
	background: rgba(28,199,85,1);
	background: -moz-linear-gradient(top, rgba(28,199,85,1) 0%, rgba(19,172,57,1) 100%);
	background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(28,199,85,1)), color-stop(100%, rgba(19,172,57,1)));
	background: -webkit-linear-gradient(top, rgba(28,199,85,1) 0%, rgba(19,172,57,1) 100%);
	background: -o-linear-gradient(top, rgba(28,199,85,1) 0%, rgba(19,172,57,1) 100%);
	background: -ms-linear-gradient(top, rgba(28,199,85,1) 0%, rgba(19,172,57,1) 100%);
	background: linear-gradient(to bottom, rgba(28,199,85,1) 0%, rgba(19,172,57,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	transition: opacity .3s;
	&:hover{
		opacity: .7;
	}
}
@mixin read_more($color){
	display: inline-block;
	vertical-align: middle;
	
	text-transform: uppercase;
	text-align: center;
	color: $color;
	border: 2px solid $color;
	font: 500 18px/36px PFDinTextCompPro, sans-serif;
	height: 36px;
	background: #fff;
	overflow: hidden;
	box-sizing: border-box;
	transition: .3s;
	& a{
		text-decoration: none;
		display: block;
		color: $color;
		padding: 0 20px;
		transition: .3s;
		box-sizing: border-box;
		&:hover{
			color:#fff;
			background: $color;
		}
	}
}
.contacts_header{
	.phone_number{
		a{
			font:400 30px 'PFDinTextCompPro', sans-serif;
			text-decoration: none;
			cursor: default;
		}
	}
}
.buttons_header{	
	width: 190px;	
	.application{
		$bg: #fff url(images/edit.png) no-repeat center left 9px;
		margin-bottom: 8px;
		cursor: pointer;
		@include button_bg(#18bb49, $bg);
		transition: .3s;
		&:hover{
			transform:scale(.95);
		}
	}
	.add_advert{
		$bg: #fff url(images/plus.png) no-repeat center left 9px;
		cursor: pointer;
		margin-bottom: 10px;
		@include button_bg(#03a9f4, $bg);
		transition: .3s;
		&:hover{
			transform:scale(.95);
		}
	}
}
.header {
	padding: 9px 0 0;
	box-sizing: border-box;
	.header_content{
		max-width: 1170px;
		margin:0 auto;
		height: 95px;
		overflow: hidden;
		padding: 0 25px;
	}	
	.logo_wrapper{
		float: left;
		margin: 9px 30px 0 0;
		a.logo{
			margin-bottom: 10px;
			display: block;
			text-decoration: none;
			text-transform: uppercase;
			img{
				margin-right: 10px;
			}
			span{
				display: inline-block;
				vertical-align: bottom;
			}
			.site_name{
				color: #03a9f4;
				font: 500 41px 'PFDinTextCompPro', sans-serif;
			}
			.domen{
				font: 400 26px 'PFDinTextCompPro', sans-serif;
				color: #3b4047;
				margin-bottom: 4px;
			}
		}
	}	
	.region_header{
		float: right;
		overflow: hidden;
		& > div{
			float: left;
			& + div{
				margin-left: 44px;
			}
		}
		.address{
			margin-bottom: 3px;
		}
	}	
	.superlending &,
	.superlending_inner &{
		.region_header{
			float: none;
			.buttons_header{
				float: right;
			}
		}
		.logo_wrapper{
			margin-right: 90px;
		}
	}
	.benefits{
		width: 335px;
		.item{
			display: inline-block;
			vertical-align: middle;
			width: 48%;
			padding-right: 12px;
			box-sizing: border-box;
			&.test a{
				background: url(images/check.png) no-repeat top 0px left;
			}
			&.video a{
				background: url(images/play.png) no-repeat top 0px left;
			}
			&.plan a{
				background: url(images/plan.png) no-repeat top 4px left;
			}
			&.favorite a{
				background: url(images/star.png) no-repeat top 0px left;
			}
			a{
				text-transform: uppercase;
				font:500 16px/31px 'PFDinTextCompPro', sans-serif;
				text-decoration: none;
				display: block;
				padding-left: 34px;
				margin-bottom: 13px;
				box-sizing: border-box;
				&:hover{
					text-decoration: underline;
				}
			}
		}
	}
}
.block_content{
	max-width: 1170px;
	margin: auto;
}
.block_main_menu{
	clear: both;
	background: #03a9f4;
	height: 45px;
	// overflow: hidden;
	position: relative;
	z-index: 10;
	box-shadow: inset 0 3px 5px 0 rgba(5, 5, 5, .1);
	.menu{
		list-style: none;
		li.leaf{
			display: inline-block;
			vertical-align: middle;
			width: 16.5%;
			text-align: center;
			float: left;
			& > a{
				display: block;
				color:#fff;
				text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
				font:400 24px/48px 'PFDinTextCompPro', sans-serif;
				height: 45px;
				overflow: hidden;
				text-decoration: none;
				text-transform: uppercase;
				transition: .3s;
				&:hover,
				&.active{
					background: #0190d1;
				}
			}
		}
		.menu_child{
			display: none;
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			z-index: 10;
			background: #03a9f4;
			text-align: left;
			a{
				text-transform: none;
				height: auto;
				line-height: 1.4;
				font: 400 18px 'PFDinTextCompPro', sans-serif;
				text-decoration: none;
				color:#fff;
				display: block;
				padding: 10px 15px;
				transition: .3s;
				box-sizing: border-box;
				&:hover{
					background: #0190d1;
				}
			}
		}
		li.expanded{
			position: relative;
			&:hover{
				.menu_child{					
					display: block;
				}
			}
		}
	}
}
img.left{
	float: left;
	margin: 0 34px 18px 0;	
}
.block_seo .image{
	float: left;
}
body.repair_premises .block_seo,
.block_seo {
	clear: both;
	background: #fff;
	padding-bottom: 40px;
	border-bottom: 1px solid #cacaca;
	margin-bottom: 40px;
	box-sizing: border-box;
	.tests &,
	.real_services &{
		border-bottom: 0;
		padding-bottom: 13px;
	}
	.not-front &{
		padding-top: 42px;
		margin-bottom: 0;
	}
	.cleaning &{
		padding-top: 0;
	}
	.earth &,
	.ranking_daily &,
	.trust &,
	.services &,
	.insurance &,
	.universal &{
			background: transparent;
			padding-bottom: 0;
			border:none;
			.block_title{
				margin-bottom: 35px;
			}
	}
	.block_title{
		padding: 0 20px;
		line-height: 1;
		box-sizing: border-box;
	}
	.body{
		font:400 24px/30px 'PFDinTextCompPro', sans-serif;		
	}
	.content_body{
		height: 255px;
		overflow: hidden;
	}
	.banner{
		text-align: center;
		img{
			display: block;
			margin: auto;
		}
		.newbuilding &{
			padding: 34px 0 24px;
		}
	}
}
.installation_water,
.property_valuation,
.cleaning {	
	.block_seo{
		background:none;
		border-bottom: none;
	}
}
.block_seo {
	&.active{
		.read_more{
			display: none;
		}
		.content_body{
			height: auto!important;
			overflow: visible!important;
		}
	}
}
.body{
	p{
		margin-bottom: 8px;
	}
	ul{
		font:400 14px/20px Roboto, sans-serif;
		overflow: hidden;
		margin-top: 15px;
		li{
			position: relative;
			padding-left: 40px;
			min-height: 30px;
			margin-bottom: 22px;
			background: url(images/check.png) no-repeat top 4px left;
			box-sizing: border-box;
			&:last-child{
				margin-bottom: 14px;
			}
		}
	}
	
}
.block_articles {
	text-align: center;
	padding-bottom: 36px;
	overflow: hidden;
	clear: both;
	box-sizing: border-box;
	.view_content{
		float: left;
		width: 100%;
	}
	.block_title{
		letter-spacing: -0.2px;
		margin-bottom: 30px;
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		text-align: left;
		float: left;
		width: 270px;
		margin: 0 30px 30px 0;
		transition: .3s;
		&:hover{
			box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
		}
		&:nth-child(4n+4) {
			margin-right: 0;
		}
		.field_image{
			img{
				display: block;
				margin: auto;
			}
		}
		.views_body{
			background: #fff;
			padding: 12px 20px;
			border: 1px solid #e3e2e2;			
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
		}
		.field_title{
			a{
				font:400 24px/30px 'PFDinTextCompPro', sans-serif;
				text-decoration: none;
				margin-bottom: 13px;
				display: block;
				padding-right: 10px;
				transition: color .3s;
				box-sizing: border-box;
				&:hover{
					color:#18bb49;
					text-decoration: underline;
				}
			}
		}
		.field_body {
			line-height: 20px;
			margin-bottom: 15px;
		}
		.statistics {
			color: #89919e;
			font:400 24px 'PFDinTextCompPro', sans-serif;
			padding-left: 44px;
			background: url(images/stat.png) no-repeat top left;
		}
	}
}

/*----------------------------------------------------------------------------
									MIDDLE
----------------------------------------------------------------------------*/

.middle {
	width: 100%;
	padding: 0 0 180px;
	position: relative;
	clear: both;
	&::after {
		display: table;
		clear: both;
		content: '';
	}
	.not-front &{
		background: url(images/bg.png) repeat top center;
	}
}
.not-front .middle_inner{
	max-width: 1170px;
	margin: auto;
	padding-top: 30px;
}
.container {
	width: 100%;
	.sidebar_first &{
		float: left;
		overflow: hidden;
	}
}
.sidebar_first #content {
	padding: 0 0 0 400px;
}
.left_sidebar {
    float: left;
    width: 370px;
    margin-left: -100%;
    position: relative;
    .block {
    	.views_row{
    		width: 100%;
    		margin: 0 0 30px;
    	}
    	.block_title{
    		font-size: 36px;
    		font-weight: 500;
    		text-align: left;
    		margin-bottom: 10px;
    	}
    }
    .block_left_menu{
    	margin-bottom: 30px;
    	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    	a{
    		display: block;
    		text-decoration: none;
    		background:#fff;
    		height: 48px;
    		border-bottom: 1px solid #d4d4d4;
    		font:400 24px/48px 'PFDinTextCompPro', sans-serif;    	
    		padding: 0 16px 0 19px;	
    		transition: color .3s, background .3s;
    		box-sizing: border-box;
    		&:hover,
    		&.active{
    			color:#fff;
    			background: #18bb49;
    			border-bottom-color: transparent;
    			span{
    				opacity: 0.5;
    				color: #ffffff;
    			}
    		}
    		span{
    			color: #89919e;
    			float: right;
    			display: inline-block;
    			vertical-align: middle;
    		}
    	}
    }
    .banner{
    	background: #fff;
    	padding: 22px 0;
    	margin-bottom: 35px;
    	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    	box-sizing: border-box;
    	.field_image {
    		img{
    			display: block;
    			margin: auto;
    		}
    	}
    }
}
.property_search{
	background: #fff;
	padding: 28px 29px;
	margin-bottom: 33px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.form_title{
		text-transform: uppercase;
		color: #3b4047;
		font:500 36px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 15px;
	}
	.webform_client_form{
		.form_wrapper{
			margin-bottom: 17px;
			.form_checkbox{
				width: 100%;
				& + .form_checkbox{
					margin-top: 15px;
				}
			}
		}
	}
}
.right_sidebar {
    float: left;
    width: 240px;
    margin-left: -240px;
    position: relative;
}
.slider_main{
	text-align: center;
	padding: 65px 0 50px;
	color:#fff;
	background: url(images/bg_slide.png) no-repeat center / cover;
	box-sizing: border-box;
	.block_content{
		position: relative;
	}
	.test_result & {
		margin-bottom: 30px;
	}
	body.articles &{
		background: url(images/banner_11.jpg) no-repeat center / cover;
		margin-bottom: 15px;
	}
	body.test_passing &{
		background: url(images/banner_4.jpg) no-repeat center / cover;
		padding: 30px;
		.slider_title{
			margin-bottom: 0;
		}
	}
	body.articles_inner &,
	body.final &{
		background: url(images/banner_12.jpg) no-repeat center / cover;		
	}
	body.articles_inner & {
		.slider_title{
			margin-bottom: 40px;
		}
	}
	body.final &{
		padding-top: 28px;
		margin-bottom: 35px;
		.slider_title{
			padding-bottom: 28px;
			position: relative;
			margin-bottom: 28px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				background: #fff;
				height: 3px;
				width: 220px;
				margin-left: -110px;
			}
		}
		.top_inner_content .title{
			margin-bottom: 20px;
		}
		.button_wrap{
			position: absolute;
			top: 50%;
			right: 0;
			transform:translateY(-50%);
			a{
				display: block;
				width: 127px;
				text-transform: uppercase;
				text-decoration: none;
				border:2px solid #fff;
				height: 36px;
				color:#fff;
				font:500 18px 'PFDinTextCompPro', sans-serif;
				margin-bottom: 30px;
				line-height: 36px;
				box-sizing: border-box;
				transition: opacity .3s;	
				&:hover{
					opacity: .7;
				}
			}
		}
	}
	body.favorites &{
		background: url(images/banner_14.jpg) no-repeat center / cover;		
		padding-bottom: 17px;
	}
	body.earth &{
		background: url(images/banner_3.png) no-repeat center / cover;		
	}
	body.tests &{
		background: url(images/banner_test.png) no-repeat center / cover;	
		padding-bottom: 1px;	
	
	}
	body.test_result &,
	body.test_passing &{
		background: url(images/banner_test2.jpg) no-repeat center / cover;	
		padding-bottom: 35px;
	}
	body.test_passing &{
		padding-bottom: 180px;
	}
	body.video_inner &{
		padding-top: 42px;
	}
	body.superlending_inner &{
		background: url(images/banner_20.jpg) no-repeat center / cover;
	}
	body.video_transfer &{
		background: url(images/banner_13.jpg) no-repeat center / cover;
	}
	body.cleaning &{
		background: url(images/banner_9.jpg) no-repeat center / cover;
	}
	body.real_services &{
		background: url(images/banner_17.jpg) no-repeat center / cover;
		padding: 43px 0 71px;
		.webform_client_form{
			max-width: 739px;
			.form_item{
				margin-right: 6px;
				margin-bottom: 10px;
				&.email{
					margin-right: 0;
				}
			}
		}
	}	
	.slider_title{
		text-transform: uppercase;
		font:400 64px/64px 'PFDinTextCompPro', sans-serif;
		padding-bottom: 17px;
		position: relative;
		max-width: 1170px;
		margin: 0 auto 20px;
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			background: #fff;
			height: 5px;
			width: 102px;
			margin-left: -51px;
		}
	}
	&.slider_notfront {
		padding: 22px 0 36px;
		background-image: url(images/bg_slide_notfr.png);
		.slider_title{
			text-transform: none;
			padding: 0;
			&::after{
				content: none;
			}
		}
	}
	.ranking_daily &,
	.multilending &,
	.superlending &,
	.process &{
		background-image: url(images/banner_3.jpg);
		padding: 50px 0 58px;
		.slider_title{
			padding-bottom: 17px;
			position: relative;
			margin-bottom: 20px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				background: #fff;
				height: 3px;
				width: 220px;
				margin-left: -110px;
			}
		}
		.title_text{
			margin-bottom: 13px;
		}
		.form_item{
			width: 220px;
			margin-right: 20px!important;
		}
	}
	body.multilending &{
		background: url(images/banner_18.jpg) no-repeat center / cover;
		padding: 83px 0 177px;
		.slider_subtitle{
			text-transform: uppercase;
			margin-bottom: 50px;
		}
		.slider_title{
			padding-bottom: 27px;
			margin-bottom: 30px;
		}
	}
	body.superlending &{
		background: url(images/banner_19.jpg) no-repeat center / cover;
		padding: 83px 0 177px;
		.slider_subtitle{
			text-transform: uppercase;
			margin-bottom: 50px;
		}
		.slider_title{
			padding-bottom: 27px;
			margin-bottom: 30px;
		}
	}
	.newbuilding_inner &{
		background: url(images/banner_new.jpg) no-repeat center / cover !important;
		padding-bottom: 184px;
	}
	.apartment &{
		padding-bottom: 184px;
		background: url(images/banner_21.jpg) no-repeat center / cover;
	}
	.daily_inner &{
		padding-bottom: 184px;		
		background: url(images/banner_22.jpg) no-repeat center / cover;
	}
	.installation_water &{
		background: url(images/banner_25.jpg) no-repeat center / cover !important;
		.title_text{
			margin-bottom: 15px;
		}
	}
	.newbuilding &{
		background: url(images/banner_5.jpg) no-repeat center / cover;
	}
	.contacts &{
		background: url(images/banner_contacts.jpg) no-repeat center / cover;
		padding: 45px 0 70px;
		.webform_client_form {
			.form_actions {
				width: 100%;					
				input{
					width: 100%;					
				}
			}
			.form_item.name{
				input{
					background-image: url(images/user.png);
				}
			}

		}
		.top_inner_content .title{
			margin-bottom: 55px;
		}
	}
	.process &{
		background: url(images/banner_23.jpg) no-repeat center / cover;
		.slider_title{
			padding-bottom: 23px;
			margin-bottom: 55px;
		}
		.form_item{
			width: 335px;
			&.form_select{
				select{
					height: 45px;
					background: #fff url("images/select.png") no-repeat center right 25px;
					border: none;
				}
			}
		}
	}
	.video_inner &{
		background: url(images/banner_15.jpg) no-repeat center / cover;
		padding-bottom: 184px;
		.slider_title{
			margin-bottom: 35px;
		}
	}
	.trust &,
	.installation_water &,
	.property_valuation &,
	.insurance &,
	.cleaning &,
	.universal &{
		background: url(images/banner_6.jpg) no-repeat center / cover;
		padding: 50px 0 55px;
		.slider_title{
			padding-bottom: 25px;
			margin-bottom: 30px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				background: #fff;
				height: 3px;
				width: 220px;
				margin-left: -110px;
			}
		}
		.slider_subtitle{
			text-transform: uppercase;
		}
		form{
			.form_item{
				margin-right: 20px!important;
			}
		}
	}
	.property_valuation & {
		background-image: url(images/banner_7.jpg);
		.title_text{
			margin-bottom: 13px;
		}
	}
	.universal & {
		background-image: url(images/banner_10.jpg);
		.slider_title{
			padding-bottom: 8px;
			margin-bottom: 18px;
		}
		.form_item{
			width: 220px;
		}
		.form_item.phone input{
			background-image: url(images/phone3.png);
		}
	}
	.insurance & {
		background-image: url(images/banner_8.jpg);
	}
	.earth &{
		padding: 50px 0 55px;
		.slider_subtitle{
			text-transform: uppercase;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		}
		.text{
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			font:500 20px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 15px;
		}
		.form_item{
			width: 277px;
			margin-bottom: 0 !important;
		}
		.slider_title{
			padding-bottom: 25px;
			margin-bottom: 30px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				background: #fff;
				height: 3px;
				width: 220px;
				margin-left: -110px;
			}
		}
	}
	.services &{
		background-image: url(images/banner_7.png);
		padding: 16px 0 36px;
	}
	.slider_subtitle{
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		font:400 36px 'PFDinTextCompPro', sans-serif;
		u{
			text-decoration: none;
			box-shadow: inset 0 -2px 0 0 #fff;
		}
	}
	.text{
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 30px;
		u{
			color: #f8dd1c;			
		}
	}
	.title_text{		
		font:500 20px 'PFDinTextCompPro', sans-serif;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		margin: 0 auto 25px;
		max-width: 1170px;
	}
	.slider_list{
		text-align: center;
		max-width: 1170px;
		margin: auto;
		overflow: hidden;
		margin-bottom: 40px;
		.item{
			width: 220px;
			margin-right: 20px;
			display: inline-block;
			vertical-align: top;
		}
		.li{
			width: 47px;
			height: 47px;
			border-radius:50%;
			text-align: center;
			border: 2px solid #ffffff;
			background-color: rgba(255, 255, 255, 0.1);
			float: left;
			margin-right: 10px;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			color: #ffffff;
			font:400 24px/47px 'PFDinTextCompPro', sans-serif;
			box-sizing: border-box;
		}
		.field_item{
			overflow: hidden;
			text-align: left;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			color: #ffffff;
			font:400 20px 'PFDinTextCompPro', sans-serif;
		}
	}
	form{
		max-width: 1170px;
		margin: auto;
		.contacts &{
			max-width: 490px;
			.form_item{
				margin-bottom: 10px;
				float: left;
				&.phone{
					margin-right: 0;
					float: right;
				}
			}
		}
	}
	.block_favorites {
		.views_row{
			display: inline-block;
			vertical-align: top;
			margin-right: 55px;
			width: auto;
			text-align: left;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			font:500 20px 'PFDinTextCompPro', sans-serif;
			.count{
				font-weight: 400;
				font-size: 36px;
			}
		}
	}
	.come_back{
		float: left;
		position: absolute;
		top: 7px;
		left: 0;
		z-index: 1;
		a{
			color:#fff;
			display: inline-block;
			vertical-align: middle;
			text-decoration: none;
			text-transform: uppercase;
			cursor: pointer;
			text-align: center;
			padding: 0 20px;
			border:2px solid #fff;
			font:500 18px/34px 'PFDinTextCompPro', sans-serif;
			height: 36px;
			transition: .3s;
			box-sizing: border-box;
			&:hover{
				opacity: .7;
			}
		}
	}
}
.tests{
	.slider_main {
		.slider_list{
			.item{
				width: auto;
				max-width: 280px;
				min-width: 195px;
				margin-right: 42px;
				&:nth-child(2) {
					min-width: 240px;
				}
			}			
		}		
	}
}
.ranking_daily{
	.slider_main {
		.slider_list{
			.item{
				width: auto;
				max-width: 220px;
				margin-right: 67px;
				&:nth-child(3) {
					margin-right: 0;
				}
			}			
		}	
		.slider_title{
			padding-bottom: 25px;
			margin-bottom: 27px;
		}	
		.webform_client_form .form_item.phone input{
			background-image: url(images/phone3.png);
		}
	}
}
.plan{
	.slider_main {
		background: url(images/banner_16.jpg) no-repeat center / cover;
		.slider_list{
			.item{
				width: auto;
				max-width: none;
				min-width: auto;
				margin-right: 72px;
				line-height: 47px;
				&:last-child{
					margin-right: 0;
				}
				.field_item{
					margin-top: 13px;
				}
			}			
		}	
		.submit{
			text-align: center;
			a{
				outline: none;
				border: none;
				text-transform: uppercase;
				text-decoration: none;
				color:#fff;
				display: inline-block;
				vertical-align: middle;
				@include submit;				
				line-height: 47px;
			}
		}	
	}
}
.top_inner_content{
	line-height: 20px;
	max-width: 1170px;
	margin: auto;
	.main_breadcrumbs{
		margin-bottom: 10px;
		.services &{
			margin-bottom: 33px;
		}
		.articles_inner &{
			margin-bottom: 60px;
		}
		a{
			color:#fff;
			text-decoration: none;
		}
		span.delimiter{
			display: inline-block;
			vertical-align: middle;
			margin: 0 8px;
		}
	}
	.title{
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		font:400 24px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 40px;
	}
}
.icons_wrap{
	width: 100%;
	max-width: 645px;
	position: relative;
	text-align: center;
	margin: 0 auto 20px;
	.articles_inner &{
		max-width: 513px;
	}
	.video_inner &{
		.icon{
			&.stat{
				padding-top: 33px;
				background-position: center top 4px;
			}
		}
	}
	.test_result &{
		max-width: 585px;
		margin-bottom: 4px;
	}
	.title{
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		font:500 20px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 10px;
	}
	.icon{
		width: 46px;
		height: 46px;
		margin: 0 7px;
		font:400 24px 'PFDinTextCompPro', sans-serif;
		display: inline-block;
		vertical-align: middle;
		box-sizing: border-box;
		&.vip{
			background: url(images/vip_icon.png) no-repeat center / contain;
		}
		&.stat{
			background: url(images/stat_icon.png) no-repeat center top ;
			padding-top: 29px;
		}
		&.favorite{
			background: #fff url(images/favorite.png) no-repeat center ;
			cursor: pointer;
			border-radius: 100%;
			box-sizing: border-box;
			&.active{
				background-image: url(images/favorite_active.png);
			}
		}
		&.result{
			background: #e14b4b;
			width: 70px;
			height: 70px;
			border-radius: 100%;
			font:400 48px/80px 'PFDinTextCompPro', sans-serif;
		}
		&.time{
			background: url(images/time.png) no-repeat center top;
			padding-top: 33px;
		}
	}
	&::after,
	&::before{
		content: '';
		position: absolute;
		width: 220px;
		height: 3px;
		background: #ffffff;
		opacity: 0.5;
		top: 50%;
		left: 0;
		margin-top: -1.5px;	
		.test_result &{
			top: 67px;
		}
	}
	&::before{
		left: auto;
		right: 0;
	}
}
.webform_client_form{
	text-align: center;
	.property_search &{
		text-align: left;
	}	
	.form_item{
		width: 240px;
		display: inline-block;
		vertical-align: middle;
		input{
			width: 100%;
			height: 45px;
			background: #fff;
			padding: 0 15px;
			outline: none;
			border: none;
			color: #89919e;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-moz-placeholder,
			&:-ms-input-placeholder{
				color: #89919e;				
			}
		}
		&.phone {
			margin-right: 16px;
			input{
				background: #fff url(images/phone.png) no-repeat center left 14px;
				padding-left: 45px;
			}
		}
		&.search{
			margin-right: 16px;
			width: 275px;
			input{				
				background: #fff url(images/search.png) no-repeat center left 14px;
				padding: 0 10px 0 45px;
			}
		}
	}
	.form_wrapper{
		margin-bottom: 20px;
		width: 100%;
		& > label{
			display: block;
			margin-bottom: 6px;
		}
	}
	.form_select{
		width: 100%;		
		label{
			margin-bottom: 6px;
			display: block;
		}
	}
	select{
		background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.06) 100%);
		border: 1px solid #bec7d1;
		box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
		box-sizing: border-box;
		height: 32px;
		width: 100%;
		padding: 0 25px 0 10px;
		-webkit-appearance: none;
		background: url('images/select.png') no-repeat center right 9px,
					url(images/grad.png) repeat center;
		line-height: 1em;
		/* for FF */
		-moz-appearance: none;
		text-indent: 0.01px; 
		text-overflow: '';
		/* for IE */
		-ms-appearance: none;
		appearance: none!important;			 
		box-sizing: border-box;
	}
	select::-ms-expand {
	  display: none;
	}
	.form_radios,
	.form_checkboxes{
		text-align: left;
		margin-bottom: 15px;
		.form_checkbox,
		.form_radio {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			input{
				display: none;
			}
			label{
				display: block;
				padding-left: 25px;
				position: relative;
				cursor: pointer;
				box-sizing: border-box;
				&::after{
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 18px;
					height: 18px;
					background: #fff;
					border:1px solid #bec7d1;
					box-shadow: 0 1px 0 0px #e5e5e5;
					box-sizing: border-box;
				}
			}
			input:checked + label{
				font-weight: 500;
				&::after{
					background: url(images/checkbox.png) no-repeat center;
					border-color: #03a9f4;
				}
			}
		}
	}
	.form_number {
		display: inline-block;
		vertical-align: bottom;
		margin-right: 10px;
		&:last-child{
			margin-right: 0;
		}
		input{
			width: 102px;
			border:1px solid #bec7d1;
			height: 32px;
			color:#3b4047;
			padding-left: 9px;
			box-shadow: 0 1px 0 0 #e5e5e5;
			box-sizing: border-box;
			&::-webkit-input-placeholder,
			&::-moz-placeholder,
			&:-moz-placeholder,
			&:-ms-input-placeholder{
				color: #3b4047!important;				
			}
		}
	}
	.price_currency{
		display: inline-block;
		vertical-align: bottom;
		width: 79px;
	}
	.form_item.date{
		width: 187px;
		input{
			background: #fff url(images/date.png) no-repeat center right 13px;
			padding: 0 45px 0 15px;
			color:#3b4047;
		}
	}
	.form_item.name{
		input{
			padding: 0 15px 0 45px;
			background: #fff url(images/user_name.png) no-repeat center left 13px;
		}
	}
	.form_actions{
		display: inline-block;
		vertical-align: middle;
		input{
			outline: none;
			border: none;
			text-transform: uppercase;
			color:#fff;
			@include submit;
		}
		.property_search & {
			position: relative;
			width: 100%;
			&::after {
				background: url(images/search_btn.png) no-repeat center left 12px;
				content: '';
				position: absolute;
				top: 13px;
				left: 0;
				width: 32px;
				height: 20px;
				z-index: 1;
			}
			input{
				padding-left: 41px;
				text-align: left;	
				float: left;
				margin-right: 25px;				
			}			
		}
		.links{
			overflow: hidden;
			margin-top: 4px;
			.search_full{
				a{					
					color: #18bb49;
					display: block;
					margin-bottom: 5px;
				}
			}
			.search_clear{
				a{
					color: #d0021b;
				}
			}
		}
	}
	.form_textarea{
		width: 100%;
		margin: 0 0 12px!important;
		clear: both;
	}
	textarea{
		resize: none;
		width: 100%;
		display: block;
		height: 100px;
		padding: 12px 20px 20px 40px;
		border:none;
		background: #fff;
		outline: none;
		color: #89919e;
		font-size: 14px;
		font-weight: 400;
		line-height: 20px;
		background: #fff url(images/email.png) no-repeat top 16px left 10px;
		box-sizing: border-box;
	}
	.textarea{
		width: 100%;
		margin-right: 0 !important;
		&.comment{
			textarea{
				background-image: url(images/comment.png);						
			}
		}
	}

}
.webform_client_form .form_radios .form_radio label::after{
	border-radius: 100%;
}
.webform_client_form .form_radios .form_radio input:checked + label::after{
	background: #03a9f4;
	box-shadow: inset 0 0 0 4px #fff;
}
.block_title{
	text-transform: uppercase;
	text-align: center;
	max-width: 1170px;
	margin: auto;
	font:500 64px 'PFDinTextCompPro', sans-serif;
	margin-bottom: 20px;
}
.block_services{	
	padding-bottom: 10px;
	overflow: hidden;
	clear: both;
	.block_title{
		margin-bottom: 24px;
	}
	.insurance &,
	.cleaning &,
	.universal &{
		padding: 30px 0 0 0;
		background: #fff;
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 270px;
		float: left;
		margin: 0 30px 28px 0;
		&:nth-child(4n+4){
			margin-right: 0;
		}
		.field_image {
				transition: .3s;
				&:hover{
					opacity: .7;
				}
			img{
				display: block;
				margin: auto;
			}
		}
		.field_title a{
			color: #18bb49;
			font: 400 24px/56px "PFDinTextCompPro", sans-serif;
			height: 55px;
			text-transform: uppercase;
			text-align: center;
			display: block;
			background: #fff;
			border: 1px solid #e3e2e2;
			text-decoration: none;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
			transition: .3s;
			&:hover{
				color:#fff;
				border-color:transparent;
				background-color: #17bb49;
			}
		}
	}
	.installation_water &,
	.property_valuation &{
		background: #fff;
		padding: 40px 0 0 0;
	}
}
.read_more{
	@include read_more(#18bb49);
}
.block_shops,
.block_vip{
	border-bottom: 1px solid #c4c4c4;
	padding: 33px 0 18px 0;
	background: url(images/bg.png) repeat top center;
	box-sizing: border-box;
	.block_title{
		margin-bottom: 20px;
	}
	.views_row{
		width: 31.5%;
		max-width: 370px;
		display: inline-block;
		position: relative;
		vertical-align: top;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		margin: 0 27px 30px 0;
		transition: .3s;
		&:hover{
			box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
		}
		&:nth-child(3n+3) {
			margin-right: 0;
		}
		.field_image{
			position: relative;
			img{
				display: block;
				margin: auto;
			}
			.type{
				position: absolute;
				color:#fff;
				background: #d0021b;
				padding: 5px 9px 3px 7px;
				top: 20px;
				z-index: 1;
				left: -10px;
				font: 400 15px 'PFDinTextCompPro', sans-serif;
				line-height: 1;
				text-transform: uppercase;
				box-sizing: border-box;
				&::after{
					content: '';
					border: 5px solid transparent;	
					border-top: 5px solid #b30218; 
					border-right: 5px solid #b30218;
					position: absolute;
					bottom: -10px;
					left: 0;
				}
			}
		}
		.views_body{
			position: relative;
			background: #fff;
			float: left;
			width: 100%;			
			padding: 26px 19px 20px;
			box-sizing: border-box;
			sup{
				display: inline-block;
				vertical-align: top;
			}
			.address{
				font: 400 24px 'PFDinTextCompPro', sans-serif;
				margin-bottom: 8px;
			}
			.text{
				line-height: 20px;
				margin-bottom: 20px;
			}
			.price{
				font: 400 24px 'PFDinTextCompPro', sans-serif;
				margin: 0 0px 15px 0;
				padding-right: 15px;
				float: left;
				width: 50%;			 	
				box-sizing: border-box;
				.label{
					color: #18bb49;
					font: 700 14px Roboto, sans-serif;
					line-height: 20px;
				}
			}
			
			.statistics,
			.area{
				float: left;
				width: 50%;
				font: 400 24px 'PFDinTextCompPro', sans-serif;
				color: #89919e;
			}
			.statistics{
				margin-bottom: 15px;
				background: url(images/stat.png) no-repeat top left;
				padding-left: 43px;
				float: right;
				width: 42%;
				box-sizing: border-box;
			}
			.area{
				clear: both;
				.label{
					color: #3b4047;
					font: 400 14px Roboto, sans-serif;
					line-height: 20px;
				}
			}
			.units,
			.count{
				display: inline-block;
				vertical-align: bottom;
			}
			.read_more{
				overflow: hidden;
				float: right;
				a{
					padding: 0 13px;
				}
			}			
		}
	}
}
.block_vip {
	border:none;
	padding-top: 0;
	.views_row{
		.views_body{			
			.area{
				float: right;
				clear: none;
				width: 97px;
				color:#3b4047;
			}
			.statistics{
				float: left;
				clear: left;
			}
		}
	}
}
.star{
	position: absolute;
	z-index: 1;
	left: 20px;
	top: -18px;
	width: 36px;
	cursor: pointer;
	height: 36px;
	background: url(images/favorite.png) no-repeat center / contain;
	&.active{
		background-image:url(images/favorite_active.png);
	}
}
.new_buildings,
.block_search_results,
.block_vip_shops{
	padding: 38px 0 57px 0;
	margin-bottom: 40px;
	background: url(images/bg.png) repeat top center;
	box-sizing: border-box;
	.superlending_inner &{
		.block_title{
			text-align: left;
		}
	}
	.block_title{
		margin-bottom: 15px;
	}
	.views_row{
		background: #fff;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		max-width: 570px;
		position: relative;
		background: #fff;
		text-align: left;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		transition: .3s;
		&:nth-child(even){
			margin-left: 25px;
		}		
		&:hover{
			box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
		}
		&.vip_row {
			.title	{
				padding-right: 50px;				
			}
		}
		.title{
			padding: 13px 20px;
			font: 400 24px 'PFDinTextCompPro', sans-serif;
			box-sizing: border-box;			
		}
		.field_image{
			img{
				display: block;
				margin: auto;
			}
		}
		.left_wrap,
		.right_wrap{
			float: left;
			width: 50%;
		}
		.views_body{
			position: relative;
			padding: 26px 19px 20px;
			float: left;
			width: 100%;
			box-sizing: border-box;
			.address{
				font: 400 24px 'PFDinTextCompPro', sans-serif;
				margin-bottom: 8px;
			}
			.info{
				margin-bottom: 8px;
				& > div{
					display: inline-block;
					vertical-align: middle;
					margin-right: 3px;
				}
				.item{
					font-weight: 700;
				}
			}
			.top_wrap {
				.info{					
					display: inline-block;
					vertical-align: top;
					margin: 0 40px 17px 0;
					width: auto;
					float: none;
					padding: 0;
					&:nth-child(3n+3) {
						margin-right: 0;
					}
					.label{
						display: block;
						margin: 0 0 10px;
					}
					.item{
						font: 400 24px 'PFDinTextCompPro', sans-serif;
					}
				}
			}
			.price{
				font: 400 24px 'PFDinTextCompPro', sans-serif;
				margin: 26px 0px 0 0;
			}
			.bottom_wrap{
				overflow: hidden;
			}
		}
		.statistics{
			margin-bottom: 15px;
			background: url(images/stat.png) no-repeat top left;
			padding-left: 43px;
			font: 400 24px 'PFDinTextCompPro', sans-serif;
			color: #89919e;
			box-sizing: border-box;
		}
	}
	.read_more{
		margin-top: 8px;
	}
	.vip{
		background: url(images/vip.png) no-repeat center;
		width: 22px;
		height: 27px;
		position: absolute;
		top: 12px;
		right: 15px;
	}
}
.newbuilding .block_search_results{
	padding-top: 20px;
}
.ranking .block_search_results .views_row .views_body .top_wrap .info.price{
	padding-left: 10px;
}
.articles .block_search_results{
	padding-top: 0;
	text-align: center;
}
.new_buildings .view_content{
	text-align: center;
}
.apartment {	
	.new_buildings{
		padding-bottom: 0;
		margin-bottom: 0;
	}
	.block_vip_shops{
		.block_title{
			font-size: 36px;
		}		
	}
	.block_services{
		background:#fff;
		padding-top: 26px;
	}
	.region_banner{
		padding: 0 0 30px;
	}
}
.daily_inner {
	.block_vip_shops{
		.block_title{
		text-align: left;			
		}
	}
	.region_banner{
		padding: 30px 0 56px;
	}
}
.newbuilding_inner {
	.block_vip_shops{
		.block_title{
			font-size: 36px;	
		}
	}
}
.apartment {
	.block_vip_shops{
		padding-top: 25px;
	}
}
.installation_water,
.property_valuation,
.insurance,
.cleaning,
.universal{
	.region_banner{
		padding-top: 10px;
	}
}
.block_search_results,
.block_vip_shops{
	padding-bottom: 15px;
	margin-bottom: 0;
	border:none;
	.views_row{
		width: 48%;
		margin-right: 27px !important;
		.views_body{
			.statistics{
				float: none;
				width: auto;
				display: inline-block;
				vertical-align: middle;
				margin: 0 9px 0 0;
			}
			.read_more{
				float: none;
				display: inline-block;
				vertical-align: middle;
				margin: 0;
				a{
					padding: 0 11px;
				}
				&.preview{
					@include read_more(#03a9f4);
					a{
						padding: 0 11px;
					}					
				}
			}			
		}
		&:nth-child(even){
			margin: 0 0 30px!important;
		}
		&.no_image {
			.title{
				padding-bottom: 33px;
				padding-top: 27px;
				border-bottom: 1px solid rgba(151, 151, 151, .3);
			}
			.read_more{
				float: right;
			}
			.statistics{
				margin-top: 6px;
			}
		}
	}
}
.load_more_wrap{
	text-align: center;
}
.multilending .block_search_results,
.superlending .block_search_results{
	.views_row{
		width: 370px;
		margin-right: 26px !important;
		&:nth-child(3n+3){
			margin-right: 0!important;
		}
	}
}
.superlending .block_search_results{
	padding-top: 0;
}
.multilending .banner{
	text-align: center;
	max-width: 1170px;
	margin: 0 auto 10px;
}
.block_vip_shops:not(.block_daily_products){
	.views_row{
		.views_body .top_wrap{
			.price.info{
				padding-left: 10px;
				box-sizing: border-box;
			}
		}
	}
}
.block_vip_shops,
.block_daily_products.block_search_results{
	.view_content{
		.views_row{
			width: 370px;
			margin-right: 26px !important;
			&:nth-child(3n+3){
				margin-right: 0!important;
			}
			.views_body .read_more a,
			.views_body .read_more.preview a{
				padding: 0 8px
			}
		}
	}
}
.favorites .block_daily_products{
	.view_content{
		.views_row{
			width: 370px;
			margin-right: 26px !important;
			&:nth-child(even){
				margin-right: 0 !important;
			}
		}
	}
}
.block_vip_shops{
	.view_content{
		.views_row{
			.views_body{
				.text{
					margin-bottom: 20px;
					line-height: 20px;
				}
				.top_wrap{
					.info{
						&.price {
							.label{
								font:700 14px Roboto, sans-serif;
								color: #18bb49;
							}
						}
						sup{
							vertical-align: top;
							display: inline-block;
						}
						.item{
							line-height: 26px;
						}
					}
				}
			}
		}
	}
	&.block_free{
		.views_row {
			.views_body {
				.top_wrap{
					margin-bottom: 13px;
					.info{
						width: 49%;
						margin-right: 0;
						margin-bottom: 10px;
						&.price{
							margin-bottom: 5px;
						}
						.label{
							font-weight: 700;
							display: inline-block;
							margin-bottom: 0;
						}
						.item{
							font:400 14px Roboto, sans-serif;
						}
					}
				}
			}
		}
	}
}
.newbuilding_inner .block_free{
	margin-bottom: 80px;
	padding-top: 0;
}
.block.block_daily_products {
	.views_row{
		.views_body{
			.top_wrap{
				.info{
					width: 48%;
					margin: 0 0 17px;
					overflow: hidden;
					.label{
						font-weight: 700;
						float: left;
						margin: 0 5px 0 0;
					}
					.item{
						font: 400 14px Roboto, sans-serif;
						overflow: hidden;
						vertical-align: top;
					}
				}
			}
		}
		.read_more{
			a{
				padding: 0 12px!important;
			}
		}
	}
}
.search_results{
	background:#fff;
	padding: 19px 20px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	position: relative;
	z-index: 1;
	box-sizing: border-box;	
	.not-front & {
		.form_title{
			text-transform: uppercase;
			font: 500 36px 'PFDinTextCompPro', sans-serif;
		}
		form{
			text-align: left;
			padding-top: 20px;
			.form_actions{
				position: relative;
				width: 100%;
				input{
					@include submit;
					padding-left: 41px;
					border:none;
				}
				&::after{
					background: url(images/search_btn.png) no-repeat center left 12px;
					content: '';
					position: absolute;
					top: 34px;
					left: 0;
					width: 32px;
					height: 20px;
					z-index: 1;
				}
			}
		}
		.form_wrapper{
			display: inline-block;
			vertical-align: bottom;
			width: auto;
		}
	}
	.top_wrap{
		overflow: hidden;
		margin-bottom: 20px;
	}
	.left_wrap{
		float: left;
		.count_result{
			font: 400 24px 'PFDinTextCompPro', sans-serif;
		}
	}
	.right_wrap{
		float: right;
		.sortable{
			label{
				color: #767f8d;
			}
			select{
				color: #3b4047;
				background: url(images/select.png) no-repeat center right;
				border:none;
				outline: none;
				padding: 0 18px 0 0;
				-webkit-appearance: none;
				font-size: 14px;
				line-height: 1em;
				/* for FF */
				-moz-appearance: none;
				text-indent: 0.01px; 
				text-overflow: '';
				/* for IE */
				-ms-appearance: none;
				appearance: none!important;			 
				box-sizing: border-box;
			}			
			select::-ms-expand {
			  display: none;
			}
		}
	}
	.filters {
		float: left;
		max-width: 75.5%;
		margin-right: 9px;
		.item{
			font-size: 14px;
			font-weight: 400;
			border: 1px solid #a9d5e8;
			display: inline-block;
			vertical-align: middle;
			height: 36px;
			line-height: 36px;
			padding: 0 37px 0 11px;
			position: relative;
			background: #fff;
			margin-right: 5px;
			box-sizing: border-box;
			.close{
				background: #fff url(images/filter.png) no-repeat center right 0px;
				content: '';
				top: 10px;
				right: 10px;
				width: 15px;
				height: 15px;
				cursor: pointer;
				position: absolute;
			}
		}
	}
	.form_actions{
		input{
			text-transform: uppercase;
			text-align: center;
			outline: none;
			display: block;
			color: #18bb49;
			padding: 0 20px;
			background: #fff;
			cursor: pointer;
			height: 36px;
			border: 2px solid #18bb49;
			font: 500 18px/36px PFDinTextCompPro, sans-serif;
			-webkit-transition: .3s;
			transition: .3s;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			&:hover{
				background: #18bb49;
				color:#fff;
			}
		}
	}
	&.search_products {
		padding-left: 0;
		padding-right: 0;	
		margin-bottom: 30px;
		& > div{
			padding: 0 29px;
		}
		.webform_client_form{	
			padding: 0 29px 10px;
			margin-bottom: 29px;	
			border-bottom: 1px solid rgba(151, 151, 151, .3);	
			.form_wrapper{
				margin-right: 30px;
			}
			.form_select {
				&.area_select{
					select{
						width: 189px;	
						.ranking_daily &{
							width: 329px;
						}				
					}
				}
				select{
					width: 229px;
					.ranking_daily &{
						width: 148px;
					}
				}
			}
			.form_actions{
				margin-bottom: 20px;
				width: auto;
				input{
					box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
				}
				.links{
					margin-bottom: 20px;
					margin-top: -20px;					
				}
			}
		}
	}
	.form_item.metro{
		width: 435px;
		label{
			margin-bottom: 5px;
			display: block;
		}
		input{
			height: 32px;
			box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
			border: 1px solid #bfc7d1;
		}
	}
}
.earth,
.ranking_daily {
	.search_results {
		form .form_actions::after{
			top: 30px;
		}
		.form_title{
			margin: 7px 0 17px;
		}
	}
}
.ranking_daily .search_results{
	margin-bottom: 0;
}
.form_select.currency,
.form_item.area_wrap .form_select{
	width: 79px;
	display: inline-block;
	vertical-align: middle;
	select{
		width: 100% !important;
	}
}
.multilending {
	.search_results{
		margin-top: -110px;
		form.webform_client_form{			
			.form_item{
				margin: 0 36px 0 0;
			}
			.form_actions{
				vertical-align: bottom;
				width: auto;
				.links,
				input{
					display: inline-block;
					vertical-align: bottom;
					margin-right: 24px;
				}
				&::after{
					top: 12px;
				}
			}			
		}
	}
}
.superlending{
	.search_results{		
		form.webform_client_form{		
			.form_checkboxes{
				position: absolute;
				top: 26px;
				left: 343px;
				.form_checkbox{
					width: auto;
					margin-right: 30px;
				}
			}
			.form_item{
				margin-right: 20px;
				margin-bottom: 0;
			}
			.form_item.form_select{
				width: 144px;
				select{
					width: 100%;
				}
			}
			.form_actions{
				float: none;
			}
		}
		.form_title{
			margin-bottom: 19px;
		}
	}
}
.block_presentation{
	background: url(images/bg_2.jpg) no-repeat center / cover;
	padding: 55px 0 85px;
	clear: both;
	margin-bottom: 55px;
	box-sizing: border-box;
	.not-front &{
		margin-bottom: 0;
		.block_title{
			margin-bottom: 20px;
		}
	}
	.superlending &{
		&:not(.second){			
			.block_title{
				margin-bottom: 56px;
			}
			form.webform_client_form{
				max-width: 1170px;
				margin: auto;
				.form_item{
					margin: 0 8px 0 0!important;
				}
			}
		}
		&.second{
			form.webform_client_form{
				.form_actions,
				.form_actions input{
					width: 100%;
				}
			}
		}
	}
	&.order_block{
		background-image: url(images/order_bg.jpg);
		padding: 60px 0 90px;
		.block_title{
			margin-bottom: 40px;
		}
		.real_services &{
			margin-bottom: 44px;
			padding-bottom: 90px;
		}
	}
	.plan &{
		padding: 72px 0 70px;
		.block_title{
			margin-bottom: 42px;
		}
	}
	.block_title{
		color:#fff;
		margin-bottom: 56px;
		font-weight: 400;
		line-height: 65px;
	}
	.sub_title{
		color:#fff;
		text-align: center;
		text-transform: uppercase;
		font: 400 24px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 40px;
	}
	.title_text{
		text-align: center;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
		color:#fff;
		margin-bottom: 28px;
		font: 500 20px 'PFDinTextCompPro', sans-serif;
	}
	.present_wrap{
		text-align: center;
		margin-bottom: 83px;
	}
	.see_present{
		color: #f8dd1c;
		text-transform: uppercase;
		text-align: center;
		display: inline-block;
		vertical-align: middle;
		font: 400 36px 'PFDinTextCompPro', sans-serif;
		line-height: 1;
		padding: 15px 46px 15px 103px;
		background: rgba(248, 220, 27, 0.05) url(images/present.png) no-repeat center left 20px;
		border: 2px solid #f8dd1c;
		border-radius: 100px;
		cursor: pointer;
		box-sizing: border-box;
		transition: opacity .3s;
		&:hover{
			opacity: .7;
		}
	}
	.views_row{
		text-align: center;
		color:#fff;
		display: inline-block;
		vertical-align: top;
		width: 270px;
		margin: 0 25px 58px 0;
		&:nth-child(4n+4) {
			margin-right: 0;
		}
		.field_image{
			height: 50px;
			line-height: 50px;
			margin-bottom: 20px;
			img{
				vertical-align: middle;
			}
		}
		.title{
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			font: 400 24px/30px 'PFDinTextCompPro', sans-serif;
			text-transform: uppercase;
		}
	}
	.document {
		text-align: center;
		.views_row{
			width: auto;
			max-width: 270px;
			margin: 0 15px 41px;
			padding-top: 58px;
			background: url(images/doc.png) no-repeat top center;
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			font: 500 20px/22px 'PFDinTextCompPro', sans-serif;
			a{
				color: #f5d818;
			}
			&.email_row{
				background: url(images/mail.png) no-repeat top 5px center;
			}
		}
	}
	.webform_client_form {
		.form_item{
			margin-right: 6px;
			input{
				padding-left: 42px;
			}
			&.user_name{
				input{
					background:#fff url(images/user.png) no-repeat center left 10px;
				}
			}
		}
		.form_actions{
			input{
				padding: 0 30px;
			}
		}		
	}
	.ranking &{
		.webform_client_form{
			.form_actions,
			.form_actions input{
				width: 100%;
			}
		}
	}
}
.property_valuation,
.real_services {
	.block_presentation{
		.block_title{
			position: relative;
			padding-bottom: 22px;
			margin-bottom: 23px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 220px;
				height: 3px;
				margin-left: -110px;
				background: #fff;
			}
		}
		.sub_title{
			font-size: 36px;
			margin-bottom: 0;
		}
		form.webform_client_form {
			max-width: 739px;  
			.form_item{
				margin-right: 6px!important;
				&.email{
					margin-right: 0 !important;
				}
				input{
					padding-left: 42px;
				}
				&.user_name{
					input{
						background:#fff url(images/user.png) no-repeat center left 10px;
					}
				}
				&.email{
					input{
						background:#fff url(images/email.png) no-repeat center left 10px;
					}
				}
			}
			.textarea{
				width: 100%;
				margin-right: 0 !important;
				&.comment{
					textarea{
						background-image: url(images/comment.png);						
					}
				}
			}
			.form_actions{
				input{
					padding: 0 30px;
				}
			}		
		}
	}
}
.apartment {
	.block_presentation {		
		padding-bottom: 55px;
	}
	
}
.real_services{
	.block_presentation {		
		padding-bottom: 45px;
		form.webform_client_form{
			max-width: 920px;
			textarea{
				height: 72px;
			}
			.form_item{
				width: 200px;
				&.address{
					width: 386px;
					input{
						background: #fff url(images/address.png) no-repeat center left 15px;
					}
				}
				&.price{
					width: 104px;
					margin-right: 0!important;
					input{
						background: #fff url(images/price.png) no-repeat center left 15px;
					}
				}
			}
		}
	}
}
.process .block_presentation{
	padding: 75px 0 59px;
	.block_title{
		margin-bottom: 35px;
	}
}
.trust .block_presentation .webform_client_form .form_actions,
.trust .block_presentation .webform_client_form .form_actions input{
	width: 100%;
}
.installation_water .block_presentation,
.favorites .block_presentation{
	background-image:url(images/present_1.jpg);
	padding-bottom: 92px;
	form.webform_client_form{
		max-width: 1170px;
		.form_item{
			margin: 0 8px 0 0!important;
		}
	}
	.block_title{
		margin-bottom: 35px;
	}
}
.block_earth{
	text-align: center;
	.views_row{
		text-align: left;
		padding: 24px 30px;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		margin: 0 0 30px;
		.title{
			font: 400 36px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 18px;
		}
		.top_wrap{
			overflow: hidden;
			margin-bottom: 30px;
		}
		.bottom_wrap{
			overflow: hidden;
			.char_item{
				margin-bottom: 16px;
				.label{
					font-weight: 700;
					display: inline-block;
					vertical-align: middle;
				}
				.item{
					display: inline-block;
					vertical-align: middle;
				}
			}
			.left_wrap{
				width: 290px;
				padding-right: 30px;
				box-sizing: border-box;
			}
			.center_wrap{
				overflow: hidden;
				width: 367px;
				float: left;
				.address{
					font-weight: 700;
					padding-left: 26px;
					margin-bottom: 18px;
					min-height: 21px;
					background: url(images/marker.png) no-repeat top left;
					box-sizing: border-box;
				}
				.body{
					line-height: 20px;
				}
			}
			.right_wrap{
				float: right;
				max-width: 350px;
				.info_wrap{
					overflow: hidden;
					margin-bottom: 25px;
					.item{
						width: 50%;
						float: left;
					}
					.label{
						font: 400 24px 'PFDinTextCompPro', sans-serif;
						margin-bottom: 8px;
					}
					.count{
						font: 500 36px 'PFDinTextCompPro', sans-serif;						
					}
				}
				.contacts{
					.title{
						font-size: 24px;
						font-weight: 400;
						margin-bottom: 10px;
					}
					.item{
						margin-bottom: 0;
						font-size: 18px;
						line-height: 37px;
						padding-left: 38px;
						&.field_name{
							background: url(images/name.png) no-repeat center left;
						}
						&.field_phone{
							background: url(images/phone_2.png) no-repeat center left;
						}
						.label{
							display: inline-block;
							vertical-align: middle;
							margin-right: 8px;
							font-weight: 700;
						}
						.field_item{
							display: inline-block;
							vertical-align: middle;
							font-weight: 400;
						}
					}
					.btn{
						@include read_more(#03a9f4);
						float: right;
						cursor: pointer;
						padding: 0 14px;
						margin-top: 14px;
						&:hover{
							color:#fff;
							background: #03a9f4;
						}
					}
				}
			}
		}
		.left_wrap{
			float: left;
			width: 50%;
			.map{
				img{
					display: block;
					margin: 0;
				}
			}
		}
		.right_wrap{
			width: 50%;
			max-width: 542px;
			float: right;
		}
	}
	.slider{
		max-height: 293px;
		overflow: hidden;
		.slick-arrow{
			text-indent: -9999px;
			background: url(images/prev.png) no-repeat center;
			border:none;
			outline: none;
			width: 38px;
			height: 38px;
			position: absolute;
			top: 50%;
			z-index: 10;
			margin-top: -19px;
			cursor: pointer;
			transition: .3s;
			&:hover{
				opacity: .7;
			}
			&.slick-next {
				background: url(images/next.png) no-repeat center;
				right: 0;
			}
			&.slick-prev{
				left: 0;
			}
		}
	}
}
.block_appartament_slider{
	background: #fff;
	max-width: 1170px;
	margin: -180px auto 30px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	padding: 30px;
	box-sizing: border-box;
}
.slider_for{
	max-height: 624px;
	overflow: hidden;
	margin-bottom: 20px;
	.item{
		display: table;
		width: 100%;
		height: 624px;
		.item_inner{
			display: table-cell;
			vertical-align: middle;
			img{
				max-height: 100%;
			}
		}
	}	
	.slick-arrow{
		position: absolute;
		top: 0;		
		width: 50%;
		height: 100%;
		opacity: 0;
		cursor: pointer;
		background: none;
		text-indent: -9999px;
		outline: none;
		border:none;
		z-index: 20;
		transition: .3s;
		&::before{
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 97px;
			height: 100%;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
		}
		&::after{
			content: '';
			position: absolute;
			top: 50%;
			margin-top: -25px;
			width: 28px;
			height: 50px;
			background: url(images/arrow_next_2.png) no-repeat center;
			display: block;
		}
		&:hover{
			opacity: 1;
		}
		&.slick-prev{
			left: 0;
			&::before{
				right: auto;
				left: 0;
			    background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
			}
			&::after{
				left: 25px;
				transform: rotate(-180deg);
			}
		}
		&.slick-next{
			right: 0;
			background-position: center right 25px;
			&::after{
				right: 25px;
			}
		}
	}	
}
.slider_nav{
	height: 94px;
	margin: 0 -4.5px;
	padding: 0 30px;
	box-sizing: border-box;
	.item{
		width: 166px;
		height: 94px;
		margin: 0 9px;
		cursor: pointer;
		float: left;
		display: table;		
		display: none;
		transition: opacity .3s;
		&.first,
		&.slick-slide{
			display: table;
		}
		&:hover{
			opacity: .7;
		}
		.item_inner{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
			img{
				display: block;
				margin: auto;
			}
		}
	}
	.slick-arrow{
		position: absolute;
		top: 50%;
		background: url(images/arrow_next.png) no-repeat center;
		width: 25px;
		height: 48px;
		margin-top: -24px;
		cursor: pointer;
		text-indent: -9999px;
		outline: none;
		border:none;
		transition: opacity .3s;
		&:hover{
			opacity: .5;
		}
		&.slick-prev{
			transform:rotate(-180deg);
			left: -5px;
		}
		&.slick-next{
			right: -5px;
		}
	}
}
.block_inform_appartament{
	background:#fff;
	padding: 23px 30px;
	margin-bottom: 30px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.block_content{
		overflow: hidden;
	}
	.left_wrap{
		float: left;
		width: 70%;
		.newbuilding_inner &{
			width: 35%;
		}
		.price_wrapper{
			margin-bottom: 10px;
			div{
				font: 500 36px 'PFDinTextCompPro', sans-serif;					
				display: inline-block; vertical-align: middle;
				sup{
					display: inline-block;
					vertical-align: top;
				}
			}
			span{
				width: 1px;
				height: 49px;
				background: #979797;
				transform: rotate(18deg);
				display: inline-block;
				vertical-align: middle;
				margin: 0 15px;
			}
		}
		.char_wrapper{
			overflow: hidden;
			.group{
				float: left;
				width: 50%;
				padding-right: 30px;
				box-sizing: border-box;
			}
			.item{
				margin-bottom: 17px;
				a{
					color: #1fb239;
				}
				&.list{
					.field_item{
						width: 100%;
					}
					.label{
						margin-bottom: 18px;
					}
				}
			}
			.label{
				font-weight: 700;
				display: inline-block;
				vertical-align: middle;
				margin-right: 2px;
			}
			.field_item{
				display: inline-block;
				vertical-align: middle;				
			}
			ul{
				list-style:none;
				display: block;
				clear: both;
				li{
					padding-left: 19px;
					position: relative;
					margin-bottom: 17px;
					&::after{
						content: '';
						width: 9px;
						height: 9px;
						border-radius: 50%;
						background: #03a9f4;
						position: absolute;
						top: 5px;
						left: 0;
					}
				} 
			}
		}
		&.second{
			.title{
				margin-bottom: 32px;
			}
		}
	}
	.right_wrap{
		width: 29%;
		float: right;
		.title{
			margin-bottom: 12px;
		}
		.contacts_person{
			padding-left: 34px;
			overflow: hidden;
			font-size: 18px;
			margin-bottom: 13px;
			min-height: 27px;
			box-sizing: border-box;
			a{
				color:#3b4047;
				text-decoration: none;
				cursor: default;
			}
			&.name{
				background: url(images/name.png) no-repeat top left;
			}
			&.phone{
				background: url(images/phone_2.png) no-repeat top left;				
			}
			.label{
				float: left;
				font-weight: 700;
				margin-right: 6px;
			}
			.field_item{
				overflow: hidden;
			}
		}
	}
	.title{
		font: 400 24px 'PFDinTextCompPro', sans-serif;	
		margin-bottom: 4px;
	}
	.social_share{
		.label{
			font:400 24px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 3px;
		}
	}
}
.block_body{
	background: #fff;
	max-width: 1170px;
	padding: 29px 30px 36px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	margin: 0 auto 30px;
	box-sizing: border-box;
	.title{
		text-transform: uppercase;
		font:500 36px 'PFDinTextCompPro', sans-serif;
		float: left;
		width: 345px;
		margin-right: 30px;
	}
	.field_body{
		line-height: 20px;
		overflow: hidden;
	}
	.left_wrap{
		float: left;
		width: 340px;
		margin-right: 30px;
		.title{
			margin-bottom: 5px;
		}
		ul{
			list-style: none;
			clear: both;
			li{
				position: relative;
				line-height: 20px;
				margin-bottom: 23px;
				padding-left: 25px;
				-webkit-box-sizing: border-box;
				box-sizing: border-box;
				&::after{
					content: '';
					position: absolute;
					top: 6px;
					left: 0;
					background: #3b4047;
					width: 3px;
					height: 3px;
					border-radius: 100%;
				}
			}
		}
	}
}
.block_accommodations{
	background: #fff;
	max-width: 1170px;
	margin: 30px auto 0;
	padding: 28px 30px 0;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.block_content{
		overflow: hidden;
	}
	.left_wrap,
	.rigth_wrap{
		float: left;
		width: 50%;
	}
	.title{
		font:400 24px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 20px;
	}
	.accommodations{
		.item{
			display: inline-block;
			vertical-align: top;
			width: 49%;
			margin-bottom: 20px;
			padding-left: 53px;
			min-height: 48px;
			font-weight: 700;
			line-height: 48px;
			box-sizing: border-box;
			&.parking{
				background: url(images/p_1.png) no-repeat center left;
			}
			&.studio{
				background: url(images/p_2.png) no-repeat center left;
			}
			&.pharmacy{
				background: url(images/p_3.png) no-repeat center left;
			}
			&.security{
				background: url(images/p_4.png) no-repeat center left;
			}
			&.warehouses{
				background: url(images/p_5.png) no-repeat center left;
			}
			&.cafe{
				background: url(images/p_6.png) no-repeat center left;
			}
			&.hours{
				background: url(images/p_7.png) no-repeat center left;
				line-height: 24px;
				font-weight: 400;
				width: 45%;
			}
			&.video{
				background: url(images/p_8.png) no-repeat center left;
				line-height: 24px;
				font-weight: 400;
				width: 54%;
			}
		}
	}
	.char{
		.group{
			display: inline-block;
			vertical-align: top;
			width: 48%;
			margin-bottom: 18px;
		}
		.item{
			margin-bottom: 15px;
		}
		.label{
			display: inline-block;
			vertical-align: middle;
			margin-right: 3px;
		}
		.field_item{
			font-weight: 700;
			display: inline-block;
			vertical-align: top;
		}
	}
}
.region_banner{
	background: #fff;
	padding: 33px 0 60px;
	.field_banner{
		max-width: 1170px;
		margin: auto;
		img{
			display: block;
			margin: auto;
		}
	}
	.process &{
		padding: 50px 0 67px;
	}
}
.not-front {
	.block_presentation{		
		.webform_client_form{
			max-width: 490px;
			margin: auto;
			.form_item{
				margin-bottom: 8px;
				&:nth-child(even){
					margin-right: 0;
				}
			}
		}
	}
}
.apartment,
.daily_inner {
	.block_presentation:not(.block_picking){
		.webform_client_form{
			max-width: 1170px;		
			.form_item{
				margin-bottom: 0;
				margin-right: 8px!important;
			}
		}		
	}
}
.block_presentation.order_block{
	.webform_client_form{
		max-width: 1170px;		
		.form_item{
			margin-bottom: 0;
			margin-right: 8px!important;
		}
	}		
	.superlending_inner &{
		margin-bottom: 30px;
	}
}
.daily_inner .block_presentation:not(.block_picking){
	margin-bottom: 30px;
}
.block_maps{
	margin: 30px auto;
	background: #fff;
	padding: 20px 30px 30px;
	max-width: 1170px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.map{
		display: inline-block;
		vertical-align: top;
		width: 48.7%;
		&:first-child{
			margin-right: 24px;
		}
		.field_image{			
			img{
				display: block;
				margin: auto;
			}
		}
		.title{
			font:400 24px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 6px;
		}
	}
	.newbuilding_inner &{
		margin-bottom: 45px;
	}
}
.block_video{	
	padding-bottom: 50px;
	margin-bottom: 43px;
	border-bottom: 2px solid rgba(151, 151, 151, .5);
	box-sizing: border-box;
	.block_content{
		max-width: 1170px;
		margin: auto;
	}
	iframe{
		height: 521px !important;
		display: block;
	}
	.video_inner &{
		margin-top: -180px;
		padding: 30px;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
	}
	.daily_inner &,
	.trust &,	
	.superlending_inner &{
		padding-bottom: 0;
		margin-bottom: 0;
		border:none;
	}
	.plan &{
		border: none;
		padding-bottom: 0;
		margin-bottom: 20px;
	}
	.real_services &{
		margin-bottom: 40px;
		border: none;
		padding-bottom: 0;
	}
	.repair_premises &{
		margin-bottom: 40px;
	}
	.installation_water &,
	.property_valuation &,
	.insurance &,
	.cleaning &,
	.universal &{
		padding-bottom: 0;
		border:none;
		margin-bottom: 60px;
	}
	.apartment &{
		margin-bottom: 0;
	}
}
iframe{
	border:none;
	width: 100% !important;
}
.block_tests_wrapper{
	margin-bottom: 30px;
	.views_row{
		background: #fff;
		padding: 30px 29px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		margin-bottom: 30px;
		box-sizing: border-box;
		.view_row_inner{
			overflow: hidden;
		}
		.left_wrap{
			float: left;
			margin-right: 30px;
			width: 55%;			
			.title{
				text-transform: uppercase;
				font:500 36px/36px 'PFDinTextCompPro', sans-serif;
				margin-bottom: 8px;
			}
			.subtitle{
				font:400 24px 'PFDinTextCompPro', sans-serif;	
				margin-bottom: 13px;			
			}
			ul{
				line-height: 20px;
				list-style: none;
				max-width: 466px;
				li{
					position: relative;
					padding-left: 37px;
					margin-bottom: 18px;
					&::after{
						content: '';
						position: absolute;
						top: 7px;
						left: 11px;
						border-radius: 100%;
						background: #3b4047;
						width: 3px;
						height: 3px;
					}
				}
			}
			.read_more{
				float: left;
				margin-right: 18px;
				@include read_more(#03a9f4);
			}
			.totals{
				font:400 24px/36px 'PFDinTextCompPro', sans-serif;
				.count{
					display: inline-block;
					vertical-align: middle;
					color: #18bb49;
					margin-left: 5px;
				}
			}
		}
		.right_wrap{
			width: 36.8%;
			float: right;
		}
		.field_image img{
			display: block;
			margin: 0;
		}
		.body{
			line-height: 20px;
			p{
				margin-bottom: 18px;
			}
		}
	}
}
.block_test_result {
	.views_row{		
		.left_wrap{
			width: 350px;
			margin-right: 19px;
			.title{
				margin-bottom: 19px;
			}
		}
		.right_wrap{
			width: auto;
			float: none;
			overflow: hidden;
		}
	}
	.read_more {
		height: 46px;
		a{
			@include submit;
			height: 46px;
			line-height: 46px;
			border: none;
			padding: 0 40px;
		}
	}
}
.block_test_passing{
	margin-top: -180px;
	.views_row{
		background: #fff;
		padding: 30px 29px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		margin-bottom: 30px;
		text-align: center;
		box-sizing: border-box;
		.title{
			text-transform: uppercase;
			margin-bottom: 30px;
			font:500 36px 'PFDinTextCompPro', sans-serif;
		}
		.webform_client_form{
			text-align: left;
			max-width: 464px;
			label{
				font-size: 18px;
				&::after{
					top: 2px;
				}
			}
			.form_radios{
				margin-bottom: 0;
			}
		}
	}
	.webform_client_form{
		max-width: 441px;
		margin: auto;
		text-align: left;
		.form_radio{
			margin-bottom: 27px;
			 input:checked + label {
			 	font-weight: 400;
			 }
			 &:last-child{
			 	margin-bottom: 0;
			 }
		}
	}
}
.block_newbuilding_row{
	.block_content{
		.view_content{
			.views_row{
				margin: 0 0 30px!important;
				width: 100%;
				max-width: 100%;
				padding: 18px 20px;
				box-sizing: border-box;
				.title{
					padding-left:0;
					padding-right:0;
					padding-top: 0;
				}
				.left_wrap{
					width: 469px;
					margin-right: 30px;
					.field_images{
						position: relative;
						.star{
							left: auto;
							right: 17px;
						}
					}
					.field_image{
						.slick-arrow{
							position: absolute;
							top: 50%;
							background: url(images/next.png) no-repeat center;
							width: 38px;
							margin-top: -19px;
							z-index: 1;
							height: 38px;
							cursor: pointer;
							outline: none;
							border: none;
							text-indent: -99999px;
							overflow: hidden;
							transition: .3s;
							&.slick-prev{
								left: 0;
								background-image: url(images/prev.png);
							}
							&.slick-next{
								right: 0;
							}
							&:hover{
								opacity: .7;
							}
						}
					}
				}
				.right_wrap{
					width: 31%;
					overflow: hidden;
					float: right;
					.views_row{
						padding: 0;
						box-shadow: none;
						text-align: center;
						margin-bottom: 21px!important;
						&:last-child{
							margin-bottom: 0!important;
						}
					}
					.field_img{
						text-align: center;
						img{
							display: block;
							margin: 0 0 7px;
						}
					} 
					.see_more{
						a{
							text-align: center;
							display: block;
							color: #18bb49;
						}
					}
				}
				.views_body{
					padding: 11px 0;
					.text{
						margin-bottom: 34px;
					}
				}
				.bottom_wrap{
					clear: both;
					.left_wrap{
						width: 35%;
						margin: 0;
						.info{
							display: inline-block;
							vertical-align: top;
							margin-right: 30px;
							.label{
								margin-bottom: 11px;
							}
							&.price{
								.label{
									font-weight: 700;
									color: #18bb49;
								}
							}
							.item{
								font:400 24px 'PFDinTextCompPro', sans-serif;
							}
						}
					}
					.right_wrap{
						width: auto;
						float: right;
						margin-top: 10px;
						.statistics{
							display: inline-block;
							vertical-align: middle;
							margin: 0 18px 0 0 ; 
						}
						.read_more{
							margin-top: 0;
							margin-right: 4px;
							&.preview{
								@include read_more(#03a9f4);
							}
						}						
					}
				}
				.area{
					display: inline-block;
					vertical-align: middle;
					margin-right: 20px;
				}
				.price{
					color: #d0021b;
					font-weight: 700;
					display: inline-block;
					vertical-align: middle;
				}
			}			
		}
	}
}
.newbuilding .block_newbuilding_row .block_content .view_content .views_row .price{
	color:#3b4047;
}
.block_process{
	.block_title{
		text-align: left;
		font-size: 36px;
		margin-bottom: 10px;
		display: inline-block;
		vertical-align: middle;
	}
	.create{
		@include read_more(#03a9f4);
		float: right;
		margin-bottom: 10px;
	}
	.views_row{
		background: #fff;		
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		padding: 0 20px;
		margin-bottom: 30px;
		box-sizing: border-box;
		.left_wrap{
			float: left;
			padding: 17px 0;
			margin-right: 20px;
			.read_more{
				@include read_more(#03a9f4);				
			}
		}
		.right_wrap{
			border-left: 1px solid rgba(151, 151, 151, .2);
			overflow: hidden;
			padding: 17px 0 20px 20px;
			box-sizing: border-box;
			.title{
				font:400 24px 'PFDinTextCompPro', sans-serif;
				margin-bottom: 15px;
			}
			.text{
				line-height: 20px;
				margin-bottom: 42px;
			}
			.webform_client_form{
				text-align: left;
				label{
					margin-bottom: 4px;
					display: block;
				}
				.form_textarea{	
					float: left;				
					width: 390px;
					margin-right: 30px!important;
					textarea{
						border:1px solid #bec7d1;
						height: 175px;
						padding-left: 20px;
						padding-top: 15px;
						background: #fff;
						box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
						box-sizing: border-box;
					}
				}
				.form_item{
					width: 100%;
					input{
						border:1px solid #bec7d1;
						height: 32px;
						box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
						box-sizing: border-box;
					}
				}
				.group{
					overflow: hidden;
					.form_item{
						margin-bottom: 20px;
						&:last-child{
							margin-bottom: 0;
						}
					}					
				}
			}
		}
	}
}
form {
	.status{
		.status_wrap{
			position: relative;			
			span{
				position: absolute;
				top: 7px;
				left: 9px;
				width: 16px;
				height: 16px;
				background: #000;
				border-radius: 100%;
				z-index: 1;
			}
			&.red{
				span{
					background: #ff1919;
				}
			}
		}
		select{
			position: relative;
			padding-left: 30px;
			height: 32px;
		}
		option{
			position: relative;
		}
	}
}
body.process .block_banner{
	text-align: center;
	margin-bottom: 32px;
}
.block_process.during .block_content .read_more {					
	@include read_more(#18bb49);
}
.block_services_wrap{
	.views_row{
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		background: #ffffff;
		height: 247px;
		box-sizing: border-box;
		.views_inner{
			overflow: hidden;			
		}
		.left_wrap{
			float: left;
			width: 50%;
		}
		.right_wrap{
			float: right;
			width: 50%;
		}
		.field_image {
			a{
				transition: .3s;
				&:hover{
					opacity: .7;
				}
			}
		}
		.title{
			text-transform: uppercase;
			position: relative;
			padding-bottom: 12px;
			margin-bottom: 20px;
			font:500 36px 'PFDinTextCompPro', sans-serif;
			&::after{
				content: '';
				width: 114px;
				height: 1px;
				background: rgba(151, 151, 151, .5);
				position: absolute;
				left: 0;
				bottom: 0;
			}
		}
		.text{
			font:400 24px 'PFDinTextCompPro', sans-serif;
		}
		&.odd{
			.left_wrap{
				padding: 29px;
				box-sizing: border-box;
			}
		}
		&.even{
			.right_wrap{
				padding: 29px;
				box-sizing: border-box;
			}
		}
	}
}
.social_likes_wrapper{
	.label{
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);		
		font:400 24px/36px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 2px;
	}
	.articles_inner &{
		.label{
			font-size: 20px;
		}
	}
}
.social-likes{
	margin: 0 0 -80px;
}
.social-likes_vertical .social-likes__widget{
	clear: none;
	min-width: 60px;
	height: 60px;	
	border-radius: 100%;
	margin: 0 15px;
	text-indent: -999999px;
	span{
		display: none;
	}
	.articles_inner & {
			margin: 0 10px;
	}
}
.social-likes__widget_facebook{
	background: url(images/fb_soc.png) no-repeat center / contain;
}
.social-likes__widget_twitter{
	background: url(images/tw_soc.png) no-repeat center / contain;	
}
.social-likes__widget_vkontakte{
	background: url(images/vk_soc.png) no-repeat center / contain;	
}
.social-likes__widget_mailru{
	background: url(images/mr.png) no-repeat center / contain;	
}
.social-likes__widget_plusone{
	background: url(images/plusone.png) no-repeat center / contain;	
}
.block_work_client{
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	background: #ffffff;
	padding: 30px;
	margin-bottom: 40px;
	box-sizing: border-box;
	.title{
		text-transform: uppercase;
		font:500 36px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 25px;
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 24%;
		.label{
			font:400 24px 'PFDinTextCompPro', sans-serif;	
			margin-bottom: 5px;		
		}
		a{
			text-decoration: none;
		}
	}
}
.block_contacts_specialists{
	.views_row{
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		padding: 30px;
		margin-bottom: 30px;
		box-sizing: border-box;
		.views_row_inner{
			overflow: hidden;
		}
		.left_wrap{
			float: left;
			margin-right: 28px;
		}
		.right_wrap{
			overflow: hidden;
			.name{
				text-transform: uppercase;
				margin-bottom: 23px;
				font:500 36px 'PFDinTextCompPro', sans-serif;	
			}
			.about_block{
				.field_item{
					display: inline-block;
					vertical-align: top;
					width: 24%;
					padding-right: 15px;
					margin-bottom: 26px;
					box-sizing: border-box;
					.label{
						font:400 24px 'PFDinTextCompPro', sans-serif;	
						margin-bottom: 8px;
					}
				}
			}
			.body_wrap{
				line-height: 20px;
				margin-bottom: 16px;
			}
			.video{
				text-align: center;
				max-width: 595px;
				margin: auto;
				.title{
					font:400 24px 'PFDinTextCompPro', sans-serif;	
					margin-bottom: 15px;
				}
			}
		}
	}
}
.block_benefits{
	text-align: center;
	padding-bottom: 48px;
	box-sizing: border-box;
	.block_title{
		margin-bottom: 40px;
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 19.7%;
		.repair_premises & ,
		.installation_water &{
			width: 24%;
		}
		.field_img{
			line-height: 104px;
			height: 104px;
			margin-bottom: 25px;
			img{
				vertical-align: middle;
			}
		}
		.text{
			font:400 24px 'PFDinTextCompPro', sans-serif;	
			padding: 0 25px;
			line-height: 1;
		}
	}
	.superlending &{
		padding-bottom: 12px;
		padding-top: 28px;
		.block_title{
			margin-bottom: 30px;
		}
		.views_row{
			width: 24%;
			.field_img {
				line-height: 120px;
				height: 120px;
			}
		}
	}
	.installation_water &{
		.field_img{
			line-height: 120px;
			margin-bottom: 15px;
			height: 120px;
		}
	}
	&.steps{
		padding: 50px 0 35px;
		box-sizing: border-box;
		.block_title{
			margin-bottom: 40px;
		}
		.views_row{
			width: 33%;			
			position: relative;
			&::after{
				content: '';
				position: absolute;
				width: 188px;
				height: 36px;
				top: 34px;
				right: -80px;
				background: url(images/arrow.png) no-repeat center / contain;
			}
			&:last-child{
				&::after{
					content: none;
				}
			}
			.text{
				max-width: 215px;
				padding: 0;
				margin: auto;
			}
		}
	}
}
.block_benefits.steps.second .group:last-child .views_row{
	width: 24%;
	&::after{
		width: 103px;
		top: 127px!important;
		right: -65px;
		background-position: center right;
		background-size: auto;
	}
	.text{
		height: 76px;
	}
}
.real_services .block_benefits:not(.steps){
	padding-bottom: 0;
	.views_row{
		width: 24%;
		margin-bottom: 68px;
	}
}
.real_services .block_benefits.steps,
.property_valuation .block_benefits.steps{
	.group{
		padding-bottom: 50px;
		margin-bottom: 46px;
		position: relative;
		&:first-child{
			background: url(images/line_1.png) no-repeat bottom right;
		}
		&:last-child{
			.field_img{
				margin-bottom: 0;
			}
			.text{
				margin-bottom: 25px;
			}
			.views_row::after{
				top: 110px;
			}
			.views_row:first-child::before{
				content: '';
				position: absolute;
				width: 100px;
				height: 36px;
				top: 110px;
				left: -80px;
				background: url(images/arrow.png) no-repeat top right ;

			}
		}
	}
	&.second{
		background: url(images/line_2.png) no-repeat top 237px center;
		.group{
			&:first-child{
				background: none;
			}
			&:last-child .views_row:first-child::before{
				content: none;
			}
		}
	}
}
.block_numbers{
	background: #fff;
	text-align: center;
	padding: 50px 0 20px 0;
	margin-bottom: 40px;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.block_title{
		margin-bottom: 40px;
	}
		.views_row{
			width: 22%;
			text-align: center; 	
			margin: 0 15px 63px;
			display: inline-block;
			vertical-align: top;
			.count{
				color: #03a9f4;
				font:400 72px/72px 'PFDinTextCompPro', sans-serif;	
				position: relative;
				margin-bottom: 11px;
				&::after{
					content: '';
					position: absolute;
					bottom: 0;
					left: 50%;
					margin-left: -60px;
					background: #03a9f4;
					height: 1px;
					width: 120px;
				}
			}
			.text{
				font:400 24px 'PFDinTextCompPro', sans-serif;					
			}
		}
}
.block_diplomas{
	padding: 50px 0 20px 0;
	margin-bottom: 40px;
	box-sizing: border-box;
	.trust & {
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
	.views_row{
		width: 32%;
		max-width: 370px;
		display: inline-block;
		vertical-align: top;
		text-align: center;
		margin: 0 25px 30px 0;
		background: #fff;
		line-height: 269px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		&:nth-child(3n+3){
			margin-right: 0;
		}
		img{
			vertical-align: middle;
		}
	}
}
.block_reviews{
	.slider_video{
		margin: 0 -20px 30px;		
		.views_row{
			width: 50%;
			max-width: 566px;
			float: left;
			height: 368px;
			margin: 0 20px;
			display: none;
			&.slick-slide,
			&:first-child{
				display: block;
			}
		}
		.slick-arrow{
			text-indent: -99999px;
			position: absolute;
			overflow: hidden;
			border: none;
			background: none;
			outline: none;
			width: 25px;
			height: 48px;
			top: 50%;
			cursor: pointer;
			margin-top: -24px;
			background: url(images/arrow_next.png) no-repeat center / contain;
			transition: opacity .3s;
			&:hover{
				opacity: .7;
			}
			&.slick-prev{
				left: -35px;
				transform: rotate(-180deg);
			}
			&.slick-next{
				right: -35px;
			}
		}
	}
	.reviews_text{
		padding-bottom: 10px;
		.view_row{
			// float: left;
			width: 370px;
			background: #fff;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
			margin-bottom: 30px;
			box-sizing: border-box;
			.field_image{
				margin-bottom: 15px;
				img{
					display: block;
					margin: auto;
				}
			}
			.name{
				font:400 24px 'PFDinTextCompPro', sans-serif;	
				margin-bottom: 10px;
			}
			.city{
				color: #89919e;
				margin-bottom: 13px;
			}
			.views_body{
				padding: 0 19px 26px;
				box-sizing: border-box;
			}
			.text{
				line-height: 20px;
			}
		}
	}
}
.block_questions{
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	background: #fff;
	padding: 38px 0 40px;
	box-sizing: border-box;
	.views_row{
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
		border: 1px solid #e3e2e2;
		padding: 15px;
		background: #fbfbfb;
		box-sizing: border-box;
		transition: .3s;
		.qty_text{
			font:400 24px/46px 'PFDinTextCompPro', sans-serif;	
			position: relative;
			padding-left: 54px;
			padding-right: 70px;
			cursor: pointer;
			background: url(images/qty.png) no-repeat top left;
			box-sizing: border-box;
			transition: .3s;
			.item{
				width: 70%;
			}
			span{
				width: 20px;
				height: 20px;
				position: absolute;
				top: 10px;
				right: 0px;
				cursor: pointer;
				background: url(images/max.png) no-repeat center / contain;
			}
		}
		&.active{
			background: #fff;
			position: relative;
			z-index: 10;
			box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15);
			.qty_text{
				border-color:transparent;
				margin-bottom: 15px;
				span{
					background-image: url(images/min.png);
				}
			}
			.body{
				display: block;
			}
			& + .views_row{
				.qty_text{
					border-top-color: transparent;
				}
			}
		}
		.body{
			display: none;
			padding-left: 55px;
			line-height: 20px;
			box-sizing: border-box;
		}
	}
}
.block_price_wrapper{
	background: #fff;	
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	padding: 40px 0 50px;
	margin-bottom: 30px;
	box-sizing: border-box;
	.thead{
		overflow: hidden;
		line-height: 20px;
		border-bottom: 1px solid rgba(151, 151, 151, .5);
		padding-bottom: 13px;
		box-sizing: border-box;
		.left{
			float: left;
		}
		.right{
			float: right;
		}
	}
	.tbody{
		.views_row{
			padding: 15px 0;
			font: 400 24px 'PFDinTextCompPro', sans-serif;
			overflow: hidden;
			border-bottom: 1px solid rgba(151, 151, 151, .5);
			box-sizing: border-box;
			.left{
				float: left;
				width: 65%;
			}
			.right{
				float: right;
				width: 200px;
				text-align: right;
			}
			&:last-child{
				border-bottom: 0;
			}
		}
	}
	.calculate_form{
		text-align: center;
		.title{
			text-transform: uppercase;
			font: 500 36px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 10px;
		}
		.subtitle{
			font: 400 24px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 12px;
		}
		.form_actions a{
			@include submit;
			text-decoration: none;
			display: inline-block;
			vertical-align: middle;
			line-height: 46px;
		}
	}
}
.block_our_works{
	margin-bottom: 60px;
	.views_row{
		display: inline-block;
		vertical-align: top;
		margin: 0 25px 30px 0;
		&:nth-child(3n+3) {
			margin-right: 0;
		}
		img{
			display: block;
			margin: auto;
		}
	}
	.footnote{
		text-align: center;
		font: 400 24px 'PFDinTextCompPro', sans-serif;
	}
}
.region_map{
	img{
		display: block;
		margin: auto;
	}
}
.block_ready{
	padding-bottom: 40px;
	box-sizing: border-box;
	.view_content{
		text-align: center;
		& > div{
			display: inline-block;
			vertical-align: middle;
			margin: 0 11px;
		}
		.submit a{
			@include submit;
			text-decoration: none;
			display: inline-block;
			vertical-align: middle;
			line-height: 46px;
		}
		.order{
			@include read_more(#03a9f4);
		}
	}
}
.block_hydrometers{
	padding: 45px 0 32px;
	box-sizing: border-box;
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 270px;
		margin: 0 26px 30px 0;
		&:nth-child(4n+4){
			margin-right: 0;
		}
		.field_img{
			margin-bottom: 13px;
			img{
				display: block;
				margin: auto;
			}
		}
		.title{
			text-align: center;
			font: 400 24px 'PFDinTextCompPro', sans-serif;
		}
	}
}
.block_photo{
	text-align: center;
	margin-bottom: 27px;
	.view_content{
		margin: 0 -13px;
		.views_row{
			display: inline-block;
			vertical-align: top;
			margin: 0 13px 30px;
			.filed_img{
				img{
					display: block;
					margin: auto;
				}
			}		
		}
	}
	.universal &{
		margin-bottom: 0;
	}
}
.block_package{
	.views_row{
		display: inline-block;
		vertical-align: top;
		max-width: 370px;
		width: 100%;
		padding-bottom: 30px;
		margin: 0 26px 30px 0;
		background: #fff;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
		&:nth-child(3n+3){
			margin-right: 0;
		}
		.title{
			text-align: center;
			color:#fff;
			text-transform: uppercase;
			height: 74px;
			font: 400 36px/74px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 29px;
		}
		&.beginner{
			.title{				
				background: rgba(3,186,247,1);
				background: -moz-linear-gradient(top, rgba(3,186,247,1) 0%, rgba(0,144,236,1) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3,186,247,1)), color-stop(100%, rgba(0,144,236,1)));
				background: -webkit-linear-gradient(top, rgba(3,186,247,1) 0%, rgba(0,144,236,1) 100%);
				background: -o-linear-gradient(top, rgba(3,186,247,1) 0%, rgba(0,144,236,1) 100%);
				background: -ms-linear-gradient(top, rgba(3,186,247,1) 0%, rgba(0,144,236,1) 100%);
				background: linear-gradient(to bottom, rgba(3,186,247,1) 0%, rgba(0,144,236,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03baf7', endColorstr='#0090ec', GradientType=0 );
			}
		}
		&.advanced{
			.title{
				background: rgba(32,206,93,1);
				background: -moz-linear-gradient(top, rgba(32,206,93,1) 0%, rgba(14,165,49,1) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(32,206,93,1)), color-stop(100%, rgba(14,165,49,1)));
				background: -webkit-linear-gradient(top, rgba(32,206,93,1) 0%, rgba(14,165,49,1) 100%);
				background: -o-linear-gradient(top, rgba(32,206,93,1) 0%, rgba(14,165,49,1) 100%);
				background: -ms-linear-gradient(top, rgba(32,206,93,1) 0%, rgba(14,165,49,1) 100%);
				background: linear-gradient(to bottom, rgba(32,206,93,1) 0%, rgba(14,165,49,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20ce5d', endColorstr='#0ea531', GradientType=0 );
			}
		}
		&.expert{
			.title{
				background: rgba(248,189,47,1);
				background: -moz-linear-gradient(top, rgba(248,189,47,1) 0%, rgba(241,141,22,1) 100%);
				background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,189,47,1)), color-stop(100%, rgba(241,141,22,1)));
				background: -webkit-linear-gradient(top, rgba(248,189,47,1) 0%, rgba(241,141,22,1) 100%);
				background: -o-linear-gradient(top, rgba(248,189,47,1) 0%, rgba(241,141,22,1) 100%);
				background: -ms-linear-gradient(top, rgba(248,189,47,1) 0%, rgba(241,141,22,1) 100%);
				background: linear-gradient(to bottom, rgba(248,189,47,1) 0%, rgba(241,141,22,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8bd2f', endColorstr='#f18d16', GradientType=0 );
			}
		}
		.views_body{
			line-height: 20px;
			padding: 0 17px 0 28px;
			box-sizing: border-box;
			ul{
				list-style: none;
				li{
					position: relative;
					padding-left: 24px;
					margin-bottom: 23px;
					box-sizing: border-box;
					&::after{
						content: '';
						position: absolute;
						top: 8px;
						left: 0;
						width: 3px;
						height: 3px;
						border-radius: 100%;
						background: #3b4047;
					}
				}
			}
			.submit {
				text-align: center;
				a{
					@include submit;
					display: inline-block;
					vertical-align: middle;
					margin: auto;
					line-height: 46px;
					text-align: center;
					text-decoration: none;
				}
			}
		}
	}
}
.block_our_advantages {
	padding-top: 45px;
	text-align: center;
	.insurance &{
		margin-bottom: 20px;
		.block_title{
			margin-bottom: 45px;
		}
		.field_image{
			height: 105px;
		}
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 370px;
		margin: 0 26px 30px 0;
		&:nth-child(3n+3){
			margin-right: 0;
		}
		.field_image{
			margin-bottom: 13px;
			// height: 110px;
			img{
				display: block;
				margin: auto;
			}
		}
		.title{
			position: relative;
			font: 400 36px 'PFDinTextCompPro', sans-serif;
			margin-bottom: 10px;
			padding-bottom: 7px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				margin-left: -58px;
				width: 116px;
				height: 3px;
				background: #17bb49;
			}
		}
		.subtitle{
			font: 400 24px 'PFDinTextCompPro', sans-serif;
		}
	}
}
.block_compensate{
	text-align: center;
	.views_row{
		display: inline-block;
		vertical-align: top;
		margin: 0 26px 30px 0;
		&:nth-child(4n+4) {
			margin-right: 0;
		}
		.field_image{
			margin-bottom: 13px;
			img{
				margin: auto;
				display: block;
			}
		}
		.title{
			font: 400 24px/26px 'PFDinTextCompPro', sans-serif;
		}
	}
}
.block_should_use{
	text-align: center;
	.block_title{
		margin-bottom: 0;
		line-height: 1.1;
	}
	.subtitle{
		font: 400 36px 'PFDinTextCompPro', sans-serif;
		position: relative;
		padding-bottom: 12px;
		margin-bottom: 12px;
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			height: 3px;
			margin-left: -79.5px;
			width: 159px;
			background: #17bb49;
		}
	}
	.title_text{
		font: 400 24px 'PFDinTextCompPro', sans-serif;	
		margin-bottom: 21px;	
	}
	.views_row{
		display: inline-block;
		vertical-align: top;
		width: 270px;
		margin: 0 26px 35px 0;
		&:last-child{
			margin-right: 0;
		}
		.field_image{
			position: relative;
			margin-bottom: 45px;
			& > img{
				display: block;
				margin: auto;
			}
			.icon{
				position: absolute;
				bottom: -35.5px;
				left: 50%;
				width: 71px;
				height: 71px;
				margin-left: -35.5px;
				line-height: 71px;
				border-radius: 100%;
				box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
				background: #ffffff;
				box-sizing: border-box;
				img{
					vertical-align: middle;
				}
			}
		}
		.text{
			line-height: 20px;
		}
	}
}
.block_we_have{
	background: url(images/bg_3.jpg) no-repeat top center / cover;
	color:#fff;
	padding: 54px 0 64px;
	margin-bottom: 42px;
	box-sizing: border-box;
	.block_title{
		font-size: 64px;
		font-weight: 400;
		position: relative;
		margin-bottom: 58px;
		padding-bottom: 24px;
		box-sizing: border-box;
		&::after{
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			height: 3px;
			background: #fff;
			width: 220px;
			margin-left: -110px;
		}
	}
	.group{
		display: inline-block;
		vertical-align: top;
		width: 48%;
		margin-bottom: 35px;
		ul{
			list-style: none;
			li{
				position: relative;
				text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
				font: 500 20px/34px 'PFDinTextCompPro', sans-serif;	
				margin-bottom: 24px;
				min-height: 32px;
				padding-left: 43px;
				box-sizing: border-box;
				&::after{
					width: 32px;
					height: 32px;
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					background: url(images/check.png) no-repeat center;
				}
			}
		}
	}
	.bottom_wrap{
		.title{
			text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
			text-align: center;
			font: 400 36px 'PFDinTextCompPro', sans-serif;	
			text-transform: uppercase;
			margin-bottom: 38px;
		}
		.webform_client_form{			
			.form_item{		
				margin-right: 6px;		
				&.name{
					input{
						background-image: url(images/user.png);
					}
				}
			}
		}
	}
}	
.block_advantages_1{
	text-align: center;
	padding: 13px 0 35px 0;
	box-sizing: border-box;
	.views_row{
		display: inline-block;
		vertical-align: top;
		margin: 0 26px 30px 0;
		&:nth-child(4n+4){
			margin-right: 0;
		}
		.field_image{
			margin-bottom: 15px;
			img{
				display: block;
				margin: auto;
			}
		}
		.title{
			text-transform: uppercase;
			font: 400 24px/26px 'PFDinTextCompPro', sans-serif;	
		}
	}
}
.block_node_content{
	background:#fff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
	padding: 25px 30px 30px;
	box-sizing: border-box;
	.field_body{
		line-height: 20px;
		span{
			font: 400 24px 'PFDinTextCompPro', sans-serif;	
			display: block;
			margin-bottom: 16px;
		}
		img.left{
			float: left;
			margin: 0 25px 17px 0;
		}
		iframe{
			margin-bottom: 20px;
		}
		h2{
			font: 400 24px 'PFDinTextCompPro', sans-serif;	
			margin-bottom: 17px;			
		}
		p:last-child{
			margin-bottom: 0;
		}
	}
}
.articles_inner #content{
	margin-bottom: 37px;
}
.block_video_transfer{
	text-align: center;
	margin-bottom: 24px;
	.views_row{
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		background: #fff;
		margin-bottom: 30px;
		text-align: left;
		padding: 17px 30px 30px;
		box-sizing: border-box;
		.views_row_inner{
			overflow: hidden;
		}
		.title{
			font: 400 24px 'PFDinTextCompPro', sans-serif;	
			margin-bottom: 12px;
		}
		.field_image{
			float: left;
			margin-right: 20px;
			img{
				display: block;
				margin: auto;
			}
		}
		.field_nid{
			overflow: hidden;
			margin-bottom: 3px;
		}
		ul{
			list-style: none;
			li{
				position: relative;
				line-height: 20px;
				margin-bottom: 23px;
				padding-left: 25px;
				box-sizing: border-box;
				&::after{
					content: '';
					position: absolute;
					top: 6px;
					left: 0;
					background: #3b4047;
					width: 3px;
					height: 3px;
					border-radius: 100%;
				}
			}
		}
		.field_info{
			overflow: hidden;
			color: #89919e;
			font: 400 24px 'PFDinTextCompPro', sans-serif;	
			.stat{
				padding-left: 45px;
				background: url(images/stat.png) no-repeat top 1px left;
				margin-right: 23px;
				display: inline-block;
				vertical-align: middle;
			}
			.time{
				display: inline-block;
				vertical-align: middle;
				padding-left: 39px;
				background: url(images/hours.png) no-repeat top left;
				line-height: 31px;
			}
		}
	}
}
.favorites {
	.block_shops,
	.block_earth {
		padding-top: 0;
		.block_title{
			text-transform: uppercase;
			color: #03a9f4;
			font: 500 36px 'PFDinTextCompPro', sans-serif;
			text-align: left;
			margin-bottom: 8px;
		}
	}
	.block_newbuilding_row{
		.views_row {
			.views_body {

				.address{
					margin-bottom: 0;
				}
			}
			.left_wrap{				
				.price.info{
					color:#3b4047;
				}
			}
		}
	}
	.block_earth .views_row .left_wrap,
	.block_earth .views_row .right_wrap{
		max-width: 347px;
	}
}
.block_final_wrap{
	.group_title{
		text-transform: uppercase;
		color: #03a9f4;
		text-align: left;
		font: 500 36px 'PFDinTextCompPro', sans-serif;
		margin-bottom: 10px;
	}
	.group_wrap{
		margin-bottom: 4px;
	}
	.views_row{
		background: #fff;
		padding: 25px 30px;
		margin-bottom: 30px;
		box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		box-sizing: border-box;
		.webform_client_form{
			text-align: left;
			overflow: hidden;
			input,
			textarea{
				border: 1px solid #bec7d1;
				-webkit-box-shadow: 0 1px 0 0 #e5e5e5;
				box-shadow: 0 1px 0 0 #e5e5e5;
				box-sizing: border-box;
			}
			.left_wrap{
				float: left;
				width: 864px;
				margin-right: 30px;
				.title{
					font: 400 24px 'PFDinTextCompPro', sans-serif;
					margin-bottom: 8px;
				}
				.text{
					line-height: 20px;
					margin-bottom: 30px;
				}
				.form_item.textarea{
					label{
						display: block;
						margin-bottom: 4px;
					}
					textarea{
						width: 100%;
						height: 64px;
						background: #fff;
						padding-left: 12px;
					}
				}
			}
			.right_wrap{
				overflow: hidden;
				.status{
					padding-left: 25px;
					margin-bottom: 23px;
					line-height: 20px;
					div{
						position: relative;						
						&::after{
							content: '';
							background: #3b4047;
							width: 16px;
							height: 16px;
							border-radius: 100%;
							position: absolute;
							top: 1px;
							left: -25px;
							box-sizing: border-box;
						}						
						&.important{
							&::after{
								background: #ff1919;								
							}
						}
					}
				}
				label{
					margin-bottom: 4px;
					display: block;
				}
				.form_item{
					width: 100%;
					margin-bottom: 22px;
					input{
						width: 100%;
						height: 32px;
					}
				}
			}
		}
	}
}
.block_webform{
	background:#fff;
	max-width: 1170px;
	margin: auto;
	position: fixed;
	z-index: 100;
	width: 100%;
	margin-left: -585px;
	top: 50%;
	left: 50%;
	padding: 30px;
	transition: .3s;
	box-sizing: border-box;
	&#block-webform-confirm{
		max-width: 350px;
		margin-left: -175px;
		margin-top: -39px;
		.close{
			width: 30px;
			height: 30px;
			top: 24px;
		}
	}
	.block_title{
		margin: 0 auto 30px;
		max-width: 790px;
	}
	.close{
		position: absolute;
		top: 30px;
		right: 30px;
		cursor: pointer;
		width: 50px;
		height: 50px;
		background: url(images/close.png) no-repeat center / contain;
		transition: .3s;
		&:hover{
			transform:rotate(180deg);
		}
	}
	&.block_order_form {
		display: none;
		max-width: 710px;
		margin-left: -355px;
		.webform_client_form{
			.top_wrap{
				width: auto;
				float: left;
				margin-right: 30px;
				.form_item.title_item{
					width: 100%;
					margin-right: 0;
					margin-bottom: 25px;
				}
				.form_item{
					// width: 47%;					
					&.phone{
						// float: right;
					}
				}
			}
			.form_actions{
				width: auto;
				vertical-align: bottom;
				float: left;
				margin-top: 7px;
				margin-bottom: 30px;
			}
		}
	}
	.webform_client_form{
		text-align: left;
		max-width: 632px;
		margin: auto;
		input,
		select{
			height: 32px;
		}
		input,
		textarea{
			border: 1px solid #bec7d1;
			-webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
		}
		label{
			margin-bottom: 3px;
			display: block;
		}
		.top_wrap{
			margin-bottom: 30px;
			.form_item{
				width: 200px;
				margin-right: 27px;
				&.date{
					width: 184px;
				}
				&:last-child{
					margin-right: 0;
				}				
			}
		}
		.bottom_wrap{
			.form_item.textarea{
				float: left;
				width: 414px;
				margin-right: 30px!important;
				textarea{
					background: #fff;
					padding-left: 12px;
					height: 118px;
				}
			}
			.form_item.form_select{
				overflow: hidden;
				width: 184px;
				display: block;
				margin-bottom: 33px;
			}
		}
		.form_actions{
			text-align: center;
			width: 100%;
			input{
				display: inline-block;
				vertical-align: middle;
				height: 46px;
				border: none;
				outline: none;
			}
		}
	}
	.text{
		text-align: center;
		font-size: 18px;
		margin-bottom: 30px;
		a{
			text-decoration: none;
		}
	}
	&.webform_client,
	&.webform_object{
		.block_title{
			line-height: 1;
			position: relative;
			padding-bottom: 20px;
			margin-bottom: 30px;
			&::after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 220px;
				height: 3px;
				margin-left: -110px;
				background-color: #3b4047;
			}
		}
		.webform_client_form{
			max-width: 920px;
			.form_item{
				margin-right: 6px;
				input{
					height: 42px;
				}
			}
			.address{
				width: 386px;
				input{
					background: url(images/address.png) no-repeat center left 15px;
					padding-left: 45px;
				}
			}
			.name{
				input{
					background-image: url(images/user.png);
				}
			}
			.price{
				width: 104px;
				margin-right: 0;
				input{
					background: url(images/price.png) no-repeat center left 15px;
					padding-left: 45px;
				}
			}
			.form_item.textarea{
				width: 100%;
				float: none;
				margin: 0 0 16px;
				textarea{
					padding-left: 45px;
					background: url(images/email.png) no-repeat top 15px left 15px;
				}
			}
			.top_wrap{
				margin-bottom: 10px;
			}
		}
	}
	&.webform_object{
		.webform_client_form{		
			text-align: center;	
			.form_actions{
				display: inline-block;
				vertical-align: middle;
				width: auto;
			}
			.form_item{
				width: 200px;
				margin-right: 25px;
				input{
					height: 46px;
				}
			}
		}
	}
	.filters {
		text-align: center;
		margin-bottom: 30px;
		.item{
			font-size: 14px;
			font-weight: 400;
			border: 1px solid #a9d5e8;
			display: inline-block;
			vertical-align: middle;
			height: 36px;
			line-height: 36px;
			padding: 0 37px 0 11px;
			position: relative;
			background: #fff;
			margin-right: 37px;
			box-sizing: border-box;
			.close{
				background: #fff url(images/filter.png) no-repeat center right 0px;
				content: '';
				top: 10px;
				right: 10px;
				width: 15px;
				height: 15px;
				cursor: pointer;
				position: absolute;
			}
		}
	}
}


/*----------------------------------------------------------------------------
									FOOTER
----------------------------------------------------------------------------*/
.footer.active {
	height: 325px;	
	.footer_bottom{
		display: block;
	}
}
.footer {
	width: 100%;
	margin: -180px auto 0;
	height: 180px;
	position: relative;
	clear: both;
	padding: 20px 0;
	background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center / cover;
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
	box-sizing: border-box;
	.footer_content{
		max-width: 1170px;
		margin:0 auto;
	}
	.footer_top{
		float: left;
		width: 100%;
		border-bottom: 1px solid rgba(151, 151, 151, 0.5);
		padding-bottom: 30px;
		.region_footer .menu_footer{
			a{
				margin-bottom: 0;
			}
		}
	}
	.footer_bottom{
		clear: both;
		display: none;
		padding-top: 30px;
	}
	.region_footer{
		max-width: 1170px;
		margin: auto;
		position: relative;
		.logo_wrapper{
			float: left;
			width: 230px;
			margin-right: 35px;
			a.logo{
				margin-bottom: 10px;
				display: block;
				text-decoration: none;
				text-transform: uppercase;
				img{
					margin-right: 10px;
				}
				span{
					display: inline-block;
					vertical-align: bottom;
					margin-bottom: 10px;
				}
				.site_name{
					color: #03a9f4;
					font: 500 31px 'PFDinTextCompPro', sans-serif;
				}
				.domen{
					font: 400 19px 'PFDinTextCompPro', sans-serif;
					color: #3b4047;
					margin-bottom: 14px;
				}
			}
			.copyright{
				font-size: 12px;
				font-weight: 400;
				line-height: 17px;
				padding-right: 7px;
				box-sizing: border-box;
			}
		}
		.footer_center{
			width: 480px;
			float: left;
			margin-right: 20px;
		}
		.menu_footer{
			li.group_item{
				display: inline-block;
				vertical-align: middle;
				margin-right: 57px;
				&:nth-child(3n+3){
					margin-right: 0;
				}
				.expanded{
					position: relative;
					.expanded_btn{
						position: relative;
						padding-right: 15px;
						&::after{
							content: '';
							position: absolute;
							top: 9px;
							right: 0;
							border: 4.5px solid transparent;	
							border-top: 4.5px solid #4a4a4a;						
						}
					}
				}
				.disabled{
					display: none;
					position: absolute;
					top: 100%;
					left: 0;
					background: #fff;
					padding: 5px 15px;
				}
			}
			a{
				font-size: 14px;
				font-weight: 400;
				line-height: 24px;
				display: block;
				margin-bottom: 7px;
				text-decoration: none;
				transition: .3s;
				&:hover{
					color:#1fb239;
					text-decoration: underline;
				}
			}
		}		
		.footer_right{
			padding-top: 40px;
			float: right;
			& > div{
				float: left;
			}
			.buttons_header{
				margin-left: 20px;
			}
		}
		.visit{
			margin-top: 15px;
			.item{
				display: inline-block;
				vertical-align: middle;
				& + .item{
					margin-left: 20px;
				}
			}
		}
	}
	.footer_bottom {
		.menu_footer{
			li.group_item{
				margin-right:75px;
				&:nth-child(3n+3){
					margin-right: 88px;
				}
				&:last-child{
					margin-right: 0px;
				}				
			}
		}
	}
	.phone_number a{
		font-size: 36px;
	}
	.email{
		margin-bottom: 10px;
	}
	.social_links {
		position: absolute;
		top: -46px;
		right: 0;
		z-index: 1;
		.item {
			display: inline-block;
			vertical-align: middle;
			& + .item{
				margin-left: 17px;
			}
		}
		a{
			width: 52px;
			height: 52px;
			display: inline-block;
			vertical-align: middle;			
		}
		.fb a{
			background: url(images/fb.png) no-repeat center / contain;
		}
		.vk a{
			background: url(images/vk.png) no-repeat center / contain;
		}
		.tw a{
			background: url(images/tw.png) no-repeat center / contain;
		}
		.gp a{
			background: url(images/gp.png) no-repeat center / contain;
		}
	}
}
/*----------------------------------------------------------------------------
									PAGER
----------------------------------------------------------------------------*/
.pager{
	position: relative;
	width: 310px;
	margin: 0 auto !important;
	clear: both;
	li{
		padding: 0 !important;
		margin: 0 !important;
		a{
			font: 700 24px 'Arial';
			color: #514e4e;
			text-decoration: none;
			&:hover{
				color: #a34fb0;
			}
		}
		&.pager-item{
			a{
				 padding: 7px 14px 2px 14px !important;
			    margin: 0 !important;
			    line-height: 1 !important;
			    display: inline-block !important;
			}
		}
		&.pager-next,&.pager-previous{
			a{
				display: inline-block;
				width: 24px;
				height: 14px;
				text-indent: -9999px;
				position: absolute;
				top: 50%;
				margin-top: -7px;
			}
		}
		&.pager-next{
			a{
				background: url("images/pager_arrows.png") no-repeat scroll right 0 transparent;
				right: 0;
				&:hover{
					background: url("images/pager_arrows.png") no-repeat scroll right bottom transparent;
				}
			}
		}
		&.pager-previous{
			a{
				background: url("images/pager_arrows.png") no-repeat scroll 0 0 transparent;
				left: 0;
				&:hover{
					background: url("images/pager_arrows.png") no-repeat scroll 0 bottom transparent;
				}
			}
		}
		&.pager-current {
			color: #fff;
			background:#02ae5f !important;
			font: 700 24px 'Arial';
		    padding: 7px 14px 2px 14px !important;
		    margin: 0 !important;
		    line-height: 1 !important;
		    display: inline-block !important;
		}
	}
	.pager-last,.pager-first{
		display: none !important; 
	}
}
.item-list.pager_my_ui .pager-current {
    background: none;
    border: none;
}
/*----------------------------------------------------------------------------
									LOADER
----------------------------------------------------------------------------*/
.ajax-progress {
	background: rgba(255,255,255,.8);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	text-indent: -9999px;
	margin: 0;
	padding: 0;
	z-index: 1000;
}
.ajax-progress .throbber{
    background: none;
    border-radius: 6px;
    position: fixed;
    left: 50%;
    top: 50%;
    width:50px;
    height:50px;
    text-indent: -9999px;
    margin: 0;
    padding: 0;
    border: 8px solid rgba(0,0,0,.3);
    border-left: 8px solid #000;
    border-radius: 100%;
    -webkit-animation: rotate 0.7s linear infinite;
    -moz-animation: rotate 0.7s linear infinite;
    animation: rotate 0.7s linear infinite;
    margin-top: -25px;
    margin-left: -25px;
}
@-webkit-keyframes rotate{
	0%{
		-webkit-transform:rotate(0deg);
	}
	100%{
		-webkit-transform:rotate(360deg);
	}
}

@-webkit-keyframes fadeOut{
	0%{
		-webkit-transform:scale(0);
	}
	50%{
		-webkit-transform:scale(1);opacity: 0.1;
	}
	100%{
		-webkit-transform:scale(.1);opacity: 0.8;
	}
}
@-webkit-keyframes strechdelay{
	0%,50%,100%{
		-webkit-transform:scaleY(.4);
	}
	20%{
		-webkit-transform:scaleY(1);
	}
}
@-moz-keyframes rotate{
	0%{
		-moz-transform:rotate(0deg);
	}
	100%{
		-moz-transform:rotate(360deg);
	}
}

@-moz-keyframes fadeOut{
	0%{
		-moz-transform:scale(0);
	}
	50%{
		-moz-transform:scale(1);opacity: 0.1;
	}
	100%{
		-moz-transform:scale(.1);opacity: 0.8;
	}
}
@-moz-keyframes strechdelay{
	0%,50%,100%{
		-moz-transform:scaleY(.4);
	}
	20%{
		-moz-transform:scaleY(1);
	}
}
@keyframes rotate{
	0%{
		transform:rotate(0deg);
	}
	100%{
		transform:rotate(360deg);
	}
}

@keyframes fadeOut{
	0%{
		transform:scale(0);
	}
	50%{
		transform:scale(1);opacity: 0.1;
	}
	100%{
		transform:scale(.1);opacity: 0.8;
	}
}
@keyframes strechdelay{
	0%,50%,100%{
		transform:scaleY(.4);
	}
	20%{
		transform:scaleY(1);
	}
}
/*----------------------------------------------------------------------------
									BASIC STYLES
----------------------------------------------------------------------------*/
.grippie {
	display: none !important; 
}
.webform-confirmation + .links{
	display: none !important;
}
ul.menu, ul {
	li{
		margin: 0;
		padding: 0;
		list-style: none;
	}
}
input{
	vertical-align: middle;
	box-sizing: border-box;
	&.form-submit {
		cursor: pointer;
		transition: 0.3s;
		&:hover{
			opacity: 0.8;
		}
	}
	&.form-text{
	    transition: 0.3s;
	    &:focus{
	    	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
	    }
	}
}
textarea{
	box-sizing: border-box;
	transition: 0.3s;
    &:focus{
    	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important;
    }
}
input.error,
textarea.error,
input[type="text"].error,
input[type="password"].error{
	border:1px solid red !important;
}
.jGrowl-notification.error {
    background-color: #CC0000 !important;
}
.jGrowl-notification.status {
    background-color: #009900 !important;
}
#jGrowl .jGrowl-notification {
    background-position: 4px 8px !important;
}
.form-item, .form-actions {
	margin: 0;
}
a {
	color: #514e4e;
	text-decoration: underline;
	&:hover{
		text-decoration: none;
	}
}
img{
	max-width: 100%;
	height: auto;
	border: none;
}
* {
	margin: 0;
	padding: 0;
	outline: none;
}
p {
	margin: 0 0 18px;
}
body.admin-menu {
	margin-top: 0 !important;
	padding-top: 32px;
}
.admin_tabs{
	background: rgba(0,0,0,0.6);
	position: fixed;
	bottom: 10px;
	left: 10px;
	z-index: 95;
	.tabs {
		border-bottom: 0 !important;
		a {
			font-size: 16px;
			color: #fff !important;
			background: none !important;
			border: none !important;
			&.active,&:hover{
				color: rgb(100, 195, 0) !important;
			}
		}
	}
}
.page-user {
	.page_title{
		text-align: center;
	}
	#middle {
		margin: 0 auto;
		text-align: center;
	}
	#block-system-main {
		form{
			display: table;
			margin: 0 auto;
		}
		.description {
			display: none;
		}
		label {
			font-size: 16px;
			color: #000;
			margin-top: 10px;
		}
		input {
			width: 230px;
			height: 33px;
			border: 1px solid #dededf;
			padding: 0 10px;
			color: #8a8989;
			font-size: 16px;
			&.form-submit {
				height: 30px;
				border: 0;
				background: #888;
				cursor: pointer;
				font-size: 16px;
				color: #fff;
				text-transform: uppercase;
				margin-top: 20px;
			}
		}
	}
}
.page-error-403,.page-error-404,.maintenance-page{
	.page_title{
		text-align: center;
	}
	.clearfix,.errores_page_content{
		text-align: center;
		color: #888;
		font-size: 16px;
	}
}
.maintenance-page .logo-floater {
	margin: 0 auto;
	padding-top: 15px;
	display: table;
}
.popup_exit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: none;
    z-index: 99;
    .popup &{
    	display: block;
    }
}
#gl_devel{
/*	position: absolute;
	left: 50%;
	margin-left: -585px;
	top: 110px;*/
}
#gl_devel{
	height:20px;
	clear: both;
}
#gl_devel span,
#gl_devel a{
	font:12px/20px 'cuprumregular';
	text-transform:uppercase;
}
#gl_devel span{
	color:#999999;
}
#gl_devel img{
    margin-right: 5px;
    position: relative;
    top: 3px;
}
#gl_devel a{
	color:#999999;
	text-decoration:none;
}
body.ie_browser #gl_devel a,
body.ie_browser #gl_devel span{
	font-size:8px;
}
#gl_devel a:hover{
	text-decoration:underline;
}
.fixed_mobile{
	display: none;
}
.to_up{
	position: absolute;
	top: -26px;
	right: 40px;
	width: 52px;
	height: 52px;
	background: url(images/up.png) no-repeat center / contain;
	cursor: pointer;
	transition: .3s;
	&:hover{
		opacity: .7;
	}
}
/*----------------------------------------------------------------------------
									ADAPTIVE STYLES
----------------------------------------------------------------------------*/
@media (max-width:767px){
	.fixed_mobile {
	    position: fixed;
	    left: 0;
	    bottom: 0px;
	    z-index: 90;
	    width: 100%;
	    text-align: center;
	    background: #fff;
	    box-shadow: 0 0px 10px 0 rgba(0,0,0,0.2);
	    font-size: 20px;
	    min-width: 320px;
	    -webkit-transform: translateZ(0);
	    display: block;
		a {
		    display: block;
			span {
			    display: inline-block;
			    vertical-align: top;
			    padding: 10px 0 10px 30px;
			    background: url(images/ico_phone.png) no-repeat scroll 0 center transparent;
			    cursor: pointer;
			}
		}
	}
}
@media (min-width: 991px) and (max-width:1023px){
	html{
		min-width: 320px;
	}
	.header {
		.header_content{
			height: auto;
			.benefits{
				width: 290px;
			}
		}
		.region_header{
			float: none;
			overflow: visible;
			padding-top: 10px;
			& > div{
				float: right;
			}
			.benefits{
				margin-left: 0;
			}
			.buttons_header {	
				float: none;	
				clear: both;		
				text-align: center;
				margin: 0;
				width: 100%;
				.application,
				.add_advert{
					width: 192px;
					display: inline-block;
					vertical-align: top;
					margin: 5px 15px 30px;
				}
			}
		}
		.logo_wrapper {
			width: 215px;
			margin: 9px 25px 9px 0;
			display: block;
			a.logo {
			span{
				margin-bottom: 10px;
			}			
				.site_name{
					font-size: 28px;
				}
				.domen{
					font-size: 18px;
					margin-bottom: 15px;
				}
			}
		}
		.block_main_menu .menu li.leaf > a{
			font-size: 18px;
		}
		.region_header > div + div{
			margin-left: 25px;
		}
	}
	.slider_main{
		padding-left: 15px;
		padding-right: 15px;
		.slider_title{
			font-size: 43px;
			line-height: 1.2;
		}
	}
	.block_title{
		font-size: 43px;
		line-height: 1.2;
	}
	.block_shops, 
	.block_vip{
		padding-left: 10px;
		padding-right: 10px;
		box-sizing: border-box;
		.view_content{
			text-align: center;
		}
		.views_row{
			width: 30%;
			text-align: left;
			.views_body{
				.text{
					font-size: 12px;
					line-height: 1.3;
				}
				.price,
				.statistics,
				.area{
					font-size: 20px;
				}
				.area{
					width: 33%;
				}
			}
		}
	}
	.read_more{
		font-size: 15px;
	}
	.new_buildings .views_row, 
	.block_search_results .views_row, 
	.block_vip_shops .views_row{
		width: 45%;
		.sidebar_first #content & {
			width: 100%;
			margin-right: 0 !important;	
		}
	}
	.block_services .view_content{
		text-align: center;
		float: none;
		.views_row{
			float: none;
			&:nth-child(even){
				margin-right: 0;
			}
		}
	}
	.block_presentation {
		padding-left:15px;
		padding-right: 15px;
		.view_content{
			text-align: center;
			.views_row{
				width: 30%;
			}
		}	
	}
	.block_seo{
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		.body{
			font-size: 20px;
			line-height: 1.3;
		}
		.content_body{
			height: 252px;
		}
	}
	.block_articles{
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		.views_row{
			float: none;
			margin: 0 25px 30px !important;
		}
		.read_more{
			clear: both;
		}
	}
	.footer{
		height: 323px;
		margin-top: -323px;
		.superlending &,
		.superlending_inner &{
			height: 180px;
			margin-top: -180px;
		}
		&.active{
			height: auto;
		}
		.region_footer {
			.footer_right {
				float: none;
				clear: both;
				display: block;
				margin: 0 auto;
				width: 400px;
				overflow: hidden;
				.superlending & ,
				.superlending_inner &{
					clear: none;
					float: right;
					width: auto;
				}
			}
			.footer_center{
				width: auto;
				margin-right: 0;
				margin-top: 20px;
			}
		}
		.footer_bottom,
		.footer_top{
			padding-left:15px;
			padding-right:15px;
			box-sizing: border-box;
		}
		.social_links{
			right: 50%;
			margin-right: -135px;
		}
	}
	.superlending .middle,
	.superlending_inner .middle{
		padding-bottom: 180px;
	}
	.middle{
		padding-bottom: 323px;
	}
	.wrapper{
		overflow: hidden;
	}
	.slider_for .item{
		height: 475px;
	}
	.block_inform_appartament {
		.left_wrap{
			width: 61%;
		}
		.right_wrap{
			width: 38%;
		}
	} 
	.block_body .field_body{
		overflow: visible;
		text-align: justify;
	}
	.block_maps .map{
		width: 48%;
	}
	.block_vip_shops{
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
		.view_content {		
			text-align: center;	
			.views_row{
				width: 48%!important;
				&:nth-child(even){
					margin-right: 0 !important;
				}
			}
		}
	}
	.sidebar_first {
		.middle_inner{
			padding-left: 15px;
			padding-right: 15px;
			box-sizing: border-box;
		}
	}
	.block_node_content .field_body{
		text-align: justify;
	}
	.articles_inner,
	.favorites,
	.newbuilding,
	.process,
	.ranking,
	.video_transfer{
		#content{
			padding-left: 0;
		}
		.left_sidebar{
			float: none;
			margin: auto;
			clear: both;
		}
	}
	.block_should_use .views_row{
		margin: 0 15px 30px!important;
	}
	.block_we_have,
	.block_package{
		padding-left:15px;
		padding-right:15px;
		box-sizing: border-box;
	}
	.block_package {		
		text-align: center;
		.views_row{
			max-width: 48%;
			text-align: left;
			&:nth-child(even){
				margin-right: 0;
			}
		}
	}
	.block_work_client {
		.views_row{
			width: 49%;
			margin-bottom: 25px;
			padding-right: 10px;
			box-sizing: border-box;
		}
	}
	.block_contacts_specialists .views_row .right_wrap .about_block .field_item{
		width: 47%;
	}
	.block_contacts_specialists .views_row .right_wrap .body_wrap{
		text-align: justify;
	}
	.block_contacts_specialists .views_row .right_wrap iframe{
		height: 238px;
	}
	.block_vip_shops .block_content .view_content .views_row, 
	.block_daily_products.block_search_results .block_content .view_content .views_row{
		margin-right: 26px !important;
	}
	.block_vip_shops .view_content .views_row:nth-child(even), 
	.block_daily_products.block_search_results .view_content .views_row:nth-child(even){
		margin-right: 0!important;
	}
	.search_results.search_products .webform_client_form .form_select.area_select select{
		width: 177px;
	}
	.search_results.search_products .webform_client_form .form_actions .links{
		margin: 15px 20px 0 0;
		display: inline-block;
		float: left;
		vertical-align: middle;
	}
	.earth .search_results form .form_actions::after, .ranking_daily .search_results form .form_actions::after{
		top: 13px;
		left: 79px;
	}
	.block_earth .views_row .left_wrap{
		width: 47%;
	}
	.block_earth .views_row .right_wrap{
		width: 47%;
	}
	.block_earth .views_row .bottom_wrap .left_wrap{
		width: 48%;
	}
	.block_earth .views_row .bottom_wrap .center_wrap{
		width: 47%;
		float: right;
	}
	.block_earth .views_row .bottom_wrap .right_wrap{
		float: left;
		margin-top: 25px;
		clear: both;max-width: 100%;
		width: 100%;
		.info_wrap{
			float: left;
			width: 50%;
		}
		.contacts{
			float: right;
			width: 50%;
			padding-left: 20px;
			box-sizing: border-box;
		}
	}
	.block_earth .views_row .bottom_wrap .right_wrap .contacts .btn{
		float: none;
	}
	.slider_main .block_favorites .views_row{
		margin-right: 30px;
	}
	.sidebar_first .block_shops .views_row .views_body .statistics, 
	.sidebar_first .block_vip .views_row .views_body .statistics{
		padding-left: 40px;
		font-size: 18px;
		margin-right: 7px;
		line-height: 23px;
	}
	.block_search_results .views_row .views_body .read_more a, 
	.block_vip_shops .views_row .views_body .read_more a{
		padding: 0 10px;
	}
	.block_search_results .views_row .views_body .read_more.preview, .block_vip_shops .views_row .views_body .read_more.preview{
		font-size: 14px;
	}
	.sidebar_first.favorites #content .block_search_results .views_row,
	.sidebar_first.ranking #content .block_search_results .views_row{
		width: 47%;
		margin-right: 26px!important;
    }
	.sidebar_first.favorites #content .block_search_results .views_row:nth-child(even),
	.sidebar_first.ranking #content .block_search_results .views_row:nth-child(even){
		margin-right: 0!important;
	}
	.sidebar_first.favorites #content .block_search_results.block_newbuilding_row .views_row{
		width: 100%;
		margin-right: 0 !important;
	}
	.block_newbuilding_row .block_content .view_content .views_row .left_wrap{
		width: 64%;
	}
	.favorites .block_earth .views_row .bottom_wrap .right_wrap{
		clear: none;
		width: 47%;
		margin-top: 0;
		.info_wrap,
		.contacts{
			float: none;
			width: 100%;
			padding-left: 0;
		}
	}
	body.final .slider_main {
		.social-likes{
			margin: 0;
		}
		.button_wrap{
			position: static;
			transform: none;
			clear: both;
			text-align: center;
			.btn{
				display: inline-block;
				vertical-align: middle;
				margin: 25px 15px 0;
			}
		}
	} 
	.block_final_wrap{
		padding: 0 15px;
		box-sizing: border-box;
		.views_row .webform_client_form .left_wrap{
			width: 50%;
		}
	}
	.block_webform{
		margin-left: 0;
		left: 0;
	}
	.block_webform.webform_client .webform_client_form .address, 
	.block_webform.webform_object .webform_client_form .address{
		width: 175px;
	}
	.block_webform#block-webform-confirm{
		left: 50%;
	}
	.block_benefits.steps .views_row::after{
		width: 126px;
		right: -63px;
	}
	.block_hydrometers {
		.view_content{
			text-align: center;
		}
	}
	.block_questions .views_row .qty_text .item{
		width: 100%;
	}
	.block_our_advantages .views_row{
		width: 30%;
	}
	.multilending .search_results form.webform_client_form .form_item.metro{
		width: 100%;
		margin-right: 0;
		margin-bottom: 25px;
	}
	.multilending .block_search_results .view_content .views_row, 
	.superlending .block_search_results .view_content .views_row{
		width: 46%;
		margin-right: 26px !important;
	}
	.multilending .block_search_results .view_content .views_row:nth-child(even), 
	.superlending .block_search_results .view_content .views_row:nth-child(even){
		margin-right: 0 !important;
	}
	.multilending .block_vip_shops .view_content {
		margin-bottom: 30px;
	}
	.newbuilding_inner .block_inform_appartament {		
		.left_wrap{
			width: 50%;
		}
		.right_wrap{
			width: 43%;
			clear: both;
			float: none;
			display: block;
			margin: 25px auto 0;
		}
	}
	.block_accommodations .block_content div.item,
	.block_accommodations .block_content .accommodations div.item{
		width: 100%;
	}
	.plan .slider_main .slider_list .item{
		margin-bottom: 20px;
	}
	.block_benefits .views_row{
		width: 24%;
	}
	.process .slider_main .form_item{
		width: 220px;
	}
	.block_process .views_row .right_wrap .webform_client_form .form_textarea{
		width: 50%;
	}
	.property_valuation .block_benefits .views_row{
		width: 32%;
		margin-bottom: 20px;
	}
	.property_valuation .block_presentation form.webform_client_form .form_item:not(.textarea), 
	.real_services .block_presentation form.webform_client_form .form_item:not(.textarea){
		width: 238px;
	}
	.ranking_daily .slider_main .form_item{
		margin-bottom: 20px;
	}
	.ranking_daily .slider_main .webform_client_form .form_actions{
		vertical-align: top;
	}
	.ranking_daily .search_results.search_products .webform_client_form .form_select.area_select select{
		width: 275px;
	}
	.ranking_daily .search_results.search_products .webform_client_form .form_item:nth-child(3){
		margin-right: 0;
	}
	.search_results .filters{
		max-width: 73%;
		.item{
			margin-bottom: 15px;
		}
	}
	.search_results.search_products > div{
		overflow: hidden;
	}
	.block_vip_shops .view_content .views_row, 
	.block_daily_products.block_search_results .view_content .views_row{
		width: 47%;
	}
	.trust .slider_main form .form_item{
		width: 200px;
	}
	.block_price_wrapper,
	.block_our_works{
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;		
	}
	.block_our_works{
		.views_row{
			margin-right: 25px !important;
			width: 47%;
			&:nth-child(even){
				margin-right: 0 !important;
			}
		}		
	}
	.repair_premises .block_benefits .views_row, 
	.installation_water .block_benefits .views_row{
		width: 45%;
		margin-bottom: 25px;
	}
	.block_ready .view_content > div{
		margin-bottom: 20px;
	}
	.block_services_wrap .views_row{
		margin-bottom: 30px;
		.field_image{
			overflow: hidden;
			img{
				display: block;
				height: 100%;
				max-width: none;
			}
		}
	}
	body.superlending .slider_main{
		padding-bottom: 83px;
	}
	.superlending .search_results form.webform_client_form .form_item{
		margin-bottom: 20px;
	}
	.superlending .block_search_results .view_content .views_row{
		margin-right: 26px!important;
		&:nth-child(even){
			margin-right: 0 !important;
		}
	}
	.block_diplomas{
		padding: 0 15px;
		box-sizing: border-box;
		.views_row{
			width: 48%;
			margin-right: 26px !important;
			&:nth-child(even){
				margin-right: 0 !important;
			}
		}
	}
	.block_test_result .views_row {
		.left_wrap{
			margin-bottom: 15px;
		}	
		.right_wrap{
			overflow: visible;
		}
	}
	.tests .slider_main .slider_list {		
		.item{		
			margin-right: 30px;	
			margin-bottom: 20px;
			&:nth-child(2){
				min-width: auto;
			}
		}
	}
	.tests .slider_main .slider_list .item:last-child{
		margin: 0;
	}
	.trust .block_benefits .views_row{
		width: 30%;
		margin-bottom: 30px;
	}
	.trust .block_video{
		margin-bottom: 30px;
	}
	.block_reviews{
		padding-left: 15px;
		padding-right: 15px;
		box-sizing: border-box;
	}
	.block_reviews .slider_video .views_row{
		height: 260px;
		iframe{
			height: 100%!important;
			width: 100%!important;
		}
	}
	.block_reviews .reviews_text .view_row{
		width: 47%;
	}
	.slider_main .come_back{
		left: 10px;
	}
	.block_search_results .views_row.no_image .read_more, .block_vip_shops .views_row.no_image .read_more{
		float: none;
	}
}
@media (max-width:768px){
	html{
		min-width: 320px;
	}
	.header {		
		padding-top: 0;
		.header_content{
			padding: 0;
			height: auto;
			.logo_wrapper{
				float: none;
				margin: 0 auto;
				text-align: center;
				padding:17px 0 8px;
				margin-bottom: 0;
				box-shadow: none;	
				background: #fff url(images/shadow.png) repeat-x bottom;
				box-sizing: border-box;
			}
		}
		.region_header{
			float: none;
			overflow: visible;
			padding: 20px 0 0 0;
			box-sizing: border-box;
			.benefits{
				float: none;
				margin: 0;
				width: 100%;
				padding: 0 20px 6px;
				box-shadow: none;	
				background: #fff url(images/shadow.png) repeat-x bottom;
				margin-bottom: 20px;
				box-sizing: border-box;
			}
			.contacts_header{
				float: none;
				padding: 0 20px 21px;
				box-shadow: none;
				background: #fff url(images/shadow.png) repeat-x bottom;
				margin-bottom: 20px;
				position: relative;
				box-sizing: border-box;
				.slicknav_menu{
					position: absolute;
					top: 11px;
					right: 20px;
					padding: 0;
					background: none;
					width: 100%;
					.slicknav_btn{
						border-radius: 100%;
						background: #17bb49;
						height: 54px;
						width: 54px;
						display: block;
						box-sizing: border-box;						
						.slicknav_icon{
							width: 100%;
							height: 100%;
							padding-top: 8px;
							&::before{
								width: 100%;
								height: 100%;
							}
						}
						.slicknav_icon-bar{
							height: 2px;
							width: 24px;
							margin: auto;
							box-shadow: none;
							& + .slicknav_icon-bar{
								margin-top: 9px;
							}
						}
					}
					.slicknav_nav{
						background: #fff;
						padding: 15px;
						a{
							color:#000;
							font-size: 16px;
							margin: 5px 0;
							text-transform: uppercase;
						}
					}
				}
			}
			.buttons_header{
				margin: 0 auto;
				float: none;
				padding-bottom: 11px;
				& > div{
					& + div{
						margin-top: 9px;
					}
				}
			}
		}
	}
	.block_main_menu{
		display: none;
	}
	.slider_main {
		padding: 28px 20px!important;
		.services & {
			padding: 20px!important;
			.top_inner_content {
				.main_breadcrumbs{
					margin-bottom: 18px;
				}
			}
			.slider_title{
				margin-bottom: 0 !important;
			}
		}
		.apartment & {
			padding-bottom: 181px !important;
		}
		.slider_title{
			font-size: 26px;
			line-height: 1.1;
			font-weight: 400;
			padding-bottom: 16px!important;
			margin-bottom: 20px !important;
			text-transform: uppercase!important;
			&::after{
				width: 40px!important;
				height: 2px;
				margin-left: -20px!important;
			}
			.ranking &,
			.contacts & {
				padding-bottom: 0 !important;
			} 	
			.process &,
			.tests & {
				text-transform: uppercase;
				padding-bottom: 0;
				&::after{
					content: none;
				}
			}
			.repair_premises & {
				padding-bottom: 18px;
				margin-bottom: 20px;
			}
		}
		.block_favorites{
			text-align: left;
			.views_row{
				margin-right: 13px;
				margin-bottom: 20px;
			}
		}
		.tests & {
			.slider_list {
				margin-bottom: 0;
				text-align: left;
				.item{
					margin-bottom: 30px;
					padding: 0 10px;
					box-sizing: border-box;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
		}
		.slider_subtitle{
			text-transform: none!important;
		}
		.title_text{
			font: 500 14px Roboto;
			margin: 0 auto 25px;
		}
		.cleaning &{
			background-image: url(images/bg_mob.jpg)!important;
			.slider_title{
				padding-bottom: 19px;
				margin-bottom: 19px;
				&::after{
					width: 40px;
					margin-left: -20px;
				}
			}
			
			.title_text{
				max-width: 171px;
			}
			.webform_client_form {
				width: 240px;
				margin: auto;
				.form_item{
					width: 100%;
					margin-bottom: 20px;
				}
				.form_actions{
					width: 100%;
					& input{
						width: 100%;
					}
				}
			}
		}
		.webform_client_form{
				width: 240px;
				margin: auto;
				.form_item{
					width: 100%;
					margin-bottom: 20px!important;
				}
				.form_actions{
					width: 100%;
					& input{
						width: 100%;
					}
				}
			}
		.slider_subtitle{
			font-size: 20px!important;
			margin-bottom: 20px!important;
			.trust & {
				text-transform: none !important;
				margin-bottom: 14px !important;				
			}
		}
		.text{
			width: 250px!important;
			margin: auto!important;
			margin-bottom: 24px!important;
			font: 500 14px Roboto!important;
			.earth & {
				width: auto !important;
			}
		}
		.repair_premises & {
			.webform_client_form .form_actions input {
				font-size: 15px;
			}
		}
		.front &{
			.webform_client_form .form_item.phone{
				width: 100%;
				margin: 0 0 20px;
			}
			.webform_client_form .form_actions input{
				font-size: 15px;
			}
		}
	}
	.sidebar_first #content{
		padding: 0;
	}
	.block_title {
		font-size: 26px;
		font-weight: 400;
	}
	.block_process{
		padding: 0!important;
		&.during {
			.block_content {
				.block_title {
					margin-bottom: 25px;
				}
			}
		}
		.block_content{
			text-align: center;
			.block_title{
				text-align: center;
				font-size: 26px;
				display: block;				
			}
			.create{
				float: none;
				display: inline-block;
				vertical-align: middle;
			}
			.views_row {
				padding: 20px 0;
				text-align: left;
				box-sizing: border-box;
				.left_wrap{
					float: none;
					width: 100%;
					margin: 0;
					padding: 0;
					text-align: center;
				}				
				.right_wrap{
					padding: 0;
					border: none;
					.webform_client_form {	
						padding-bottom: 20px;
						padding-left: 20px;
						padding-right: 20px;
						box-sizing: border-box;
						margin-bottom: 20px;
						box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
						.form_textarea {						
							width: 100%;
							float: none;
							margin-bottom: 0;
							textarea{
								width: 100%;
								height: 104px;
								box-sizing: border-box;
							}
						}
					}
					.group{
						margin-bottom: 20px;
					}
				}
				.text{
					margin-bottom: 20px;
					padding: 0 20px;
					box-sizing: border-box;
				}
				.title{
					padding: 0 20px;
					box-sizing: border-box;
				}
			}
		}
	}
	body.process .block_banner,
	body.process .block_vip,
	body.process .block_services,
	body.process .region_banner{
		display: none;
	}
	.process {		
		.block_presentation{
			padding: 30px 0;
			.block_content{
				max-width: 280px;
				margin: auto;
				.block_title{
					margin-bottom: 18px;
				}
			}
		}
		.banner{
			background: #fff;
		}
	}
	.sidebar_first .container,
	.left_sidebar{
		margin: 0;
		float: none;
		width: 100%;
	}
	.middle_inner{
		.process & {
			padding: 20px 10px 0;
		}
		.tests &,
		.apartment & {
			padding: 20px 10px 0;
		}
		.cleaning & {
			padding-top: 0 !important; 
		}
		.earth &,
		.ranking & {
			padding:0 0;
		}
	}
	.block_shops .views_row, 
	.block_vip .views_row{
		width: 100%;
		margin: 0 0 20px;
	}
	.block {		
		body:not(.trust) & {
			.slick-list{
				// width: 290px;
				margin: auto;
				box-sizing: border-box;
				.views_row{
					margin: 0 0px 20px!important;
				}
			}
		}	
	}
	.middle_inner,
	.block{
		padding: 20px;
		margin-bottom: 0;
		box-sizing: border-box;
	}
	.slick-dots {
		text-align: center;
		li{
			display: inline-block;
			vertical-align: middle;
			margin: 0 12px;
			button{
				text-indent: -99999px;
				border-radius: 100%;
				overflow: hidden;
				width: 15px;
				height: 15px;
				box-shadow: inset 0 1px 3px #89919e;
				background-color: rgba(137, 145, 158, 0.1);
				outline: none;
				border: none;
			}
			&.slick-active{
				button{
					border: 1px solid #18bb49;
					background-color: #ffffff;
					box-shadow: none;
				}
			}
		}
	}
	.new_buildings .views_row, 
	.block_search_results .views_row{
		width: 100%;
		margin: 0 0 20px!important;
	}
	.block_services{
		padding-bottom: 30px;
		.block_title{
			max-width: 250px;
			margin: 0 auto 20px;
		}
		.views_row{
			margin: 0 auto 20px!important;
			float: none;
			display: block;
			.field_image{
				display: none;
			}
			&:last-child{
				margin-bottom: 0!important;
			}
		}
	}
	.block_presentation {		
		padding: 20px 25px;
		.trust & {
			padding-bottom: 55px;
		}
		.front &{
			padding: 20px 1px;
		}
		.view_content{
			text-align: center;
			form.webform_client_form .form_item,
			form.webform_client_form .form_textarea{
				width: 240px!important;
				margin:  0 auto 20px!important;
				display: block;
			}
			.form_actions input{
				width: 240px;
			}
		}
		.block_title{
			line-height: 1.3;
			margin-bottom: 30px;
			&::after{
				width: 40px!important;
				margin-left: -20px!important;
			}
		}
		.present_wrap{
			margin-bottom: 30px;
		}
		.see_present{
			font-size: 20px;
			background-position: center  left 11px;
			background-size: 52px;
			width: 270px;
		}
		.views_row{
			margin: 0 10px 30px;
		}
		.sub_title{
			font-size: 20px!important;
			text-transform:none;
			margin-bottom: 16px!important;
		}
		.title_text{
			font:500 14px Roboto;
			margin-bottom: 25px;
		}
	}
	.block_seo {
		padding-left: 17px;
		padding-right: 17px;
		.view_content{
			text-align: center;
		}
		.body{
			text-align: center;
			font-size: 20px;
			line-height: 1.2;
			ul{
				text-align: left;
			}
			.image{
				display: none;
			}			
		}		
	}
	.block_articles{
		.slick-list {
			width: 270px;
		}
		.view_content_inner{
			margin-bottom: 20px;
		}
		.views_row{
			margin: 0px 0 20px !important;
		}
	}
	.footer{
		height: 540px;
		margin-top: -540px;
		padding: 45px 20px 35px;
		text-align: center;
		box-sizing: border-box;
		.social_links{
			right: auto;
			left: 50%;
			margin: 0 0 0 -135px;
			width: 270px;
			top: -70px;
		}
		.region_footer .logo_wrapper{
			float: none;
			margin: 0 auto;
			width: 100%;
			text-align: center;
			.copyright{
				max-width: 210px;
				margin: auto;
			}
		}
		.region_footer{		
			.visit{
				margin: 25px auto 21px;
				width: 250px;
				.item{
					margin: 0 12px!important;
				}
			}
			.footer_center{
				float: none;
				width: 100%;
				margin: 0;
				.menu_footer{
					ul{
						display: none;
					}
				}
			}
			.footer_right{
				padding-top: 20px;
				float: none;clear: both;
				overflow: hidden;
				.buttons_header{
					float: none;
					margin: 0 auto 25px;
				}
				.contacts_header{
					float: none;
				}
			}
		}
		.footer_top{
			padding: 0;
			float: none;
			border: none;
		}
	}
	.to_up{
		position: fixed;
		bottom: 15px;
		right: 15px;
		top: auto;
		left: auto;
	}
	.middle{
		padding-bottom: 540px;
	}
	.block_tests_wrapper {	
		padding: 0;	
		.views_row {	
			padding: 0 0 30px;		
			.view_row_inner{
				text-align: center;
				.read_more {
					@include read_more(#03a9f4);
				}
			}
			.right_wrap,
			.left_wrap{
				float: none;
				width: 100%;
			}
			.left_wrap{
				padding: 20px 25px;
				text-align: center;
				box-sizing: border-box;
				.title{
					margin-bottom: 16px;
					font-weight: 400;
					font-size: 24px;
					line-height: 1.3;
				}
				.subtitle{
					margin-bottom: 20px;
				}
				ul{
					text-align: left;
				}
			}
		}
	}
	.load_more_wrap{
		.tests & {
			margin-bottom: 30px;
		}
	}
	.block_should_use {
		.subtitle{
			color: #3a3f47;
			font-size: 20px;
			font-weight: 400;
			text-transform: lowercase;
			line-height: 1.3;
			padding-bottom: 18px;
			margin-bottom: 20px;
		}
		.block_title{
			margin-bottom: 25px;
		}
		.title_text{
			font: 500 14px Roboto;
			margin-bottom: 20px;
		}
		.view_content{
			width: 270px;
			margin: auto;
		}
		.views_row{
			margin-right: 0 !important;
			.field_image .icon img{
				display: inline;
			}
		}
	}
	.block_we_have {		
		padding-top: 28px;
		.block_title{
			font-size: 26px;
			font-weight: 400;
			padding-bottom: 18px;
			margin-bottom: 20px;
		} 
		.group{
			width: 100%;
			margin-bottom: 0;
			li{
				margin-bottom: 24px;				
			}
		}
		.bottom_wrap{
			.title{
				font-size: 26px;
				margin-bottom: 20px;
			}
		}
		.webform_client_form {
			width: 240px;
			margin: auto;
			.form_item{
				width: 100%;
				margin-bottom: 20px;
			}
			.form_actions{
				width: 100%;
				& input{
					width: 100%;
					font-size: 15px;
				}
			}
		}
	}
	.block_video{
		margin-bottom: 4px!important;
		padding-left: 10px;
		padding-right: 10px;
		iframe{
			height: 181px !important;
			width: 100% !important;
			margin: auto;
		}
		.insurance & {
			margin-bottom: 20px!important;
		}
		.real_services &,
		.repair_premises & {
			margin-bottom: 30px !important;
		}
		.trust & {
			padding-top: 30px;
			margin-bottom: 0;
		}
		.apartment & {
			padding-top: 0;
			padding-bottom: 0;
			margin-bottom: 0;
			border-bottom: none;
		}
	}
	.block_package{
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}
	.block_photo{
		padding-top: 0;
		padding-left: 10px;
		padding-right: 10px;
		.views_row{
			margin:  0 0 20px!important;
		}
	}
	.block_benefits {	
		background: none!important;
		.repair_premises &,
		.trust &{
			padding-bottom: 0;
		}
		.block_title{
			margin-bottom: 27px;
		}	
		.views_row{
			width: 100%!important;
			margin-bottom: 21px!important;
			.field_img img{
				display: inline;
			}
		}
	}
	.block_benefits.steps {		
		.block_title{
			margin-bottom: 27px;
		}
		.views_row{
			margin-bottom: 93px!important;
			&::after{
				right: 50%!important;
				top: 117%!important;
				margin-right: -18px!important;
				width: 50px;
				background: url(images/arrow.png) no-repeat center left -143px;
				transform: rotate(90deg);
			}
			&:last-child {
				&::after{
					content: '';
				}
			}
		}
		.group{
			background: none!important;
			padding-bottom: 0!important;
			margin-bottom: 0!important;
			&::after{
				content: none;
			}
			&:last-child{
				.views_row:last-child{
					margin-bottom: 0!important;
					&::after{
						content: none;
					}
				}
			}
		}
	}
	.block_benefits.steps.second .group:last-child .views_row::after,
	.real_services .block_benefits.steps .group:last-child .views_row::after, .property_valuation .block_benefits.steps .group:last-child .views_row::after{
		right: 50%!important;
		top: 117%!important;
		margin-right: -18px!important;
		width: 50px;
		background: url(images/arrow.png) no-repeat center left -143px;
		transform: rotate(90deg);
	}
	.real_services .block_benefits.steps .group:last-child .views_row:first-child::before, 
	.property_valuation .block_benefits.steps .group:last-child .views_row:first-child::before{
		content: none;
	}
	.block_presentation.order_block{
		padding: 30px 0!important;
		margin-bottom: 0!important;
	}
	.real_services .block_presentation form.webform_client_form .form_item.price{
		margin-right: auto!important;
	}
	.block_questions {
		.views_row {			
			.qty_text {
				padding-right: 0;
				line-height: 1.3;
				.item{
					width: 100%;
				}
				span{
					display: none;
				}
			}
		}
	} 
	.block_seo{
		border-bottom: none !important;		
		.block_title{
			margin-bottom: 20px!important;
		}		
		.real_services &{
			background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center/cover;
		}
		.repair_premises & {
			margin-bottom: 10px!important;
			.content_body {
				height: 353px!important;
			}
		}
		.services & {
			padding-top: 10px;
			.block_title{
				margin-bottom: 23px;
			}
		}
		.earth &{
			padding-top: 30px;
		}
	}
	.block_price_wrapper{
		padding: 20px 0 0;
		margin-bottom: 0;
		box-shadow: none;
		box-sizing: border-box;
		.thead{
			display: none;
		}
		.tbody {
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
			.views_row{
				padding: 17px 25px;
				border-bottom: 2px solid #e5e5e5;
				.left{
					width: auto;
					margin-right: 7px;
				}
				.right{
					width: auto;
					text-align: left;
					float: left;
					position: relative;
					&::before{
						content: '....';
						display: inline-block;
						vertical-align: bottom;
						margin-right: 7px;
					}
					&::after {
						content: 'грн';
						display: inline-block;
						vertical-align: bottom;
						margin-left: 7px;
					}
				}
			}
		}
		.calculate_form{
			padding-top: 30px;
			padding-bottom: 30px;
			background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center/cover;
			.title{
				font-size: 26px;
				font-weight: 400;
				padding: 0 20px;
				box-sizing: border-box;
			}
		}
	}
	.repair_premises {
		.block_our_works{
			box-shadow: none;		
			padding: 0 10px 7px;
			margin-bottom: 0;
			box-sizing: border-box;	
			text-align: center;
			.views_row{
				margin: 0 auto 18px;
				border: 1px solid #979797;
			}
			.footnote{
				margin: 20px auto 0;
				width: 260px;
				box-sizing: border-box;
			}
		}
	}
	.block_ready .view_content > div{
		margin-bottom: 20px;
	}
	.region_banner{
		padding-bottom: 55px;
	}
	.services,
	.trust {		
		.middle_inner{
			padding: 0!important;
		}
	}
	.block_services_wrap{		
		padding-bottom: 0;
		.views_row {		
			height: auto;	
			margin-bottom: 20px;
			.left_wrap,
			.right_wrap{
				float: none;
				width: 100%;
			}
			&.odd {				
				.left_wrap{
					padding: 17px 20px 20px!important;
				}				
			}
			&:not(.odd) {
				.right_wrap{
					padding: 17px 20px 20px!important;
				}	
			}
			.title{
				padding-bottom: 16px;
			}
		}
	}
	.block_vip_shops {
		.view_content{
			.views_row{
				width: 100%;
				margin: 0 0 20px!important;
				.info.price{
					display: block;
					vertical-align: top;
					margin: 0 0 17px 0;
					width: auto;
					float: none;
					padding: 0;
					font: 400 24px 'PFDinTextCompPro', sans-serif;
					.label{
						font: 700 14px Roboto, sans-serif;
						color: #18bb49;
						display: block;
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	.block_numbers{
		margin-bottom: 0;
		.views_row{
			width: 100%;
			margin:  0 0 30px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.block_diplomas{
		padding-top: 20px;
		margin-bottom: 19px!important;
		.views_row{
			width: 100%;
			max-width: 100%;
			margin: 0 0 20px;
			img{
				margin: auto;
				display: block;
			}
		}
	}
	.block_reviews {
		padding: 0 8px;
		box-sizing: border-box;
		.slider_video{
			margin-bottom: 25px;
			.views_row{
				margin: 0 1px 20px;
				height: 181px;
				iframe{
					height: 100% !important;
				}
			}
		}
	} 
	.block_questions{
		padding: 30px 20px;
	}
	.search_results{
		padding-bottom: 30px;
		.form_title{
			font-size: 26px!important;
			font-weight: 400!important;
			margin: 0 0 23px!important;
			padding-bottom: 0!important;
			text-align: center;
		}
		.webform_client_form {		
			border-bottom: none;	
			.form_number input{
				width: 90px;
			}
			.form_select {
				width: 100%;
				select{
					width: 100% !important; 
				}
			}
			.form_actions { 				
				.links{
					margin: 27px 0 0 26px!important;
					float: right;
				}
				&::after{
					top: 14px!important;
				}
			}
		}
		.left_wrap {
			margin-bottom: 25px;
			float: none;
		}
		.right_wrap{
			float: none;
			margin-bottom: 0px;
		}
		.filters .item{
			margin-bottom: 18px;
		}
	}
	.block_earth{
		padding: 0 10px;
		.views_row{
			padding: 5px 20px 20px;
			.title{
				font-size: 24px;
				font-weight: 400;
			}
			.top_wrap{
				margin-bottom: 23px;
				.left_wrap{
					float: none;width: 100%;
					margin-bottom: 20px;
					height: 267px;
					overflow: hidden;
					iframe{
						height: 100% !important;
					}
					img{
						height: 100%;
						max-width: none;
					}
				}
				.right_wrap{
					float: none;width: 100%;
					height: 267px;
					.slick-list{
						width: 100%!important;
						img{
							height: 100%;
							max-width: none;
						}
					}
				}
			}
			.bottom_wrap{
				.left_wrap{
					float: none;
				}
				.center_wrap{
					width: auto;
					float: none;
					margin-bottom: 30px;
				}
				.right_wrap{
					width: 100%;
					max-width: 100%;
					float: none;
					.contacts .btn{
						float: none;
					}
				}
			}
		}
	}
	.icons_wrap{
		overflow: hidden;
		padding-bottom: 10px;
		&::before{
			right: -159px;
		}
		&::after{
			left: -159px;
		}
	}
	.slider_for {
		.item{
			height: auto;
		}		
		.slick-arrow{
			display: none!important;
		}
	}
	.slider_nav {
		padding: 0 19px;
		.item{
			margin: 0 7px;
		}
		.slick-arrow{
			width: 15px;
			height: 29px;
			opacity: .5;
			margin-top: -18px;
			background-size: contain;
			&.slick-prev{
				left: 0;
			}
			&.slick-next{
				right: 0;
			}
		}
	} 
	.block_appartament_slider{
		padding: 10px 10px 20px;
		margin-bottom: 20px;
	}
	.block_inform_appartament {		
		background: none;
		padding: 0;
		box-shadow: none;
		.left_wrap,
		.right_wrap{
			float: none;
			width: 100%;
			margin-bottom: 20px;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
			background: #fff;
			padding: 18px 20px 20px;
			box-sizing: border-box;
		}
		.left_wrap{			
			.price_wrapper {
				div{
					margin-bottom: 15px;
					width: 100%;
				}
				span{
					transform:none;
					width: 49px;
					height: 1px;
					margin: 0 0 20px;
				}
			}
			.char_wrapper {
				.group{
					float: none;	
					width: 100%;
					& + .group {
						margin-top: 40px;						
					}
				}
			}
		}
		.ya-share2__container_size_m .ya-share2__icon{
			width: 21px;
			height: 21px;
		}
	}
	.block_body {
		margin-bottom: 20px;
		.title{
			text-transform: none;
			font-size: 24px;
			font-weight: 400;
			float: none;
			width: auto;
			margin: 0 0 10px;
		}
	} 
	.block_presentation.order_block {		
		.block_title{
			margin-bottom: 28px;
		}
	}
	.apartment .block_presentation:not(.block_picking) .webform_client_form .form_item, .daily_inner .block_presentation:not(.block_picking) .webform_client_form .form_item{
		margin-right: auto !important;
	}
	.block_maps{
		margin: 20px 10px 0;
		background: none;
		box-shadow: none;
		padding: 0;
		.map{
			margin-right: 0 !important;
			width: 100%;
			padding: 20px;
			background: #fff;
			margin-bottom: 20px;
			box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
			.title{
				margin-bottom: 10px;
			}
		}
	}
	.new_buildings .views_row {		
		.left_wrap, 
		.right_wrap{
			float: none;
			width: 100%;
			.info{
				clear: both;
			}
		}
		.address{
			float: left;
			margin-bottom: 20px;
		}
		.statistics{
			float: right;
			margin-bottom: 20px;
		}
		.read_more{
			float: right;
			margin-top: 10px;
		}
		.right_wrap{
			.price{
				margin: 23px 20px 0 0;
				overflow: hidden;
			}
		}
	}
	.apartment .block_vip_shops{
		padding-top: 5px;
	}
	.property_search {
		padding-bottom: 30px;
		border-bottom: 2px solid #e5e5e5;
		.form_title{
			text-align: center;
			font-size: 26px;
			font-weight: 400;
		}
		.webform_client_form .price_currency{
			width: 30%;
		}
	}
	.search_results {
		padding-top: 30px;
	}
	.block_search_results .views_row .views_body .price{
		margin-top: 0;
		margin-bottom: 20px;
		.label{
			display: block;
			margin-bottom: 10px;
		}
	}
	.block_search_results .views_row .views_body .statistics, 
	.block_vip_shops .views_row .views_body .statistics{
		margin-top: 25px;
	}
	.ranking{		
		.block_vip{
			padding-top: 10px;
			.block_title{
				text-align: center;
				font-size: 24px;
				font-weight: 400;
			}
			.views_row .views_body{				
				.address{
					margin-bottom: 24px;
				}
			}
		}
		.block_services{
			background:#fff;
			border-bottom: 2px solid #e5e5e5;
			.block_title{
				text-align: center;
				font-size: 24px;
				font-weight: 400;
			}
		}
		.block_seo{
			padding-top: 30px;
		}
		.block_presentation{
			padding-bottom: 55px;
		}
	}
	.block_presentation {
		.block_title{
			padding-left: 10px;
			padding-right: 10px;
			box-sizing: border-box;
		}
	}
	.articles{
		.block_search_results{
			margin-bottom: 20px;
			padding: 0;
			.slick-dots{
				margin-bottom: 20px;
			}
		}
		.block_seo{
			padding-top: 30px;
		}
	}
	.left_sidebar {		
		.block_left_menu{
			padding: 0;
			margin-bottom: 20px;
		}
		.banner{
			margin-bottom: 20px;	
		}
		.block_services{
			background:#fff;
			border-bottom: 2px solid #e5e5e5;
			.block_title{
				text-align: center;
				font-size: 24px;
				font-weight: 400;
			}
		}
	}
	.articles_inner .top_inner_content .main_breadcrumbs{
		margin-bottom: 25px;
	}
	.articles_inner .slider_title{
		margin-bottom: 5px!important;
	}
	.social-likes_vertical .social-likes__widget{
		min-width: 45px;
		height: 45px;
	}
	.social-likes{
		margin-bottom: -68px;
	}
	.block_node_content .field_body iframe{
		height: 181px !important;
	}
	.articles_inner #content{
		margin-bottom: 20px;
	}
	.block_articles{
		padding: 0 20px;
		margin-bottom: 20px;
		.block_title{
			margin-bottom: 20px;
		}
		.view_content{
			float: none;
		}
		.front & {
			padding-bottom: 55px;
		}
	}
	.webform_client_form .form_textarea{
		margin-bottom: 20px !important;
	}
	.contacts .slider_main .top_inner_content .title{
		margin-bottom: 20px;
	}
	.block_work_client {
		margin-bottom: 22px;
		.title{
			margin-bottom: 20px;
			text-align: center;
			font-size: 24px;
			font-weight: 400;
		}
		.views_row{
			width: 100%;
			margin-bottom: 20px;
		}
	} 
	.block_contacts_specialists {
		.views_row_inner {
			.left_wrap{
				float: none;
				margin-right: 0;
				margin-bottom: 20px;
			}
			.right_wrap {
				.name{
					text-align: center;
					font-size: 24px;
					font-weight: 400;
					margin-bottom: 19px;
				}
				.about_block .field_item{
					width: 100%;
				}
				.video_field{
					iframe{
						height: 181px !important;
					}
				}
			} 
		}		
	}
	.contacts {
		.block_vip_shops,
		.middle_inner {
			padding: 20px 10px;
		}
	}
	.block_accommodations{
		.left_wrap,
		.rigth_wrap{
			width: 100%;
			float: none;
		}
		.rigth_wrap{
			.group{
				width: 100%;
				margin-bottom: 0;
			}
		}
	}
	.daily_inner{
		.block_video{
			margin-bottom: 20px!important;
		}
		.block_daily_products{
			padding: 0 10px 20px;
			.block_title{
				text-align: center;
				margin-bottom: 20px;
				font-size: 24px;
				font-weight: 400;
			}
		}
	}
	.block_daily_products.block_search_results .view_content .views_row{
		margin: 0 0 20px!important;
	}
	.favorites{		
		.middle_inner{
			padding: 20px 10px;
			box-sizing: border-box;
		}
		.block_shops{
			padding: 0;
			margin-bottom: 20px;
			.block_title{
				font-size: 24px;
				text-align: center;
				font-weight: 400;
			}
			.views_row .views_body .price{
				float: none;
				width: 100%;
				margin-bottom: 20px;
			}
		}
	}
	.favorites{		
		.block_newbuilding_row .block_content .view_content .views_row {	
			.left_wrap{
				width: 100%;
				margin-right: 0;
				.views_body .text{
					margin-bottom: 20px;
				}
			}	
			.right_wrap{
				overflow: visible;
				clear: both;
				float: none;
				width: 100%;
				text-align: center;
				.field_img img{
					margin: 0 auto 7px;
				}
			}
			.bottom_wrap {			
				.left_wrap{
					width: auto;
					margin-top: 15px;
					margin-bottom: 20px;
				}
				.right_wrap {
					clear: none;
					width: auto;
					.statistics{
						float: none;
						width: 100%;
						margin-top: 25px;
					}
					.read_more{
						font-size: 16px;
						a{
							padding: 0 10px;
						}
					}
				}
			}
		}
		.block_earth{
			padding: 0;
			.block_title{
				font-size: 24px!important;
				text-align: center!important;
				font-weight: 400!important;
			}
		}
	}
	.final{		
		.slider_main .slider_list {
			margin-bottom: 0;
			.item{
				width: 100%;
				margin-bottom: 20px;
			}
		}
		.social-likes_vertical .social-likes__widget{
			margin: 0 5px;
		}
		.middle_inner{
			padding: 0;
		}
		.block_final_wrap{
			padding: 10px;
			.group_title{
				font-size: 24px!important;
				text-align: center!important;
				font-weight: 400!important;
			}
			.views_row .webform_client_form .left_wrap{
				width: 100%;
				float: none;
				margin-bottom: 20px;
				.text{
					margin-bottom: 20px;
				}
			}
		}
	}
	#block-webform {
		left: 0;
		margin-left: 0;
		margin-top: 0 !important;
		top: 0;
		overflow-y: scroll;
		height: 100%;
		.webform_client_form {			
			.top_wrap {	
				margin-bottom: 0;		
				.form_item{
					width: 100%;
					margin: 0 0 20px;
				}
			}
			.bottom_wrap {
				.form_item.textarea{
					width: 100%;
					float: none;
					margin-right: 0 !important;
					margin-bottom: 20px;
				}
				.form_item.form_select{
					margin-bottom: 20px;
					width: 100%;
				}
			} 
		}
		.block_content{

		& > .close{
			width: 30px;
			height: 30px;
			top: 10px;
			right: 15px;
		}
		}
		.filters {
			margin-bottom: 0;
			.item{
				margin: 0 20px 20px 0;
			}
		}
	}
	.block_webform.webform_object .webform_client_form .form_item{
		width: 100%;
		margin-right: 0;
		margin-bottom: 20px;
	}
	.block_webform.webform_object .webform_client_form .form_actions{
		width: 100%;
		input{
			width: 100%;			
		}
	}
	.block_webform#block-webform-confirm{
		width: 320px;
		margin-left: -160px;
		.close{
			top: 11px;
			right: 10px;
		}
		.webform_confirmation{
			text-align: center;
			padding: 0 30px 0 0;
			box-sizing: border-box;			
		}
	}
	.block_compensate{
		padding: 0;
		.views_row{
			margin-right: 0;
		}
	}
	.block_our_advantages{
		padding: 0;
	}
	.insurance{		
		.middle_inner{
			padding: 20px 10px;
		}
	}
	.block_our_advantages .views_row{
		width: 100%;
	}
	.multilending,
	.superlending{
		.header .benefits .item{
			padding-right: 0;
		}
		.search_results {
			padding: 20px 10px;
		}
		.middle_inner{
			padding: 20px 0;
		}
		.block_search_results{
			padding: 20px 10px 0;
		} 
		.block_seo{
			padding-top: 20px!important;
		}
	}
	.multilending .block_search_results .views_row, 
	.superlending .block_search_results .views_row{
		margin: 0 auto 20px!important;
	}
	.superlending .header .region_header .buttons_header, 
	.superlending_inner .header .region_header .buttons_header{
		float: none;
	}
	.block_newbuilding_row{
		padding: 0;
		margin-bottom: 20px;
		.block_content .view_content .views_row .left_wrap,
		.block_content .view_content .views_row .right_wrap{
			width: 100%;
			margin: 0;
			float: none;
		}
	}
	.block_newbuilding_row .block_content .view_content .views_row .right_wrap .field_img img{
		margin: 0 auto 7px;
	}
	.block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap{
		width: auto;
		margin-right: 20px;
		float: left;
	}
	.block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap {
		clear: none;
		width: auto;
		.statistics{
			width: 100%;
			text-align: left;
			margin-bottom: 20px;
		}
	}
	.newbuilding{
		.block_seo{
			padding-bottom: 55px;
		}
	}
	.newbuilding_inner{
		.slider_main{
			padding-bottom: 180px!important;
		}
		.block_inform_appartament .left_wrap{
			width: 100%;
		}
	}
	.newbuilding_inner .block_free{
		margin-bottom: 0px;
	}
	.plan,
	.ranking_daily{		
		.slider_main .slider_list{
			margin-bottom: 20px;
			text-align: center;
			.item{
				margin-bottom: 20px;
				width: 100%;
				margin-right: 10px;
				text-align: left;
			}
		}
	}
	.property_valuation .block_presentation form.webform_client_form .form_item.email, .real_services .block_presentation form.webform_client_form .form_item.email{
		margin-right: auto !important;
	}
	.property_valuation {		
		.block_presentation{
			margin-bottom: 20px;
		}
		.block_benefits.steps,
		.block_services{
			padding: 20px 10px;
		}
	}
	.block_hydrometers {
		text-align: center;
		.views_row{
			margin-right: 0;
		}
	}
	.installation_water{		
		.block_video{
			margin-bottom: 20px!important;
		}
		.block_package .views_row:last-child{
			margin-bottom: 0;
		}
	}
	.header .benefits .item{
		padding-right: 0;
	}
	.ranking_daily{		
		.block_daily_products{
			padding: 20px 0px;
		}
		.block_seo{
			padding-top: 0;
		}
	}
	.superlending .search_results form.webform_client_form .form_checkboxes{
		position: static;
	}
	.superlending{
		.search_results {
			form.webform_client_form{
				padding: 0 10px;
					.form_item.form_select{
						width: 100%;
					}					
				.form_select.currency,
				.area_wrap .form_select{
					width: 30%;
				}
				.form_wrapper{
					width: 100%;
				}
			}
		}
		.search_results form.webform_client_form .form_item{
			margin: 0 0 20px;
		}
		.block_presentation:not(.second) .block_title{
			margin-bottom: 20px;
		}
		.block_presentation:not(.second) form.webform_client_form .form_item{
			margin: 0 auto 20px !important;
		}
		.block_presentation.second{
			padding-bottom: 55px;
		}
	}
	.superlending_inner,
	.superlending{
		.footer{
			height: 440px;
			margin-top: -440px;
		}
		.middle{
			padding-bottom: 440px;
		}
	}
	.superlending_inner{
		.block_video{
			margin-bottom: 20px!important;
		}
		.block_vip_shops{
			padding-left: 10px;
			padding-right: 10px;
		}
	}
	.test_passing .slider_main{
		padding-bottom: 130px !important;
	}
	.test_result{
		.block_tests_wrapper .views_row{
			padding: 20px 10px;
		}
	}
	.universal{
		.block_advantages_1{
			padding: 0;
			text-align: center;
			.views_row{
				margin: 0 10px 30px;
			}
		}
	}
	.video_inner{
		.slider_main{
			padding-bottom: 170px !important;
			.come_back{
				position: static;
				float: none;
				margin-bottom: 25px;
			}
		}
		.block_video{
			padding: 10px;
		}
		.middle_inner{
			padding: 10px;
		}
		.block_body {
			.left_wrap{
				width: 100%;
				margin: 0 0 20px;
				float: none;
			}
		} 
	}
	.video_transfer{
		.block_video_transfer{
			padding: 0;
			.views_row{
				padding: 18px 10px;
				text-align: center;
			}
			.field_image{
				float: none;
				margin: 0 auto 20px;
			}
			.field_nid{
				text-align: left;
			}
			.load_more{
				margin-bottom: 30px;
			}
		}
		.middle_inner{
			padding: 10px;
		}
	}
	.front {
		.block_shops{
			padding: 20px 10px;
			.view_content{
				.slick-list{
					padding: 0px;
					.views_row{
						margin: 0 10px 20px!important;
					}
				}
			}
		}
	}
	.block_webform.block_order_form .webform_client_form .top_wrap{
		float: none;
		margin: 0;
	}
	.articles_inner .social-likes_vertical .social-likes__widget{
		margin: 0 5px;
	}
	.block_services .views_row .field_title a{
		font-size: 22px;
	}
	.block_should_use{
		padding: 20px 0;
	}
	.block_vip_shops .view_content .views_row .views_body .text{
		font-size: 14px;
	}
	input, textarea, select{
		border-radius: 0;
	}
	.insurance {		
		.block_seo{
			margin-bottom: 20px;
		}
	}
	.multilending {		
		.search_results{
			margin-top: 0;
			.form_select.currency{
				width: 30%;
			}
			form.webform_client_form .form_item{
				margin: 0 0 20px;
			}
			.form_wrapper{
				width: 100%;			
			}
		}
		.block_presentation{
			padding-bottom: 55px;
		}
	}
	.search_results .form_item.metro{
		width: 100%;
		margin-bottom: 20px!important;
	}
	.newbuilding{
		.middle_inner{
			padding: 20px 10px;
		}
		.see_more{
			margin-bottom: 20px;
		}
	}
	.block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview{
		font-size: 16px;
		a{
			padding: 0 10px;
		}
	}
	.webform_client_form .form_number input{
		width: 76px;
	}
	.slider_for{
		margin-bottom: 0;
	}
	.block_inform_appartament .right_wrap .contacts_person .label{
		font-size: 16px;
		margin-top: 2px;
	}
	.ya-share2__list_direction_horizontal > .ya-share2__item{
		margin-bottom: 5px!important;
	}
	.newbuilding_inner {		
		.block_maps{
			margin-bottom: 0;
		}
		.block_vip_shops .block_title{
			text-align: center;
			font-size: 24px;
		}
	}
	.plan,
	.property_valuation {		
		.block_benefits{
			padding-bottom: 0;
		}
		.block_video{
			margin-bottom: 30px!important;
		}
	}
	.process{
		.banner{
			padding-bottom: 55px;
		}
	}
	.property_valuation .block_presentation form.webform_client_form .form_actions input{
		font-size: 18px;
	}
	.ranking{
		.block_search_results .views_row .views_body .read_more a{
			font-size: 14px;
		}
	}
	.search_results.search_products .webform_client_form .form_wrapper{
		margin-right: 0;
	}
	.search_results .filters{
		max-width: 100%;
		float: none;
		margin-right: 0;
	}
	.superlending{
		.webform_client_form .form_number{
			margin-right: 0;
		}
		.block_presentation{
			padding-left: 0;
			padding-right: 0;
			&.second{
				padding-right: 15px;
				padding-left: 15px;
			}
		}
	}
	.apartment .block_vip_shops .block_title{
		text-align: center;
		font-size: 24px;
	}
	.trust {
		.slider_main{
			margin-bottom: 20px;
		}
	}
	.universal{
		.block_seo{
			margin-bottom: 20px;
		}
	}
}
@media (min-width: 330px) and (max-width: 374px){
	.block_seo {		
		.content_body{
			height: 330px;
		}
	}
}
@media (min-width: 375px) and (max-width: 768px){
	.block_seo {		
		.content_body{
			height: 301px;
		}
	}
}