@charset "UTF-8";
/*----------------------------------------------------------------------------
									FONTS
----------------------------------------------------------------------------*/
@font-face {
  font-family: 'cuprumregular';
  src: url("fonts/cuprum-regular-webfont-webfont.eot");
  src: url("fonts/cuprum-regular-webfont-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/cuprum-regular-webfont-webfont.woff") format("woff"), url("fonts/cuprum-regular-webfont-webfont.ttf") format("truetype"), url("fonts/cuprum-regular-webfont-webfont.svg#_.regular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/roboto-light.eot");
  src: url("fonts/roboto-light.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-light.woff") format("woff"), url("fonts/roboto-light.ttf") format("truetype"), url("fonts/roboto-light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0009-FFFD; }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/roboto-regular.eot");
  src: url("fonts/roboto-regular.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-regular.woff") format("woff"), url("fonts/roboto-regular.ttf") format("truetype"), url("fonts/roboto-regular.svg#Roboto-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0009-FFFD; }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/Roboto-Medium.eot");
  src: url("fonts/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium.woff2") format("woff2"), url("fonts/Roboto-Medium.woff") format("woff"), url("fonts/Roboto-Medium.ttf") format("truetype"), url("fonts/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("fonts/roboto-bold.eot");
  src: url("fonts/roboto-bold.eot?#iefix") format("embedded-opentype"), url("fonts/roboto-bold.woff") format("woff"), url("fonts/roboto-bold.ttf") format("truetype"), url("fonts/roboto-bold.svg#Roboto-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-FFFD; }

@font-face {
  font-family: 'PFDinTextCompPro';
  src: url("fonts/PFDinTextCompPro-Regular.eot");
  src: url("fonts/PFDinTextCompPro-Regular.eot?#iefix") format("embedded-opentype"), url("fonts/PFDinTextCompPro-Regular.woff") format("woff"), url("fonts/PFDinTextCompPro-Regular.ttf") format("truetype"), url("fonts/PFDinTextCompPro-Regular.svg#PFDinTextCompPro-Regular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'PFDinTextCompPro';
  src: url("fonts/PFDinTextCompPro-Medium.eot");
  src: url("fonts/PFDinTextCompPro-Medium.eot?#iefix") format("embedded-opentype"), url("fonts/PFDinTextCompPro-Medium.woff") format("woff"), url("fonts/PFDinTextCompPro-Medium.ttf") format("truetype"), url("fonts/PFDinTextCompPro-Medium.svg#PFDinTextCompPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

/*----------------------------------------------------------------------------
									BODY
----------------------------------------------------------------------------*/
ul.menu li {
  margin: 0;
  padding: 0;
  list-style: none; }

input.form-submit {
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }

input.form-submit:hover {
  opacity: 0.8; }

input {
  outline: none;
  -webkit-appearance: none;
  appearance: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

select {
  -webkit-appearance: none;
  appearance: none; }

input.error,
textarea.error,
select.error,
input[type="text"].error,
input[type="password"].error {
  border: 1px solid red !important; }

a {
  color: #3b4047;
  outline: none;
  cursor: pointer;
  text-decoration: underline; }

a:hover {
  text-decoration: none; }

tbody,
img {
  border: none; }

input {
  vertical-align: middle; }

img {
  max-width: 100%;
  height: auto; }

* {
  margin: 0;
  padding: 0;
  outline: none; }

p {
  margin: 0 0 18px; }

html {
  height: 100%;
  min-width: 1200px; }

body {
  font: 400 14px 'Roboto', sans-serif;
  width: 100%;
  height: 100%;
  line-height: 1.3125;
  color: #3b4047; }

.wrapper {
  width: 100%;
  margin: 0 auto;
  min-height: 100%;
  height: auto !important;
  height: 100%;
  overflow: hidden; }

/*----------------------------------------------------------------------------
									HEADER
----------------------------------------------------------------------------*/
.contacts_header .phone_number a {
  font: 400 30px 'PFDinTextCompPro', sans-serif;
  text-decoration: none;
  cursor: default; }

.buttons_header {
  width: 190px; }
  .buttons_header .application {
    margin-bottom: 8px;
    cursor: pointer;
    text-transform: uppercase;
    color: #18bb49;
    min-width: 189px;
    overflow: hidden;
    padding: 0 7px 0 36px;
    text-align: center;
    border: 2px solid #18bb49;
    box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.3);
    height: 36px;
    background: #fff url(images/edit.png) no-repeat center left 9px;
    font: 500 18px/36px PFDinTextCompPro, sans-serif;
    box-sizing: border-box;
    transition: .3s; }
    .buttons_header .application:hover {
      transform: scale(0.95); }
  .buttons_header .add_advert {
    cursor: pointer;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: #03a9f4;
    min-width: 189px;
    overflow: hidden;
    padding: 0 7px 0 36px;
    text-align: center;
    border: 2px solid #03a9f4;
    box-shadow: inset 0 2px 1px rgba(255, 255, 255, 0.3);
    height: 36px;
    background: #fff url(images/plus.png) no-repeat center left 9px;
    font: 500 18px/36px PFDinTextCompPro, sans-serif;
    box-sizing: border-box;
    transition: .3s; }
    .buttons_header .add_advert:hover {
      transform: scale(0.95); }

.header {
  padding: 9px 0 0;
  box-sizing: border-box; }
  .header .header_content {
    max-width: 1170px;
    margin: 0 auto;
    height: 95px;
    overflow: hidden;
    padding: 0 25px; }
  .header .logo_wrapper {
    float: left;
    margin: 9px 30px 0 0; }
    .header .logo_wrapper a.logo {
      margin-bottom: 10px;
      display: block;
      text-decoration: none;
      text-transform: uppercase; }
      .header .logo_wrapper a.logo img {
        margin-right: 10px; }
      .header .logo_wrapper a.logo span {
        display: inline-block;
        vertical-align: bottom; }
      .header .logo_wrapper a.logo .site_name {
        color: #03a9f4;
        font: 500 41px 'PFDinTextCompPro', sans-serif; }
      .header .logo_wrapper a.logo .domen {
        font: 400 26px 'PFDinTextCompPro', sans-serif;
        color: #3b4047;
        margin-bottom: 4px; }
  .header .region_header {
    float: right;
    overflow: hidden; }
    .header .region_header > div {
      float: left; }
      .header .region_header > div + div {
        margin-left: 44px; }
    .header .region_header .address {
      margin-bottom: 3px; }
  .superlending .header .region_header,
  .superlending_inner .header .region_header {
    float: none; }
    .superlending .header .region_header .buttons_header,
    .superlending_inner .header .region_header .buttons_header {
      float: right; }
  .superlending .header .logo_wrapper,
  .superlending_inner .header .logo_wrapper {
    margin-right: 90px; }
  .header .benefits {
    width: 335px; }
    .header .benefits .item {
      display: inline-block;
      vertical-align: middle;
      width: 48%;
      padding-right: 12px;
      box-sizing: border-box; }
      .header .benefits .item.test a {
        background: url(images/check.png) no-repeat top 0px left; }
      .header .benefits .item.video a {
        background: url(images/play.png) no-repeat top 0px left; }
      .header .benefits .item.plan a {
        background: url(images/plan.png) no-repeat top 4px left; }
      .header .benefits .item.favorite a {
        background: url(images/star.png) no-repeat top 0px left; }
      .header .benefits .item a {
        text-transform: uppercase;
        font: 500 16px/31px 'PFDinTextCompPro', sans-serif;
        text-decoration: none;
        display: block;
        padding-left: 34px;
        margin-bottom: 13px;
        box-sizing: border-box; }
        .header .benefits .item a:hover {
          text-decoration: underline; }

.block_content {
  max-width: 1170px;
  margin: auto; }

.block_main_menu {
  clear: both;
  background: #03a9f4;
  height: 45px;
  position: relative;
  z-index: 10;
  box-shadow: inset 0 3px 5px 0 rgba(5, 5, 5, 0.1); }
  .block_main_menu .menu {
    list-style: none; }
    .block_main_menu .menu li.leaf {
      display: inline-block;
      vertical-align: middle;
      width: 16.5%;
      text-align: center;
      float: left; }
      .block_main_menu .menu li.leaf > a {
        display: block;
        color: #fff;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        font: 400 24px/48px 'PFDinTextCompPro', sans-serif;
        height: 45px;
        overflow: hidden;
        text-decoration: none;
        text-transform: uppercase;
        transition: .3s; }
        .block_main_menu .menu li.leaf > a:hover, .block_main_menu .menu li.leaf > a.active {
          background: #0190d1; }
    .block_main_menu .menu .menu_child {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 10;
      background: #03a9f4;
      text-align: left; }
      .block_main_menu .menu .menu_child a {
        text-transform: none;
        height: auto;
        line-height: 1.4;
        font: 400 18px 'PFDinTextCompPro', sans-serif;
        text-decoration: none;
        color: #fff;
        display: block;
        padding: 10px 15px;
        transition: .3s;
        box-sizing: border-box; }
        .block_main_menu .menu .menu_child a:hover {
          background: #0190d1; }
    .block_main_menu .menu li.expanded {
      position: relative; }
      .block_main_menu .menu li.expanded:hover .menu_child {
        display: block; }

img.left {
  float: left;
  margin: 0 34px 18px 0; }

.block_seo .image {
  float: left; }

body.repair_premises .block_seo,
.block_seo {
  clear: both;
  background: #fff;
  padding-bottom: 40px;
  border-bottom: 1px solid #cacaca;
  margin-bottom: 40px;
  box-sizing: border-box; }
  .tests body.repair_premises .block_seo,
  .real_services body.repair_premises .block_seo, .tests
  .block_seo,
  .real_services
  .block_seo {
    border-bottom: 0;
    padding-bottom: 13px; }
  .not-front body.repair_premises .block_seo, .not-front
  .block_seo {
    padding-top: 42px;
    margin-bottom: 0; }
  .cleaning body.repair_premises .block_seo, .cleaning
  .block_seo {
    padding-top: 0; }
  .earth body.repair_premises .block_seo,
  .ranking_daily body.repair_premises .block_seo,
  .trust body.repair_premises .block_seo,
  .services body.repair_premises .block_seo,
  .insurance body.repair_premises .block_seo,
  .universal body.repair_premises .block_seo, .earth
  .block_seo,
  .ranking_daily
  .block_seo,
  .trust
  .block_seo,
  .services
  .block_seo,
  .insurance
  .block_seo,
  .universal
  .block_seo {
    background: transparent;
    padding-bottom: 0;
    border: none; }
    .earth body.repair_premises .block_seo .block_title,
    .ranking_daily body.repair_premises .block_seo .block_title,
    .trust body.repair_premises .block_seo .block_title,
    .services body.repair_premises .block_seo .block_title,
    .insurance body.repair_premises .block_seo .block_title,
    .universal body.repair_premises .block_seo .block_title, .earth
    .block_seo .block_title,
    .ranking_daily
    .block_seo .block_title,
    .trust
    .block_seo .block_title,
    .services
    .block_seo .block_title,
    .insurance
    .block_seo .block_title,
    .universal
    .block_seo .block_title {
      margin-bottom: 35px; }
  body.repair_premises .block_seo .block_title,
  .block_seo .block_title {
    padding: 0 20px;
    line-height: 1;
    box-sizing: border-box; }
  body.repair_premises .block_seo .body,
  .block_seo .body {
    font: 400 24px/30px 'PFDinTextCompPro', sans-serif; }
  body.repair_premises .block_seo .content_body,
  .block_seo .content_body {
    height: 255px;
    overflow: hidden; }
  body.repair_premises .block_seo .banner,
  .block_seo .banner {
    text-align: center; }
    body.repair_premises .block_seo .banner img,
    .block_seo .banner img {
      display: block;
      margin: auto; }
    .newbuilding body.repair_premises .block_seo .banner, .newbuilding
    .block_seo .banner {
      padding: 34px 0 24px; }

.installation_water .block_seo,
.property_valuation .block_seo,
.cleaning .block_seo {
  background: none;
  border-bottom: none; }

.block_seo.active .read_more {
  display: none; }

.block_seo.active .content_body {
  height: auto !important;
  overflow: visible !important; }

.body p {
  margin-bottom: 8px; }

.body ul {
  font: 400 14px/20px Roboto, sans-serif;
  overflow: hidden;
  margin-top: 15px; }
  .body ul li {
    position: relative;
    padding-left: 40px;
    min-height: 30px;
    margin-bottom: 22px;
    background: url(images/check.png) no-repeat top 4px left;
    box-sizing: border-box; }
    .body ul li:last-child {
      margin-bottom: 14px; }

.block_articles {
  text-align: center;
  padding-bottom: 36px;
  overflow: hidden;
  clear: both;
  box-sizing: border-box; }
  .block_articles .view_content {
    float: left;
    width: 100%; }
  .block_articles .block_title {
    letter-spacing: -0.2px;
    margin-bottom: 30px; }
  .block_articles .views_row {
    display: inline-block;
    vertical-align: top;
    text-align: left;
    float: left;
    width: 270px;
    margin: 0 30px 30px 0;
    transition: .3s; }
    .block_articles .views_row:hover {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); }
    .block_articles .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_articles .views_row .field_image img {
      display: block;
      margin: auto; }
    .block_articles .views_row .views_body {
      background: #fff;
      padding: 12px 20px;
      border: 1px solid #e3e2e2;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      box-sizing: border-box; }
    .block_articles .views_row .field_title a {
      font: 400 24px/30px 'PFDinTextCompPro', sans-serif;
      text-decoration: none;
      margin-bottom: 13px;
      display: block;
      padding-right: 10px;
      transition: color .3s;
      box-sizing: border-box; }
      .block_articles .views_row .field_title a:hover {
        color: #18bb49;
        text-decoration: underline; }
    .block_articles .views_row .field_body {
      line-height: 20px;
      margin-bottom: 15px; }
    .block_articles .views_row .statistics {
      color: #89919e;
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      padding-left: 44px;
      background: url(images/stat.png) no-repeat top left; }

/*----------------------------------------------------------------------------
									MIDDLE
----------------------------------------------------------------------------*/
.middle {
  width: 100%;
  padding: 0 0 180px;
  position: relative;
  clear: both; }
  .middle::after {
    display: table;
    clear: both;
    content: ''; }
  .not-front .middle {
    background: url(images/bg.png) repeat top center; }

.not-front .middle_inner {
  max-width: 1170px;
  margin: auto;
  padding-top: 30px; }

.container {
  width: 100%; }
  .sidebar_first .container {
    float: left;
    overflow: hidden; }

.sidebar_first #content {
  padding: 0 0 0 400px; }

.left_sidebar {
  float: left;
  width: 370px;
  margin-left: -100%;
  position: relative; }
  .left_sidebar .block .views_row {
    width: 100%;
    margin: 0 0 30px; }
  .left_sidebar .block .block_title {
    font-size: 36px;
    font-weight: 500;
    text-align: left;
    margin-bottom: 10px; }
  .left_sidebar .block_left_menu {
    margin-bottom: 30px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
    .left_sidebar .block_left_menu a {
      display: block;
      text-decoration: none;
      background: #fff;
      height: 48px;
      border-bottom: 1px solid #d4d4d4;
      font: 400 24px/48px 'PFDinTextCompPro', sans-serif;
      padding: 0 16px 0 19px;
      transition: color .3s, background .3s;
      box-sizing: border-box; }
      .left_sidebar .block_left_menu a:hover, .left_sidebar .block_left_menu a.active {
        color: #fff;
        background: #18bb49;
        border-bottom-color: transparent; }
        .left_sidebar .block_left_menu a:hover span, .left_sidebar .block_left_menu a.active span {
          opacity: 0.5;
          color: #ffffff; }
      .left_sidebar .block_left_menu a span {
        color: #89919e;
        float: right;
        display: inline-block;
        vertical-align: middle; }
  .left_sidebar .banner {
    background: #fff;
    padding: 22px 0;
    margin-bottom: 35px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    box-sizing: border-box; }
    .left_sidebar .banner .field_image img {
      display: block;
      margin: auto; }

.property_search {
  background: #fff;
  padding: 28px 29px;
  margin-bottom: 33px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .property_search .form_title {
    text-transform: uppercase;
    color: #3b4047;
    font: 500 36px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 15px; }
  .property_search .webform_client_form .form_wrapper {
    margin-bottom: 17px; }
    .property_search .webform_client_form .form_wrapper .form_checkbox {
      width: 100%; }
      .property_search .webform_client_form .form_wrapper .form_checkbox + .form_checkbox {
        margin-top: 15px; }

.right_sidebar {
  float: left;
  width: 240px;
  margin-left: -240px;
  position: relative; }

.slider_main {
  text-align: center;
  padding: 65px 0 50px;
  color: #fff;
  background: url(images/bg_slide.png) no-repeat center/cover;
  box-sizing: border-box; }
  .slider_main .block_content {
    position: relative; }
  .test_result .slider_main {
    margin-bottom: 30px; }
  body.articles .slider_main {
    background: url(images/banner_11.jpg) no-repeat center/cover;
    margin-bottom: 15px; }
  body.test_passing .slider_main {
    background: url(images/banner_4.jpg) no-repeat center/cover;
    padding: 30px; }
    body.test_passing .slider_main .slider_title {
      margin-bottom: 0; }
  body.articles_inner .slider_main,
  body.final .slider_main {
    background: url(images/banner_12.jpg) no-repeat center/cover; }
  body.articles_inner .slider_main .slider_title {
    margin-bottom: 40px; }
  body.final .slider_main {
    padding-top: 28px;
    margin-bottom: 35px; }
    body.final .slider_main .slider_title {
      padding-bottom: 28px;
      position: relative;
      margin-bottom: 28px; }
      body.final .slider_main .slider_title::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #fff;
        height: 3px;
        width: 220px;
        margin-left: -110px; }
    body.final .slider_main .top_inner_content .title {
      margin-bottom: 20px; }
    body.final .slider_main .button_wrap {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      body.final .slider_main .button_wrap a {
        display: block;
        width: 127px;
        text-transform: uppercase;
        text-decoration: none;
        border: 2px solid #fff;
        height: 36px;
        color: #fff;
        font: 500 18px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 30px;
        line-height: 36px;
        box-sizing: border-box;
        transition: opacity .3s; }
        body.final .slider_main .button_wrap a:hover {
          opacity: .7; }
  body.favorites .slider_main {
    background: url(images/banner_14.jpg) no-repeat center/cover;
    padding-bottom: 17px; }
  body.earth .slider_main {
    background: url(images/banner_3.png) no-repeat center/cover; }
  body.tests .slider_main {
    background: url(images/banner_test.png) no-repeat center/cover;
    padding-bottom: 1px; }
  body.test_result .slider_main,
  body.test_passing .slider_main {
    background: url(images/banner_test2.jpg) no-repeat center/cover;
    padding-bottom: 35px; }
  body.test_passing .slider_main {
    padding-bottom: 180px; }
  body.video_inner .slider_main {
    padding-top: 42px; }
  body.superlending_inner .slider_main {
    background: url(images/banner_20.jpg) no-repeat center/cover; }
  body.video_transfer .slider_main {
    background: url(images/banner_13.jpg) no-repeat center/cover; }
  body.cleaning .slider_main {
    background: url(images/banner_9.jpg) no-repeat center/cover; }
  body.real_services .slider_main {
    background: url(images/banner_17.jpg) no-repeat center/cover;
    padding: 43px 0 71px; }
    body.real_services .slider_main .webform_client_form {
      max-width: 739px; }
      body.real_services .slider_main .webform_client_form .form_item {
        margin-right: 6px;
        margin-bottom: 10px; }
        body.real_services .slider_main .webform_client_form .form_item.email {
          margin-right: 0; }
  .slider_main .slider_title {
    text-transform: uppercase;
    font: 400 64px/64px 'PFDinTextCompPro', sans-serif;
    padding-bottom: 17px;
    position: relative;
    max-width: 1170px;
    margin: 0 auto 20px; }
    .slider_main .slider_title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      background: #fff;
      height: 5px;
      width: 102px;
      margin-left: -51px; }
  .slider_main.slider_notfront {
    padding: 22px 0 36px;
    background-image: url(images/bg_slide_notfr.png); }
    .slider_main.slider_notfront .slider_title {
      text-transform: none;
      padding: 0; }
      .slider_main.slider_notfront .slider_title::after {
        content: none; }
  .ranking_daily .slider_main,
  .multilending .slider_main,
  .superlending .slider_main,
  .process .slider_main {
    background-image: url(images/banner_3.jpg);
    padding: 50px 0 58px; }
    .ranking_daily .slider_main .slider_title,
    .multilending .slider_main .slider_title,
    .superlending .slider_main .slider_title,
    .process .slider_main .slider_title {
      padding-bottom: 17px;
      position: relative;
      margin-bottom: 20px; }
      .ranking_daily .slider_main .slider_title::after,
      .multilending .slider_main .slider_title::after,
      .superlending .slider_main .slider_title::after,
      .process .slider_main .slider_title::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #fff;
        height: 3px;
        width: 220px;
        margin-left: -110px; }
    .ranking_daily .slider_main .title_text,
    .multilending .slider_main .title_text,
    .superlending .slider_main .title_text,
    .process .slider_main .title_text {
      margin-bottom: 13px; }
    .ranking_daily .slider_main .form_item,
    .multilending .slider_main .form_item,
    .superlending .slider_main .form_item,
    .process .slider_main .form_item {
      width: 220px;
      margin-right: 20px !important; }
  body.multilending .slider_main {
    background: url(images/banner_18.jpg) no-repeat center/cover;
    padding: 83px 0 177px; }
    body.multilending .slider_main .slider_subtitle {
      text-transform: uppercase;
      margin-bottom: 50px; }
    body.multilending .slider_main .slider_title {
      padding-bottom: 27px;
      margin-bottom: 30px; }
  body.superlending .slider_main {
    background: url(images/banner_19.jpg) no-repeat center/cover;
    padding: 83px 0 177px; }
    body.superlending .slider_main .slider_subtitle {
      text-transform: uppercase;
      margin-bottom: 50px; }
    body.superlending .slider_main .slider_title {
      padding-bottom: 27px;
      margin-bottom: 30px; }
  .newbuilding_inner .slider_main {
    background: url(images/banner_new.jpg) no-repeat center/cover !important;
    padding-bottom: 184px; }
  .apartment .slider_main {
    padding-bottom: 184px;
    background: url(images/banner_21.jpg) no-repeat center/cover; }
  .daily_inner .slider_main {
    padding-bottom: 184px;
    background: url(images/banner_22.jpg) no-repeat center/cover; }
  .installation_water .slider_main {
    background: url(images/banner_25.jpg) no-repeat center/cover !important; }
    .installation_water .slider_main .title_text {
      margin-bottom: 15px; }
  .newbuilding .slider_main {
    background: url(images/banner_5.jpg) no-repeat center/cover; }
  .contacts .slider_main {
    background: url(images/banner_contacts.jpg) no-repeat center/cover;
    padding: 45px 0 70px; }
    .contacts .slider_main .webform_client_form .form_actions {
      width: 100%; }
      .contacts .slider_main .webform_client_form .form_actions input {
        width: 100%; }
    .contacts .slider_main .webform_client_form .form_item.name input {
      background-image: url(images/user.png); }
    .contacts .slider_main .top_inner_content .title {
      margin-bottom: 55px; }
  .process .slider_main {
    background: url(images/banner_23.jpg) no-repeat center/cover; }
    .process .slider_main .slider_title {
      padding-bottom: 23px;
      margin-bottom: 55px; }
    .process .slider_main .form_item {
      width: 335px; }
      .process .slider_main .form_item.form_select select {
        height: 45px;
        background: #fff url("images/select.png") no-repeat center right 25px;
        border: none; }
  .video_inner .slider_main {
    background: url(images/banner_15.jpg) no-repeat center/cover;
    padding-bottom: 184px; }
    .video_inner .slider_main .slider_title {
      margin-bottom: 35px; }
  .trust .slider_main,
  .installation_water .slider_main,
  .property_valuation .slider_main,
  .insurance .slider_main,
  .cleaning .slider_main,
  .universal .slider_main {
    background: url(images/banner_6.jpg) no-repeat center/cover;
    padding: 50px 0 55px; }
    .trust .slider_main .slider_title,
    .installation_water .slider_main .slider_title,
    .property_valuation .slider_main .slider_title,
    .insurance .slider_main .slider_title,
    .cleaning .slider_main .slider_title,
    .universal .slider_main .slider_title {
      padding-bottom: 25px;
      margin-bottom: 30px; }
      .trust .slider_main .slider_title::after,
      .installation_water .slider_main .slider_title::after,
      .property_valuation .slider_main .slider_title::after,
      .insurance .slider_main .slider_title::after,
      .cleaning .slider_main .slider_title::after,
      .universal .slider_main .slider_title::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #fff;
        height: 3px;
        width: 220px;
        margin-left: -110px; }
    .trust .slider_main .slider_subtitle,
    .installation_water .slider_main .slider_subtitle,
    .property_valuation .slider_main .slider_subtitle,
    .insurance .slider_main .slider_subtitle,
    .cleaning .slider_main .slider_subtitle,
    .universal .slider_main .slider_subtitle {
      text-transform: uppercase; }
    .trust .slider_main form .form_item,
    .installation_water .slider_main form .form_item,
    .property_valuation .slider_main form .form_item,
    .insurance .slider_main form .form_item,
    .cleaning .slider_main form .form_item,
    .universal .slider_main form .form_item {
      margin-right: 20px !important; }
  .property_valuation .slider_main {
    background-image: url(images/banner_7.jpg); }
    .property_valuation .slider_main .title_text {
      margin-bottom: 13px; }
  .universal .slider_main {
    background-image: url(images/banner_10.jpg); }
    .universal .slider_main .slider_title {
      padding-bottom: 8px;
      margin-bottom: 18px; }
    .universal .slider_main .form_item {
      width: 220px; }
    .universal .slider_main .form_item.phone input {
      background-image: url(images/phone3.png); }
  .insurance .slider_main {
    background-image: url(images/banner_8.jpg); }
  .earth .slider_main {
    padding: 50px 0 55px; }
    .earth .slider_main .slider_subtitle {
      text-transform: uppercase;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); }
    .earth .slider_main .text {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      font: 500 20px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 15px; }
    .earth .slider_main .form_item {
      width: 277px;
      margin-bottom: 0 !important; }
    .earth .slider_main .slider_title {
      padding-bottom: 25px;
      margin-bottom: 30px; }
      .earth .slider_main .slider_title::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        background: #fff;
        height: 3px;
        width: 220px;
        margin-left: -110px; }
  .services .slider_main {
    background-image: url(images/banner_7.png);
    padding: 16px 0 36px; }
  .slider_main .slider_subtitle {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font: 400 36px 'PFDinTextCompPro', sans-serif; }
    .slider_main .slider_subtitle u {
      text-decoration: none;
      box-shadow: inset 0 -2px 0 0 #fff; }
  .slider_main .text {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 30px; }
    .slider_main .text u {
      color: #f8dd1c; }
  .slider_main .title_text {
    font: 500 20px 'PFDinTextCompPro', sans-serif;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    margin: 0 auto 25px;
    max-width: 1170px; }
  .slider_main .slider_list {
    text-align: center;
    max-width: 1170px;
    margin: auto;
    overflow: hidden;
    margin-bottom: 40px; }
    .slider_main .slider_list .item {
      width: 220px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: top; }
    .slider_main .slider_list .li {
      width: 47px;
      height: 47px;
      border-radius: 50%;
      text-align: center;
      border: 2px solid #ffffff;
      background-color: rgba(255, 255, 255, 0.1);
      float: left;
      margin-right: 10px;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #ffffff;
      font: 400 24px/47px 'PFDinTextCompPro', sans-serif;
      box-sizing: border-box; }
    .slider_main .slider_list .field_item {
      overflow: hidden;
      text-align: left;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #ffffff;
      font: 400 20px 'PFDinTextCompPro', sans-serif; }
  .slider_main form {
    max-width: 1170px;
    margin: auto; }
    .contacts .slider_main form {
      max-width: 490px; }
      .contacts .slider_main form .form_item {
        margin-bottom: 10px;
        float: left; }
        .contacts .slider_main form .form_item.phone {
          margin-right: 0;
          float: right; }
  .slider_main .block_favorites .views_row {
    display: inline-block;
    vertical-align: top;
    margin-right: 55px;
    width: auto;
    text-align: left;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font: 500 20px 'PFDinTextCompPro', sans-serif; }
    .slider_main .block_favorites .views_row .count {
      font-weight: 400;
      font-size: 36px; }
  .slider_main .come_back {
    float: left;
    position: absolute;
    top: 7px;
    left: 0;
    z-index: 1; }
    .slider_main .come_back a {
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      text-decoration: none;
      text-transform: uppercase;
      cursor: pointer;
      text-align: center;
      padding: 0 20px;
      border: 2px solid #fff;
      font: 500 18px/34px 'PFDinTextCompPro', sans-serif;
      height: 36px;
      transition: .3s;
      box-sizing: border-box; }
      .slider_main .come_back a:hover {
        opacity: .7; }

.tests .slider_main .slider_list .item {
  width: auto;
  max-width: 280px;
  min-width: 195px;
  margin-right: 42px; }
  .tests .slider_main .slider_list .item:nth-child(2) {
    min-width: 240px; }

.ranking_daily .slider_main .slider_list .item {
  width: auto;
  max-width: 220px;
  margin-right: 67px; }
  .ranking_daily .slider_main .slider_list .item:nth-child(3) {
    margin-right: 0; }

.ranking_daily .slider_main .slider_title {
  padding-bottom: 25px;
  margin-bottom: 27px; }

.ranking_daily .slider_main .webform_client_form .form_item.phone input {
  background-image: url(images/phone3.png); }

.plan .slider_main {
  background: url(images/banner_16.jpg) no-repeat center/cover; }
  .plan .slider_main .slider_list .item {
    width: auto;
    max-width: none;
    min-width: auto;
    margin-right: 72px;
    line-height: 47px; }
    .plan .slider_main .slider_list .item:last-child {
      margin-right: 0; }
    .plan .slider_main .slider_list .item .field_item {
      margin-top: 13px; }
  .plan .slider_main .submit {
    text-align: center; }
    .plan .slider_main .submit a {
      outline: none;
      border: none;
      text-transform: uppercase;
      text-decoration: none;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #fff;
      font: 500 20px "PFDinTextCompPro", sans-serif;
      text-transform: uppercase;
      background-color: #17bb49;
      height: 45px;
      cursor: pointer;
      padding: 0 24px;
      background: #1cc755;
      background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
      background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      transition: opacity .3s;
      line-height: 47px; }
      .plan .slider_main .submit a:hover {
        opacity: .7; }

.top_inner_content {
  line-height: 20px;
  max-width: 1170px;
  margin: auto; }
  .top_inner_content .main_breadcrumbs {
    margin-bottom: 10px; }
    .services .top_inner_content .main_breadcrumbs {
      margin-bottom: 33px; }
    .articles_inner .top_inner_content .main_breadcrumbs {
      margin-bottom: 60px; }
    .top_inner_content .main_breadcrumbs a {
      color: #fff;
      text-decoration: none; }
    .top_inner_content .main_breadcrumbs span.delimiter {
      display: inline-block;
      vertical-align: middle;
      margin: 0 8px; }
  .top_inner_content .title {
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 40px; }

.icons_wrap {
  width: 100%;
  max-width: 645px;
  position: relative;
  text-align: center;
  margin: 0 auto 20px; }
  .articles_inner .icons_wrap {
    max-width: 513px; }
  .video_inner .icons_wrap .icon.stat {
    padding-top: 33px;
    background-position: center top 4px; }
  .test_result .icons_wrap {
    max-width: 585px;
    margin-bottom: 4px; }
  .icons_wrap .title {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    font: 500 20px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 10px; }
  .icons_wrap .icon {
    width: 46px;
    height: 46px;
    margin: 0 7px;
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box; }
    .icons_wrap .icon.vip {
      background: url(images/vip_icon.png) no-repeat center/contain; }
    .icons_wrap .icon.stat {
      background: url(images/stat_icon.png) no-repeat center top;
      padding-top: 29px; }
    .icons_wrap .icon.favorite {
      background: #fff url(images/favorite.png) no-repeat center;
      cursor: pointer;
      border-radius: 100%;
      box-sizing: border-box; }
      .icons_wrap .icon.favorite.active {
        background-image: url(images/favorite_active.png); }
    .icons_wrap .icon.result {
      background: #e14b4b;
      width: 70px;
      height: 70px;
      border-radius: 100%;
      font: 400 48px/80px 'PFDinTextCompPro', sans-serif; }
    .icons_wrap .icon.time {
      background: url(images/time.png) no-repeat center top;
      padding-top: 33px; }
  .icons_wrap::after, .icons_wrap::before {
    content: '';
    position: absolute;
    width: 220px;
    height: 3px;
    background: #ffffff;
    opacity: 0.5;
    top: 50%;
    left: 0;
    margin-top: -1.5px; }
    .test_result .icons_wrap::after, .test_result .icons_wrap::before {
      top: 67px; }
  .icons_wrap::before {
    left: auto;
    right: 0; }

.webform_client_form {
  text-align: center; }
  .property_search .webform_client_form {
    text-align: left; }
  .webform_client_form .form_item {
    width: 240px;
    display: inline-block;
    vertical-align: middle; }
    .webform_client_form .form_item input {
      width: 100%;
      height: 45px;
      background: #fff;
      padding: 0 15px;
      outline: none;
      border: none;
      color: #89919e;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px; }
      .webform_client_form .form_item input::-webkit-input-placeholder, .webform_client_form .form_item input::-moz-placeholder, .webform_client_form .form_item input:-moz-placeholder, .webform_client_form .form_item input:-ms-input-placeholder {
        color: #89919e; }
    .webform_client_form .form_item.phone {
      margin-right: 16px; }
      .webform_client_form .form_item.phone input {
        background: #fff url(images/phone.png) no-repeat center left 14px;
        padding-left: 45px; }
    .webform_client_form .form_item.search {
      margin-right: 16px;
      width: 275px; }
      .webform_client_form .form_item.search input {
        background: #fff url(images/search.png) no-repeat center left 14px;
        padding: 0 10px 0 45px; }
  .webform_client_form .form_wrapper {
    margin-bottom: 20px;
    width: 100%; }
    .webform_client_form .form_wrapper > label {
      display: block;
      margin-bottom: 6px; }
  .webform_client_form .form_select {
    width: 100%; }
    .webform_client_form .form_select label {
      margin-bottom: 6px;
      display: block; }
  .webform_client_form select {
    background: linear-gradient(180deg, transparent 0%, rgba(0, 0, 0, 0.06) 100%);
    border: 1px solid #bec7d1;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    height: 32px;
    width: 100%;
    padding: 0 25px 0 10px;
    -webkit-appearance: none;
    background: url("images/select.png") no-repeat center right 9px, url(images/grad.png) repeat center;
    line-height: 1em;
    /* for FF */
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
    /* for IE */
    -ms-appearance: none;
    appearance: none !important;
    box-sizing: border-box; }
  .webform_client_form select::-ms-expand {
    display: none; }
  .webform_client_form .form_radios,
  .webform_client_form .form_checkboxes {
    text-align: left;
    margin-bottom: 15px; }
    .webform_client_form .form_radios .form_checkbox,
    .webform_client_form .form_radios .form_radio,
    .webform_client_form .form_checkboxes .form_checkbox,
    .webform_client_form .form_checkboxes .form_radio {
      display: inline-block;
      vertical-align: middle;
      margin-right: 20px; }
      .webform_client_form .form_radios .form_checkbox input,
      .webform_client_form .form_radios .form_radio input,
      .webform_client_form .form_checkboxes .form_checkbox input,
      .webform_client_form .form_checkboxes .form_radio input {
        display: none; }
      .webform_client_form .form_radios .form_checkbox label,
      .webform_client_form .form_radios .form_radio label,
      .webform_client_form .form_checkboxes .form_checkbox label,
      .webform_client_form .form_checkboxes .form_radio label {
        display: block;
        padding-left: 25px;
        position: relative;
        cursor: pointer;
        box-sizing: border-box; }
        .webform_client_form .form_radios .form_checkbox label::after,
        .webform_client_form .form_radios .form_radio label::after,
        .webform_client_form .form_checkboxes .form_checkbox label::after,
        .webform_client_form .form_checkboxes .form_radio label::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 18px;
          height: 18px;
          background: #fff;
          border: 1px solid #bec7d1;
          box-shadow: 0 1px 0 0px #e5e5e5;
          box-sizing: border-box; }
      .webform_client_form .form_radios .form_checkbox input:checked + label,
      .webform_client_form .form_radios .form_radio input:checked + label,
      .webform_client_form .form_checkboxes .form_checkbox input:checked + label,
      .webform_client_form .form_checkboxes .form_radio input:checked + label {
        font-weight: 500; }
        .webform_client_form .form_radios .form_checkbox input:checked + label::after,
        .webform_client_form .form_radios .form_radio input:checked + label::after,
        .webform_client_form .form_checkboxes .form_checkbox input:checked + label::after,
        .webform_client_form .form_checkboxes .form_radio input:checked + label::after {
          background: url(images/checkbox.png) no-repeat center;
          border-color: #03a9f4; }
  .webform_client_form .form_number {
    display: inline-block;
    vertical-align: bottom;
    margin-right: 10px; }
    .webform_client_form .form_number:last-child {
      margin-right: 0; }
    .webform_client_form .form_number input {
      width: 102px;
      border: 1px solid #bec7d1;
      height: 32px;
      color: #3b4047;
      padding-left: 9px;
      box-shadow: 0 1px 0 0 #e5e5e5;
      box-sizing: border-box; }
      .webform_client_form .form_number input::-webkit-input-placeholder, .webform_client_form .form_number input::-moz-placeholder, .webform_client_form .form_number input:-moz-placeholder, .webform_client_form .form_number input:-ms-input-placeholder {
        color: #3b4047 !important; }
  .webform_client_form .price_currency {
    display: inline-block;
    vertical-align: bottom;
    width: 79px; }
  .webform_client_form .form_item.date {
    width: 187px; }
    .webform_client_form .form_item.date input {
      background: #fff url(images/date.png) no-repeat center right 13px;
      padding: 0 45px 0 15px;
      color: #3b4047; }
  .webform_client_form .form_item.name input {
    padding: 0 15px 0 45px;
    background: #fff url(images/user_name.png) no-repeat center left 13px; }
  .webform_client_form .form_actions {
    display: inline-block;
    vertical-align: middle; }
    .webform_client_form .form_actions input {
      outline: none;
      border: none;
      text-transform: uppercase;
      color: #fff;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #fff;
      font: 500 20px "PFDinTextCompPro", sans-serif;
      text-transform: uppercase;
      background-color: #17bb49;
      height: 45px;
      cursor: pointer;
      padding: 0 24px;
      background: #1cc755;
      background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
      background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      transition: opacity .3s; }
      .webform_client_form .form_actions input:hover {
        opacity: .7; }
    .property_search .webform_client_form .form_actions {
      position: relative;
      width: 100%; }
      .property_search .webform_client_form .form_actions::after {
        background: url(images/search_btn.png) no-repeat center left 12px;
        content: '';
        position: absolute;
        top: 13px;
        left: 0;
        width: 32px;
        height: 20px;
        z-index: 1; }
      .property_search .webform_client_form .form_actions input {
        padding-left: 41px;
        text-align: left;
        float: left;
        margin-right: 25px; }
    .webform_client_form .form_actions .links {
      overflow: hidden;
      margin-top: 4px; }
      .webform_client_form .form_actions .links .search_full a {
        color: #18bb49;
        display: block;
        margin-bottom: 5px; }
      .webform_client_form .form_actions .links .search_clear a {
        color: #d0021b; }
  .webform_client_form .form_textarea {
    width: 100%;
    margin: 0 0 12px !important;
    clear: both; }
  .webform_client_form textarea {
    resize: none;
    width: 100%;
    display: block;
    height: 100px;
    padding: 12px 20px 20px 40px;
    border: none;
    background: #fff;
    outline: none;
    color: #89919e;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    background: #fff url(images/email.png) no-repeat top 16px left 10px;
    box-sizing: border-box; }
  .webform_client_form .textarea {
    width: 100%;
    margin-right: 0 !important; }
    .webform_client_form .textarea.comment textarea {
      background-image: url(images/comment.png); }

.webform_client_form .form_radios .form_radio label::after {
  border-radius: 100%; }

.webform_client_form .form_radios .form_radio input:checked + label::after {
  background: #03a9f4;
  box-shadow: inset 0 0 0 4px #fff; }

.block_title {
  text-transform: uppercase;
  text-align: center;
  max-width: 1170px;
  margin: auto;
  font: 500 64px 'PFDinTextCompPro', sans-serif;
  margin-bottom: 20px; }

.block_services {
  padding-bottom: 10px;
  overflow: hidden;
  clear: both; }
  .block_services .block_title {
    margin-bottom: 24px; }
  .insurance .block_services,
  .cleaning .block_services,
  .universal .block_services {
    padding: 30px 0 0 0;
    background: #fff; }
  .block_services .views_row {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    float: left;
    margin: 0 30px 28px 0; }
    .block_services .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_services .views_row .field_image {
      transition: .3s; }
      .block_services .views_row .field_image:hover {
        opacity: .7; }
      .block_services .views_row .field_image img {
        display: block;
        margin: auto; }
    .block_services .views_row .field_title a {
      color: #18bb49;
      font: 400 24px/56px "PFDinTextCompPro", sans-serif;
      height: 55px;
      text-transform: uppercase;
      text-align: center;
      display: block;
      background: #fff;
      border: 1px solid #e3e2e2;
      text-decoration: none;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      transition: .3s; }
      .block_services .views_row .field_title a:hover {
        color: #fff;
        border-color: transparent;
        background-color: #17bb49; }
  .installation_water .block_services,
  .property_valuation .block_services {
    background: #fff;
    padding: 40px 0 0 0; }

.read_more {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  color: #18bb49;
  border: 2px solid #18bb49;
  font: 500 18px/36px PFDinTextCompPro, sans-serif;
  height: 36px;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  transition: .3s; }
  .read_more a {
    text-decoration: none;
    display: block;
    color: #18bb49;
    padding: 0 20px;
    transition: .3s;
    box-sizing: border-box; }
    .read_more a:hover {
      color: #fff;
      background: #18bb49; }

.block_shops,
.block_vip {
  border-bottom: 1px solid #c4c4c4;
  padding: 33px 0 18px 0;
  background: url(images/bg.png) repeat top center;
  box-sizing: border-box; }
  .block_shops .block_title,
  .block_vip .block_title {
    margin-bottom: 20px; }
  .block_shops .views_row,
  .block_vip .views_row {
    width: 31.5%;
    max-width: 370px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    margin: 0 27px 30px 0;
    transition: .3s; }
    .block_shops .views_row:hover,
    .block_vip .views_row:hover {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); }
    .block_shops .views_row:nth-child(3n+3),
    .block_vip .views_row:nth-child(3n+3) {
      margin-right: 0; }
    .block_shops .views_row .field_image,
    .block_vip .views_row .field_image {
      position: relative; }
      .block_shops .views_row .field_image img,
      .block_vip .views_row .field_image img {
        display: block;
        margin: auto; }
      .block_shops .views_row .field_image .type,
      .block_vip .views_row .field_image .type {
        position: absolute;
        color: #fff;
        background: #d0021b;
        padding: 5px 9px 3px 7px;
        top: 20px;
        z-index: 1;
        left: -10px;
        font: 400 15px 'PFDinTextCompPro', sans-serif;
        line-height: 1;
        text-transform: uppercase;
        box-sizing: border-box; }
        .block_shops .views_row .field_image .type::after,
        .block_vip .views_row .field_image .type::after {
          content: '';
          border: 5px solid transparent;
          border-top: 5px solid #b30218;
          border-right: 5px solid #b30218;
          position: absolute;
          bottom: -10px;
          left: 0; }
    .block_shops .views_row .views_body,
    .block_vip .views_row .views_body {
      position: relative;
      background: #fff;
      float: left;
      width: 100%;
      padding: 26px 19px 20px;
      box-sizing: border-box; }
      .block_shops .views_row .views_body sup,
      .block_vip .views_row .views_body sup {
        display: inline-block;
        vertical-align: top; }
      .block_shops .views_row .views_body .address,
      .block_vip .views_row .views_body .address {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 8px; }
      .block_shops .views_row .views_body .text,
      .block_vip .views_row .views_body .text {
        line-height: 20px;
        margin-bottom: 20px; }
      .block_shops .views_row .views_body .price,
      .block_vip .views_row .views_body .price {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin: 0 0px 15px 0;
        padding-right: 15px;
        float: left;
        width: 50%;
        box-sizing: border-box; }
        .block_shops .views_row .views_body .price .label,
        .block_vip .views_row .views_body .price .label {
          color: #18bb49;
          font: 700 14px Roboto, sans-serif;
          line-height: 20px; }
      .block_shops .views_row .views_body .statistics,
      .block_shops .views_row .views_body .area,
      .block_vip .views_row .views_body .statistics,
      .block_vip .views_row .views_body .area {
        float: left;
        width: 50%;
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        color: #89919e; }
      .block_shops .views_row .views_body .statistics,
      .block_vip .views_row .views_body .statistics {
        margin-bottom: 15px;
        background: url(images/stat.png) no-repeat top left;
        padding-left: 43px;
        float: right;
        width: 42%;
        box-sizing: border-box; }
      .block_shops .views_row .views_body .area,
      .block_vip .views_row .views_body .area {
        clear: both; }
        .block_shops .views_row .views_body .area .label,
        .block_vip .views_row .views_body .area .label {
          color: #3b4047;
          font: 400 14px Roboto, sans-serif;
          line-height: 20px; }
      .block_shops .views_row .views_body .units,
      .block_shops .views_row .views_body .count,
      .block_vip .views_row .views_body .units,
      .block_vip .views_row .views_body .count {
        display: inline-block;
        vertical-align: bottom; }
      .block_shops .views_row .views_body .read_more,
      .block_vip .views_row .views_body .read_more {
        overflow: hidden;
        float: right; }
        .block_shops .views_row .views_body .read_more a,
        .block_vip .views_row .views_body .read_more a {
          padding: 0 13px; }

.block_vip {
  border: none;
  padding-top: 0; }
  .block_vip .views_row .views_body .area {
    float: right;
    clear: none;
    width: 97px;
    color: #3b4047; }
  .block_vip .views_row .views_body .statistics {
    float: left;
    clear: left; }

.star {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: -18px;
  width: 36px;
  cursor: pointer;
  height: 36px;
  background: url(images/favorite.png) no-repeat center/contain; }
  .star.active {
    background-image: url(images/favorite_active.png); }

.new_buildings,
.block_search_results,
.block_vip_shops {
  padding: 38px 0 57px 0;
  margin-bottom: 40px;
  background: url(images/bg.png) repeat top center;
  box-sizing: border-box; }
  .superlending_inner .new_buildings .block_title, .superlending_inner
  .block_search_results .block_title, .superlending_inner
  .block_vip_shops .block_title {
    text-align: left; }
  .new_buildings .block_title,
  .block_search_results .block_title,
  .block_vip_shops .block_title {
    margin-bottom: 15px; }
  .new_buildings .views_row,
  .block_search_results .views_row,
  .block_vip_shops .views_row {
    background: #fff;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    max-width: 570px;
    position: relative;
    background: #fff;
    text-align: left;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    transition: .3s; }
    .new_buildings .views_row:nth-child(even),
    .block_search_results .views_row:nth-child(even),
    .block_vip_shops .views_row:nth-child(even) {
      margin-left: 25px; }
    .new_buildings .views_row:hover,
    .block_search_results .views_row:hover,
    .block_vip_shops .views_row:hover {
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); }
    .new_buildings .views_row.vip_row .title,
    .block_search_results .views_row.vip_row .title,
    .block_vip_shops .views_row.vip_row .title {
      padding-right: 50px; }
    .new_buildings .views_row .title,
    .block_search_results .views_row .title,
    .block_vip_shops .views_row .title {
      padding: 13px 20px;
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      box-sizing: border-box; }
    .new_buildings .views_row .field_image img,
    .block_search_results .views_row .field_image img,
    .block_vip_shops .views_row .field_image img {
      display: block;
      margin: auto; }
    .new_buildings .views_row .left_wrap,
    .new_buildings .views_row .right_wrap,
    .block_search_results .views_row .left_wrap,
    .block_search_results .views_row .right_wrap,
    .block_vip_shops .views_row .left_wrap,
    .block_vip_shops .views_row .right_wrap {
      float: left;
      width: 50%; }
    .new_buildings .views_row .views_body,
    .block_search_results .views_row .views_body,
    .block_vip_shops .views_row .views_body {
      position: relative;
      padding: 26px 19px 20px;
      float: left;
      width: 100%;
      box-sizing: border-box; }
      .new_buildings .views_row .views_body .address,
      .block_search_results .views_row .views_body .address,
      .block_vip_shops .views_row .views_body .address {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 8px; }
      .new_buildings .views_row .views_body .info,
      .block_search_results .views_row .views_body .info,
      .block_vip_shops .views_row .views_body .info {
        margin-bottom: 8px; }
        .new_buildings .views_row .views_body .info > div,
        .block_search_results .views_row .views_body .info > div,
        .block_vip_shops .views_row .views_body .info > div {
          display: inline-block;
          vertical-align: middle;
          margin-right: 3px; }
        .new_buildings .views_row .views_body .info .item,
        .block_search_results .views_row .views_body .info .item,
        .block_vip_shops .views_row .views_body .info .item {
          font-weight: 700; }
      .new_buildings .views_row .views_body .top_wrap .info,
      .block_search_results .views_row .views_body .top_wrap .info,
      .block_vip_shops .views_row .views_body .top_wrap .info {
        display: inline-block;
        vertical-align: top;
        margin: 0 40px 17px 0;
        width: auto;
        float: none;
        padding: 0; }
        .new_buildings .views_row .views_body .top_wrap .info:nth-child(3n+3),
        .block_search_results .views_row .views_body .top_wrap .info:nth-child(3n+3),
        .block_vip_shops .views_row .views_body .top_wrap .info:nth-child(3n+3) {
          margin-right: 0; }
        .new_buildings .views_row .views_body .top_wrap .info .label,
        .block_search_results .views_row .views_body .top_wrap .info .label,
        .block_vip_shops .views_row .views_body .top_wrap .info .label {
          display: block;
          margin: 0 0 10px; }
        .new_buildings .views_row .views_body .top_wrap .info .item,
        .block_search_results .views_row .views_body .top_wrap .info .item,
        .block_vip_shops .views_row .views_body .top_wrap .info .item {
          font: 400 24px 'PFDinTextCompPro', sans-serif; }
      .new_buildings .views_row .views_body .price,
      .block_search_results .views_row .views_body .price,
      .block_vip_shops .views_row .views_body .price {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin: 26px 0px 0 0; }
      .new_buildings .views_row .views_body .bottom_wrap,
      .block_search_results .views_row .views_body .bottom_wrap,
      .block_vip_shops .views_row .views_body .bottom_wrap {
        overflow: hidden; }
    .new_buildings .views_row .statistics,
    .block_search_results .views_row .statistics,
    .block_vip_shops .views_row .statistics {
      margin-bottom: 15px;
      background: url(images/stat.png) no-repeat top left;
      padding-left: 43px;
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      color: #89919e;
      box-sizing: border-box; }
  .new_buildings .read_more,
  .block_search_results .read_more,
  .block_vip_shops .read_more {
    margin-top: 8px; }
  .new_buildings .vip,
  .block_search_results .vip,
  .block_vip_shops .vip {
    background: url(images/vip.png) no-repeat center;
    width: 22px;
    height: 27px;
    position: absolute;
    top: 12px;
    right: 15px; }

.newbuilding .block_search_results {
  padding-top: 20px; }

.ranking .block_search_results .views_row .views_body .top_wrap .info.price {
  padding-left: 10px; }

.articles .block_search_results {
  padding-top: 0;
  text-align: center; }

.new_buildings .view_content {
  text-align: center; }

.apartment .new_buildings {
  padding-bottom: 0;
  margin-bottom: 0; }

.apartment .block_vip_shops .block_title {
  font-size: 36px; }

.apartment .block_services {
  background: #fff;
  padding-top: 26px; }

.apartment .region_banner {
  padding: 0 0 30px; }

.daily_inner .block_vip_shops .block_title {
  text-align: left; }

.daily_inner .region_banner {
  padding: 30px 0 56px; }

.newbuilding_inner .block_vip_shops .block_title {
  font-size: 36px; }

.apartment .block_vip_shops {
  padding-top: 25px; }

.installation_water .region_banner,
.property_valuation .region_banner,
.insurance .region_banner,
.cleaning .region_banner,
.universal .region_banner {
  padding-top: 10px; }

.block_search_results,
.block_vip_shops {
  padding-bottom: 15px;
  margin-bottom: 0;
  border: none; }
  .block_search_results .views_row,
  .block_vip_shops .views_row {
    width: 48%;
    margin-right: 27px !important; }
    .block_search_results .views_row .views_body .statistics,
    .block_vip_shops .views_row .views_body .statistics {
      float: none;
      width: auto;
      display: inline-block;
      vertical-align: middle;
      margin: 0 9px 0 0; }
    .block_search_results .views_row .views_body .read_more,
    .block_vip_shops .views_row .views_body .read_more {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin: 0; }
      .block_search_results .views_row .views_body .read_more a,
      .block_vip_shops .views_row .views_body .read_more a {
        padding: 0 11px; }
      .block_search_results .views_row .views_body .read_more.preview,
      .block_vip_shops .views_row .views_body .read_more.preview {
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        text-align: center;
        color: #03a9f4;
        border: 2px solid #03a9f4;
        font: 500 18px/36px PFDinTextCompPro, sans-serif;
        height: 36px;
        background: #fff;
        overflow: hidden;
        box-sizing: border-box;
        transition: .3s; }
        .block_search_results .views_row .views_body .read_more.preview a,
        .block_vip_shops .views_row .views_body .read_more.preview a {
          text-decoration: none;
          display: block;
          color: #03a9f4;
          padding: 0 20px;
          transition: .3s;
          box-sizing: border-box; }
          .block_search_results .views_row .views_body .read_more.preview a:hover,
          .block_vip_shops .views_row .views_body .read_more.preview a:hover {
            color: #fff;
            background: #03a9f4; }
        .block_search_results .views_row .views_body .read_more.preview a,
        .block_vip_shops .views_row .views_body .read_more.preview a {
          padding: 0 11px; }
    .block_search_results .views_row:nth-child(even),
    .block_vip_shops .views_row:nth-child(even) {
      margin: 0 0 30px !important; }
    .block_search_results .views_row.no_image .title,
    .block_vip_shops .views_row.no_image .title {
      padding-bottom: 33px;
      padding-top: 27px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
    .block_search_results .views_row.no_image .read_more,
    .block_vip_shops .views_row.no_image .read_more {
      float: right; }
    .block_search_results .views_row.no_image .statistics,
    .block_vip_shops .views_row.no_image .statistics {
      margin-top: 6px; }

.load_more_wrap {
  text-align: center; }

.multilending .block_search_results .views_row,
.superlending .block_search_results .views_row {
  width: 370px;
  margin-right: 26px !important; }
  .multilending .block_search_results .views_row:nth-child(3n+3),
  .superlending .block_search_results .views_row:nth-child(3n+3) {
    margin-right: 0 !important; }

.superlending .block_search_results {
  padding-top: 0; }

.multilending .banner {
  text-align: center;
  max-width: 1170px;
  margin: 0 auto 10px; }

.block_vip_shops:not(.block_daily_products) .views_row .views_body .top_wrap .price.info {
  padding-left: 10px;
  box-sizing: border-box; }

.block_vip_shops .view_content .views_row,
.block_daily_products.block_search_results .view_content .views_row {
  width: 370px;
  margin-right: 26px !important; }
  .block_vip_shops .view_content .views_row:nth-child(3n+3),
  .block_daily_products.block_search_results .view_content .views_row:nth-child(3n+3) {
    margin-right: 0 !important; }
  .block_vip_shops .view_content .views_row .views_body .read_more a,
  .block_vip_shops .view_content .views_row .views_body .read_more.preview a,
  .block_daily_products.block_search_results .view_content .views_row .views_body .read_more a,
  .block_daily_products.block_search_results .view_content .views_row .views_body .read_more.preview a {
    padding: 0 8px; }

.favorites .block_daily_products .view_content .views_row {
  width: 370px;
  margin-right: 26px !important; }
  .favorites .block_daily_products .view_content .views_row:nth-child(even) {
    margin-right: 0 !important; }

.block_vip_shops .view_content .views_row .views_body .text {
  margin-bottom: 20px;
  line-height: 20px; }

.block_vip_shops .view_content .views_row .views_body .top_wrap .info.price .label {
  font: 700 14px Roboto, sans-serif;
  color: #18bb49; }

.block_vip_shops .view_content .views_row .views_body .top_wrap .info sup {
  vertical-align: top;
  display: inline-block; }

.block_vip_shops .view_content .views_row .views_body .top_wrap .info .item {
  line-height: 26px; }

.block_vip_shops.block_free .views_row .views_body .top_wrap {
  margin-bottom: 13px; }
  .block_vip_shops.block_free .views_row .views_body .top_wrap .info {
    width: 49%;
    margin-right: 0;
    margin-bottom: 10px; }
    .block_vip_shops.block_free .views_row .views_body .top_wrap .info.price {
      margin-bottom: 5px; }
    .block_vip_shops.block_free .views_row .views_body .top_wrap .info .label {
      font-weight: 700;
      display: inline-block;
      margin-bottom: 0; }
    .block_vip_shops.block_free .views_row .views_body .top_wrap .info .item {
      font: 400 14px Roboto, sans-serif; }

.newbuilding_inner .block_free {
  margin-bottom: 80px;
  padding-top: 0; }

.block.block_daily_products .views_row .views_body .top_wrap .info {
  width: 48%;
  margin: 0 0 17px;
  overflow: hidden; }
  .block.block_daily_products .views_row .views_body .top_wrap .info .label {
    font-weight: 700;
    float: left;
    margin: 0 5px 0 0; }
  .block.block_daily_products .views_row .views_body .top_wrap .info .item {
    font: 400 14px Roboto, sans-serif;
    overflow: hidden;
    vertical-align: top; }

.block.block_daily_products .views_row .read_more a {
  padding: 0 12px !important; }

.search_results {
  background: #fff;
  padding: 19px 20px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  z-index: 1;
  box-sizing: border-box; }
  .not-front .search_results .form_title {
    text-transform: uppercase;
    font: 500 36px 'PFDinTextCompPro', sans-serif; }
  .not-front .search_results form {
    text-align: left;
    padding-top: 20px; }
    .not-front .search_results form .form_actions {
      position: relative;
      width: 100%; }
      .not-front .search_results form .form_actions input {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        color: #fff;
        font: 500 20px "PFDinTextCompPro", sans-serif;
        text-transform: uppercase;
        background-color: #17bb49;
        height: 45px;
        cursor: pointer;
        padding: 0 24px;
        background: #1cc755;
        background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
        background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        transition: opacity .3s;
        padding-left: 41px;
        border: none; }
        .not-front .search_results form .form_actions input:hover {
          opacity: .7; }
      .not-front .search_results form .form_actions::after {
        background: url(images/search_btn.png) no-repeat center left 12px;
        content: '';
        position: absolute;
        top: 34px;
        left: 0;
        width: 32px;
        height: 20px;
        z-index: 1; }
  .not-front .search_results .form_wrapper {
    display: inline-block;
    vertical-align: bottom;
    width: auto; }
  .search_results .top_wrap {
    overflow: hidden;
    margin-bottom: 20px; }
  .search_results .left_wrap {
    float: left; }
    .search_results .left_wrap .count_result {
      font: 400 24px 'PFDinTextCompPro', sans-serif; }
  .search_results .right_wrap {
    float: right; }
    .search_results .right_wrap .sortable label {
      color: #767f8d; }
    .search_results .right_wrap .sortable select {
      color: #3b4047;
      background: url(images/select.png) no-repeat center right;
      border: none;
      outline: none;
      padding: 0 18px 0 0;
      -webkit-appearance: none;
      font-size: 14px;
      line-height: 1em;
      /* for FF */
      -moz-appearance: none;
      text-indent: 0.01px;
      text-overflow: '';
      /* for IE */
      -ms-appearance: none;
      appearance: none !important;
      box-sizing: border-box; }
    .search_results .right_wrap .sortable select::-ms-expand {
      display: none; }
  .search_results .filters {
    float: left;
    max-width: 75.5%;
    margin-right: 9px; }
    .search_results .filters .item {
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #a9d5e8;
      display: inline-block;
      vertical-align: middle;
      height: 36px;
      line-height: 36px;
      padding: 0 37px 0 11px;
      position: relative;
      background: #fff;
      margin-right: 5px;
      box-sizing: border-box; }
      .search_results .filters .item .close {
        background: #fff url(images/filter.png) no-repeat center right 0px;
        content: '';
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute; }
  .search_results .form_actions input {
    text-transform: uppercase;
    text-align: center;
    outline: none;
    display: block;
    color: #18bb49;
    padding: 0 20px;
    background: #fff;
    cursor: pointer;
    height: 36px;
    border: 2px solid #18bb49;
    font: 500 18px/36px PFDinTextCompPro, sans-serif;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
    .search_results .form_actions input:hover {
      background: #18bb49;
      color: #fff; }
  .search_results.search_products {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 30px; }
    .search_results.search_products > div {
      padding: 0 29px; }
    .search_results.search_products .webform_client_form {
      padding: 0 29px 10px;
      margin-bottom: 29px;
      border-bottom: 1px solid rgba(151, 151, 151, 0.3); }
      .search_results.search_products .webform_client_form .form_wrapper {
        margin-right: 30px; }
      .search_results.search_products .webform_client_form .form_select.area_select select {
        width: 189px; }
        .ranking_daily .search_results.search_products .webform_client_form .form_select.area_select select {
          width: 329px; }
      .search_results.search_products .webform_client_form .form_select select {
        width: 229px; }
        .ranking_daily .search_results.search_products .webform_client_form .form_select select {
          width: 148px; }
      .search_results.search_products .webform_client_form .form_actions {
        margin-bottom: 20px;
        width: auto; }
        .search_results.search_products .webform_client_form .form_actions input {
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
        .search_results.search_products .webform_client_form .form_actions .links {
          margin-bottom: 20px;
          margin-top: -20px; }
  .search_results .form_item.metro {
    width: 435px; }
    .search_results .form_item.metro label {
      margin-bottom: 5px;
      display: block; }
    .search_results .form_item.metro input {
      height: 32px;
      box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), inset 0 2px 1px rgba(255, 255, 255, 0.3);
      border: 1px solid #bfc7d1; }

.earth .search_results form .form_actions::after,
.ranking_daily .search_results form .form_actions::after {
  top: 30px; }

.earth .search_results .form_title,
.ranking_daily .search_results .form_title {
  margin: 7px 0 17px; }

.ranking_daily .search_results {
  margin-bottom: 0; }

.form_select.currency,
.form_item.area_wrap .form_select {
  width: 79px;
  display: inline-block;
  vertical-align: middle; }
  .form_select.currency select,
  .form_item.area_wrap .form_select select {
    width: 100% !important; }

.multilending .search_results {
  margin-top: -110px; }
  .multilending .search_results form.webform_client_form .form_item {
    margin: 0 36px 0 0; }
  .multilending .search_results form.webform_client_form .form_actions {
    vertical-align: bottom;
    width: auto; }
    .multilending .search_results form.webform_client_form .form_actions .links,
    .multilending .search_results form.webform_client_form .form_actions input {
      display: inline-block;
      vertical-align: bottom;
      margin-right: 24px; }
    .multilending .search_results form.webform_client_form .form_actions::after {
      top: 12px; }

.superlending .search_results form.webform_client_form .form_checkboxes {
  position: absolute;
  top: 26px;
  left: 343px; }
  .superlending .search_results form.webform_client_form .form_checkboxes .form_checkbox {
    width: auto;
    margin-right: 30px; }

.superlending .search_results form.webform_client_form .form_item {
  margin-right: 20px;
  margin-bottom: 0; }

.superlending .search_results form.webform_client_form .form_item.form_select {
  width: 144px; }
  .superlending .search_results form.webform_client_form .form_item.form_select select {
    width: 100%; }

.superlending .search_results form.webform_client_form .form_actions {
  float: none; }

.superlending .search_results .form_title {
  margin-bottom: 19px; }

.block_presentation {
  background: url(images/bg_2.jpg) no-repeat center/cover;
  padding: 55px 0 85px;
  clear: both;
  margin-bottom: 55px;
  box-sizing: border-box; }
  .not-front .block_presentation {
    margin-bottom: 0; }
    .not-front .block_presentation .block_title {
      margin-bottom: 20px; }
  .superlending .block_presentation:not(.second) .block_title {
    margin-bottom: 56px; }
  .superlending .block_presentation:not(.second) form.webform_client_form {
    max-width: 1170px;
    margin: auto; }
    .superlending .block_presentation:not(.second) form.webform_client_form .form_item {
      margin: 0 8px 0 0 !important; }
  .superlending .block_presentation.second form.webform_client_form .form_actions,
  .superlending .block_presentation.second form.webform_client_form .form_actions input {
    width: 100%; }
  .block_presentation.order_block {
    background-image: url(images/order_bg.jpg);
    padding: 60px 0 90px; }
    .block_presentation.order_block .block_title {
      margin-bottom: 40px; }
    .real_services .block_presentation.order_block {
      margin-bottom: 44px;
      padding-bottom: 90px; }
  .plan .block_presentation {
    padding: 72px 0 70px; }
    .plan .block_presentation .block_title {
      margin-bottom: 42px; }
  .block_presentation .block_title {
    color: #fff;
    margin-bottom: 56px;
    font-weight: 400;
    line-height: 65px; }
  .block_presentation .sub_title {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 40px; }
  .block_presentation .title_text {
    text-align: center;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    color: #fff;
    margin-bottom: 28px;
    font: 500 20px 'PFDinTextCompPro', sans-serif; }
  .block_presentation .present_wrap {
    text-align: center;
    margin-bottom: 83px; }
  .block_presentation .see_present {
    color: #f8dd1c;
    text-transform: uppercase;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    font: 400 36px 'PFDinTextCompPro', sans-serif;
    line-height: 1;
    padding: 15px 46px 15px 103px;
    background: rgba(248, 220, 27, 0.05) url(images/present.png) no-repeat center left 20px;
    border: 2px solid #f8dd1c;
    border-radius: 100px;
    cursor: pointer;
    box-sizing: border-box;
    transition: opacity .3s; }
    .block_presentation .see_present:hover {
      opacity: .7; }
  .block_presentation .views_row {
    text-align: center;
    color: #fff;
    display: inline-block;
    vertical-align: top;
    width: 270px;
    margin: 0 25px 58px 0; }
    .block_presentation .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_presentation .views_row .field_image {
      height: 50px;
      line-height: 50px;
      margin-bottom: 20px; }
      .block_presentation .views_row .field_image img {
        vertical-align: middle; }
    .block_presentation .views_row .title {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      font: 400 24px/30px 'PFDinTextCompPro', sans-serif;
      text-transform: uppercase; }
  .block_presentation .document {
    text-align: center; }
    .block_presentation .document .views_row {
      width: auto;
      max-width: 270px;
      margin: 0 15px 41px;
      padding-top: 58px;
      background: url(images/doc.png) no-repeat top center;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      font: 500 20px/22px 'PFDinTextCompPro', sans-serif; }
      .block_presentation .document .views_row a {
        color: #f5d818; }
      .block_presentation .document .views_row.email_row {
        background: url(images/mail.png) no-repeat top 5px center; }
  .block_presentation .webform_client_form .form_item {
    margin-right: 6px; }
    .block_presentation .webform_client_form .form_item input {
      padding-left: 42px; }
    .block_presentation .webform_client_form .form_item.user_name input {
      background: #fff url(images/user.png) no-repeat center left 10px; }
  .block_presentation .webform_client_form .form_actions input {
    padding: 0 30px; }
  .ranking .block_presentation .webform_client_form .form_actions,
  .ranking .block_presentation .webform_client_form .form_actions input {
    width: 100%; }

.property_valuation .block_presentation .block_title,
.real_services .block_presentation .block_title {
  position: relative;
  padding-bottom: 22px;
  margin-bottom: 23px; }
  .property_valuation .block_presentation .block_title::after,
  .real_services .block_presentation .block_title::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 220px;
    height: 3px;
    margin-left: -110px;
    background: #fff; }

.property_valuation .block_presentation .sub_title,
.real_services .block_presentation .sub_title {
  font-size: 36px;
  margin-bottom: 0; }

.property_valuation .block_presentation form.webform_client_form,
.real_services .block_presentation form.webform_client_form {
  max-width: 739px; }
  .property_valuation .block_presentation form.webform_client_form .form_item,
  .real_services .block_presentation form.webform_client_form .form_item {
    margin-right: 6px !important; }
    .property_valuation .block_presentation form.webform_client_form .form_item.email,
    .real_services .block_presentation form.webform_client_form .form_item.email {
      margin-right: 0 !important; }
    .property_valuation .block_presentation form.webform_client_form .form_item input,
    .real_services .block_presentation form.webform_client_form .form_item input {
      padding-left: 42px; }
    .property_valuation .block_presentation form.webform_client_form .form_item.user_name input,
    .real_services .block_presentation form.webform_client_form .form_item.user_name input {
      background: #fff url(images/user.png) no-repeat center left 10px; }
    .property_valuation .block_presentation form.webform_client_form .form_item.email input,
    .real_services .block_presentation form.webform_client_form .form_item.email input {
      background: #fff url(images/email.png) no-repeat center left 10px; }
  .property_valuation .block_presentation form.webform_client_form .textarea,
  .real_services .block_presentation form.webform_client_form .textarea {
    width: 100%;
    margin-right: 0 !important; }
    .property_valuation .block_presentation form.webform_client_form .textarea.comment textarea,
    .real_services .block_presentation form.webform_client_form .textarea.comment textarea {
      background-image: url(images/comment.png); }
  .property_valuation .block_presentation form.webform_client_form .form_actions input,
  .real_services .block_presentation form.webform_client_form .form_actions input {
    padding: 0 30px; }

.apartment .block_presentation {
  padding-bottom: 55px; }

.real_services .block_presentation {
  padding-bottom: 45px; }
  .real_services .block_presentation form.webform_client_form {
    max-width: 920px; }
    .real_services .block_presentation form.webform_client_form textarea {
      height: 72px; }
    .real_services .block_presentation form.webform_client_form .form_item {
      width: 200px; }
      .real_services .block_presentation form.webform_client_form .form_item.address {
        width: 386px; }
        .real_services .block_presentation form.webform_client_form .form_item.address input {
          background: #fff url(images/address.png) no-repeat center left 15px; }
      .real_services .block_presentation form.webform_client_form .form_item.price {
        width: 104px;
        margin-right: 0 !important; }
        .real_services .block_presentation form.webform_client_form .form_item.price input {
          background: #fff url(images/price.png) no-repeat center left 15px; }

.process .block_presentation {
  padding: 75px 0 59px; }
  .process .block_presentation .block_title {
    margin-bottom: 35px; }

.trust .block_presentation .webform_client_form .form_actions,
.trust .block_presentation .webform_client_form .form_actions input {
  width: 100%; }

.installation_water .block_presentation,
.favorites .block_presentation {
  background-image: url(images/present_1.jpg);
  padding-bottom: 92px; }
  .installation_water .block_presentation form.webform_client_form,
  .favorites .block_presentation form.webform_client_form {
    max-width: 1170px; }
    .installation_water .block_presentation form.webform_client_form .form_item,
    .favorites .block_presentation form.webform_client_form .form_item {
      margin: 0 8px 0 0 !important; }
  .installation_water .block_presentation .block_title,
  .favorites .block_presentation .block_title {
    margin-bottom: 35px; }

.block_earth {
  text-align: center; }
  .block_earth .views_row {
    text-align: left;
    padding: 24px 30px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    margin: 0 0 30px; }
    .block_earth .views_row .title {
      font: 400 36px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 18px; }
    .block_earth .views_row .top_wrap {
      overflow: hidden;
      margin-bottom: 30px; }
    .block_earth .views_row .bottom_wrap {
      overflow: hidden; }
      .block_earth .views_row .bottom_wrap .char_item {
        margin-bottom: 16px; }
        .block_earth .views_row .bottom_wrap .char_item .label {
          font-weight: 700;
          display: inline-block;
          vertical-align: middle; }
        .block_earth .views_row .bottom_wrap .char_item .item {
          display: inline-block;
          vertical-align: middle; }
      .block_earth .views_row .bottom_wrap .left_wrap {
        width: 290px;
        padding-right: 30px;
        box-sizing: border-box; }
      .block_earth .views_row .bottom_wrap .center_wrap {
        overflow: hidden;
        width: 367px;
        float: left; }
        .block_earth .views_row .bottom_wrap .center_wrap .address {
          font-weight: 700;
          padding-left: 26px;
          margin-bottom: 18px;
          min-height: 21px;
          background: url(images/marker.png) no-repeat top left;
          box-sizing: border-box; }
        .block_earth .views_row .bottom_wrap .center_wrap .body {
          line-height: 20px; }
      .block_earth .views_row .bottom_wrap .right_wrap {
        float: right;
        max-width: 350px; }
        .block_earth .views_row .bottom_wrap .right_wrap .info_wrap {
          overflow: hidden;
          margin-bottom: 25px; }
          .block_earth .views_row .bottom_wrap .right_wrap .info_wrap .item {
            width: 50%;
            float: left; }
          .block_earth .views_row .bottom_wrap .right_wrap .info_wrap .label {
            font: 400 24px 'PFDinTextCompPro', sans-serif;
            margin-bottom: 8px; }
          .block_earth .views_row .bottom_wrap .right_wrap .info_wrap .count {
            font: 500 36px 'PFDinTextCompPro', sans-serif; }
        .block_earth .views_row .bottom_wrap .right_wrap .contacts .title {
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 10px; }
        .block_earth .views_row .bottom_wrap .right_wrap .contacts .item {
          margin-bottom: 0;
          font-size: 18px;
          line-height: 37px;
          padding-left: 38px; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .item.field_name {
            background: url(images/name.png) no-repeat center left; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .item.field_phone {
            background: url(images/phone_2.png) no-repeat center left; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .item .label {
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
            font-weight: 700; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .item .field_item {
            display: inline-block;
            vertical-align: middle;
            font-weight: 400; }
        .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn {
          display: inline-block;
          vertical-align: middle;
          text-transform: uppercase;
          text-align: center;
          color: #03a9f4;
          border: 2px solid #03a9f4;
          font: 500 18px/36px PFDinTextCompPro, sans-serif;
          height: 36px;
          background: #fff;
          overflow: hidden;
          box-sizing: border-box;
          transition: .3s;
          float: right;
          cursor: pointer;
          padding: 0 14px;
          margin-top: 14px; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn a {
            text-decoration: none;
            display: block;
            color: #03a9f4;
            padding: 0 20px;
            transition: .3s;
            box-sizing: border-box; }
            .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn a:hover {
              color: #fff;
              background: #03a9f4; }
          .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn:hover {
            color: #fff;
            background: #03a9f4; }
    .block_earth .views_row .left_wrap {
      float: left;
      width: 50%; }
      .block_earth .views_row .left_wrap .map img {
        display: block;
        margin: 0; }
    .block_earth .views_row .right_wrap {
      width: 50%;
      max-width: 542px;
      float: right; }
  .block_earth .slider {
    max-height: 293px;
    overflow: hidden; }
    .block_earth .slider .slick-arrow {
      text-indent: -9999px;
      background: url(images/prev.png) no-repeat center;
      border: none;
      outline: none;
      width: 38px;
      height: 38px;
      position: absolute;
      top: 50%;
      z-index: 10;
      margin-top: -19px;
      cursor: pointer;
      transition: .3s; }
      .block_earth .slider .slick-arrow:hover {
        opacity: .7; }
      .block_earth .slider .slick-arrow.slick-next {
        background: url(images/next.png) no-repeat center;
        right: 0; }
      .block_earth .slider .slick-arrow.slick-prev {
        left: 0; }

.block_appartament_slider {
  background: #fff;
  max-width: 1170px;
  margin: -180px auto 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 30px;
  box-sizing: border-box; }

.slider_for {
  max-height: 624px;
  overflow: hidden;
  margin-bottom: 20px; }
  .slider_for .item {
    display: table;
    width: 100%;
    height: 624px; }
    .slider_for .item .item_inner {
      display: table-cell;
      vertical-align: middle; }
      .slider_for .item .item_inner img {
        max-height: 100%; }
  .slider_for .slick-arrow {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    background: none;
    text-indent: -9999px;
    outline: none;
    border: none;
    z-index: 20;
    transition: .3s; }
    .slider_for .slick-arrow::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 97px;
      height: 100%;
      background-image: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%); }
    .slider_for .slick-arrow::after {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -25px;
      width: 28px;
      height: 50px;
      background: url(images/arrow_next_2.png) no-repeat center;
      display: block; }
    .slider_for .slick-arrow:hover {
      opacity: 1; }
    .slider_for .slick-arrow.slick-prev {
      left: 0; }
      .slider_for .slick-arrow.slick-prev::before {
        right: auto;
        left: 0;
        background-image: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.5) 100%); }
      .slider_for .slick-arrow.slick-prev::after {
        left: 25px;
        transform: rotate(-180deg); }
    .slider_for .slick-arrow.slick-next {
      right: 0;
      background-position: center right 25px; }
      .slider_for .slick-arrow.slick-next::after {
        right: 25px; }

.slider_nav {
  height: 94px;
  margin: 0 -4.5px;
  padding: 0 30px;
  box-sizing: border-box; }
  .slider_nav .item {
    width: 166px;
    height: 94px;
    margin: 0 9px;
    cursor: pointer;
    float: left;
    display: table;
    display: none;
    transition: opacity .3s; }
    .slider_nav .item.first, .slider_nav .item.slick-slide {
      display: table; }
    .slider_nav .item:hover {
      opacity: .7; }
    .slider_nav .item .item_inner {
      display: table-cell;
      vertical-align: middle;
      height: 100%; }
      .slider_nav .item .item_inner img {
        display: block;
        margin: auto; }
  .slider_nav .slick-arrow {
    position: absolute;
    top: 50%;
    background: url(images/arrow_next.png) no-repeat center;
    width: 25px;
    height: 48px;
    margin-top: -24px;
    cursor: pointer;
    text-indent: -9999px;
    outline: none;
    border: none;
    transition: opacity .3s; }
    .slider_nav .slick-arrow:hover {
      opacity: .5; }
    .slider_nav .slick-arrow.slick-prev {
      transform: rotate(-180deg);
      left: -5px; }
    .slider_nav .slick-arrow.slick-next {
      right: -5px; }

.block_inform_appartament {
  background: #fff;
  padding: 23px 30px;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_inform_appartament .block_content {
    overflow: hidden; }
  .block_inform_appartament .left_wrap {
    float: left;
    width: 70%; }
    .newbuilding_inner .block_inform_appartament .left_wrap {
      width: 35%; }
    .block_inform_appartament .left_wrap .price_wrapper {
      margin-bottom: 10px; }
      .block_inform_appartament .left_wrap .price_wrapper div {
        font: 500 36px 'PFDinTextCompPro', sans-serif;
        display: inline-block;
        vertical-align: middle; }
        .block_inform_appartament .left_wrap .price_wrapper div sup {
          display: inline-block;
          vertical-align: top; }
      .block_inform_appartament .left_wrap .price_wrapper span {
        width: 1px;
        height: 49px;
        background: #979797;
        transform: rotate(18deg);
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px; }
    .block_inform_appartament .left_wrap .char_wrapper {
      overflow: hidden; }
      .block_inform_appartament .left_wrap .char_wrapper .group {
        float: left;
        width: 50%;
        padding-right: 30px;
        box-sizing: border-box; }
      .block_inform_appartament .left_wrap .char_wrapper .item {
        margin-bottom: 17px; }
        .block_inform_appartament .left_wrap .char_wrapper .item a {
          color: #1fb239; }
        .block_inform_appartament .left_wrap .char_wrapper .item.list .field_item {
          width: 100%; }
        .block_inform_appartament .left_wrap .char_wrapper .item.list .label {
          margin-bottom: 18px; }
      .block_inform_appartament .left_wrap .char_wrapper .label {
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
        margin-right: 2px; }
      .block_inform_appartament .left_wrap .char_wrapper .field_item {
        display: inline-block;
        vertical-align: middle; }
      .block_inform_appartament .left_wrap .char_wrapper ul {
        list-style: none;
        display: block;
        clear: both; }
        .block_inform_appartament .left_wrap .char_wrapper ul li {
          padding-left: 19px;
          position: relative;
          margin-bottom: 17px; }
          .block_inform_appartament .left_wrap .char_wrapper ul li::after {
            content: '';
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background: #03a9f4;
            position: absolute;
            top: 5px;
            left: 0; }
    .block_inform_appartament .left_wrap.second .title {
      margin-bottom: 32px; }
  .block_inform_appartament .right_wrap {
    width: 29%;
    float: right; }
    .block_inform_appartament .right_wrap .title {
      margin-bottom: 12px; }
    .block_inform_appartament .right_wrap .contacts_person {
      padding-left: 34px;
      overflow: hidden;
      font-size: 18px;
      margin-bottom: 13px;
      min-height: 27px;
      box-sizing: border-box; }
      .block_inform_appartament .right_wrap .contacts_person a {
        color: #3b4047;
        text-decoration: none;
        cursor: default; }
      .block_inform_appartament .right_wrap .contacts_person.name {
        background: url(images/name.png) no-repeat top left; }
      .block_inform_appartament .right_wrap .contacts_person.phone {
        background: url(images/phone_2.png) no-repeat top left; }
      .block_inform_appartament .right_wrap .contacts_person .label {
        float: left;
        font-weight: 700;
        margin-right: 6px; }
      .block_inform_appartament .right_wrap .contacts_person .field_item {
        overflow: hidden; }
  .block_inform_appartament .title {
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 4px; }
  .block_inform_appartament .social_share .label {
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 3px; }

.block_body {
  background: #fff;
  max-width: 1170px;
  padding: 29px 30px 36px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  margin: 0 auto 30px;
  box-sizing: border-box; }
  .block_body .title {
    text-transform: uppercase;
    font: 500 36px 'PFDinTextCompPro', sans-serif;
    float: left;
    width: 345px;
    margin-right: 30px; }
  .block_body .field_body {
    line-height: 20px;
    overflow: hidden; }
  .block_body .left_wrap {
    float: left;
    width: 340px;
    margin-right: 30px; }
    .block_body .left_wrap .title {
      margin-bottom: 5px; }
    .block_body .left_wrap ul {
      list-style: none;
      clear: both; }
      .block_body .left_wrap ul li {
        position: relative;
        line-height: 20px;
        margin-bottom: 23px;
        padding-left: 25px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box; }
        .block_body .left_wrap ul li::after {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          background: #3b4047;
          width: 3px;
          height: 3px;
          border-radius: 100%; }

.block_accommodations {
  background: #fff;
  max-width: 1170px;
  margin: 30px auto 0;
  padding: 28px 30px 0;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_accommodations .block_content {
    overflow: hidden; }
  .block_accommodations .left_wrap,
  .block_accommodations .rigth_wrap {
    float: left;
    width: 50%; }
  .block_accommodations .title {
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 20px; }
  .block_accommodations .accommodations .item {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 20px;
    padding-left: 53px;
    min-height: 48px;
    font-weight: 700;
    line-height: 48px;
    box-sizing: border-box; }
    .block_accommodations .accommodations .item.parking {
      background: url(images/p_1.png) no-repeat center left; }
    .block_accommodations .accommodations .item.studio {
      background: url(images/p_2.png) no-repeat center left; }
    .block_accommodations .accommodations .item.pharmacy {
      background: url(images/p_3.png) no-repeat center left; }
    .block_accommodations .accommodations .item.security {
      background: url(images/p_4.png) no-repeat center left; }
    .block_accommodations .accommodations .item.warehouses {
      background: url(images/p_5.png) no-repeat center left; }
    .block_accommodations .accommodations .item.cafe {
      background: url(images/p_6.png) no-repeat center left; }
    .block_accommodations .accommodations .item.hours {
      background: url(images/p_7.png) no-repeat center left;
      line-height: 24px;
      font-weight: 400;
      width: 45%; }
    .block_accommodations .accommodations .item.video {
      background: url(images/p_8.png) no-repeat center left;
      line-height: 24px;
      font-weight: 400;
      width: 54%; }
  .block_accommodations .char .group {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-bottom: 18px; }
  .block_accommodations .char .item {
    margin-bottom: 15px; }
  .block_accommodations .char .label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px; }
  .block_accommodations .char .field_item {
    font-weight: 700;
    display: inline-block;
    vertical-align: top; }

.region_banner {
  background: #fff;
  padding: 33px 0 60px; }
  .region_banner .field_banner {
    max-width: 1170px;
    margin: auto; }
    .region_banner .field_banner img {
      display: block;
      margin: auto; }
  .process .region_banner {
    padding: 50px 0 67px; }

.not-front .block_presentation .webform_client_form {
  max-width: 490px;
  margin: auto; }
  .not-front .block_presentation .webform_client_form .form_item {
    margin-bottom: 8px; }
    .not-front .block_presentation .webform_client_form .form_item:nth-child(even) {
      margin-right: 0; }

.apartment .block_presentation:not(.block_picking) .webform_client_form,
.daily_inner .block_presentation:not(.block_picking) .webform_client_form {
  max-width: 1170px; }
  .apartment .block_presentation:not(.block_picking) .webform_client_form .form_item,
  .daily_inner .block_presentation:not(.block_picking) .webform_client_form .form_item {
    margin-bottom: 0;
    margin-right: 8px !important; }

.block_presentation.order_block .webform_client_form {
  max-width: 1170px; }
  .block_presentation.order_block .webform_client_form .form_item {
    margin-bottom: 0;
    margin-right: 8px !important; }

.superlending_inner .block_presentation.order_block {
  margin-bottom: 30px; }

.daily_inner .block_presentation:not(.block_picking) {
  margin-bottom: 30px; }

.block_maps {
  margin: 30px auto;
  background: #fff;
  padding: 20px 30px 30px;
  max-width: 1170px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_maps .map {
    display: inline-block;
    vertical-align: top;
    width: 48.7%; }
    .block_maps .map:first-child {
      margin-right: 24px; }
    .block_maps .map .field_image img {
      display: block;
      margin: auto; }
    .block_maps .map .title {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 6px; }
  .newbuilding_inner .block_maps {
    margin-bottom: 45px; }

.block_video {
  padding-bottom: 50px;
  margin-bottom: 43px;
  border-bottom: 2px solid rgba(151, 151, 151, 0.5);
  box-sizing: border-box; }
  .block_video .block_content {
    max-width: 1170px;
    margin: auto; }
  .block_video iframe {
    height: 521px !important;
    display: block; }
  .video_inner .block_video {
    margin-top: -180px;
    padding: 30px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    box-sizing: border-box; }
  .daily_inner .block_video,
  .trust .block_video,
  .superlending_inner .block_video {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none; }
  .plan .block_video {
    border: none;
    padding-bottom: 0;
    margin-bottom: 20px; }
  .real_services .block_video {
    margin-bottom: 40px;
    border: none;
    padding-bottom: 0; }
  .repair_premises .block_video {
    margin-bottom: 40px; }
  .installation_water .block_video,
  .property_valuation .block_video,
  .insurance .block_video,
  .cleaning .block_video,
  .universal .block_video {
    padding-bottom: 0;
    border: none;
    margin-bottom: 60px; }
  .apartment .block_video {
    margin-bottom: 0; }

iframe {
  border: none;
  width: 100% !important; }

.block_tests_wrapper {
  margin-bottom: 30px; }
  .block_tests_wrapper .views_row {
    background: #fff;
    padding: 30px 29px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    box-sizing: border-box; }
    .block_tests_wrapper .views_row .view_row_inner {
      overflow: hidden; }
    .block_tests_wrapper .views_row .left_wrap {
      float: left;
      margin-right: 30px;
      width: 55%; }
      .block_tests_wrapper .views_row .left_wrap .title {
        text-transform: uppercase;
        font: 500 36px/36px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 8px; }
      .block_tests_wrapper .views_row .left_wrap .subtitle {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 13px; }
      .block_tests_wrapper .views_row .left_wrap ul {
        line-height: 20px;
        list-style: none;
        max-width: 466px; }
        .block_tests_wrapper .views_row .left_wrap ul li {
          position: relative;
          padding-left: 37px;
          margin-bottom: 18px; }
          .block_tests_wrapper .views_row .left_wrap ul li::after {
            content: '';
            position: absolute;
            top: 7px;
            left: 11px;
            border-radius: 100%;
            background: #3b4047;
            width: 3px;
            height: 3px; }
      .block_tests_wrapper .views_row .left_wrap .read_more {
        float: left;
        margin-right: 18px;
        display: inline-block;
        vertical-align: middle;
        text-transform: uppercase;
        text-align: center;
        color: #03a9f4;
        border: 2px solid #03a9f4;
        font: 500 18px/36px PFDinTextCompPro, sans-serif;
        height: 36px;
        background: #fff;
        overflow: hidden;
        box-sizing: border-box;
        transition: .3s; }
        .block_tests_wrapper .views_row .left_wrap .read_more a {
          text-decoration: none;
          display: block;
          color: #03a9f4;
          padding: 0 20px;
          transition: .3s;
          box-sizing: border-box; }
          .block_tests_wrapper .views_row .left_wrap .read_more a:hover {
            color: #fff;
            background: #03a9f4; }
      .block_tests_wrapper .views_row .left_wrap .totals {
        font: 400 24px/36px 'PFDinTextCompPro', sans-serif; }
        .block_tests_wrapper .views_row .left_wrap .totals .count {
          display: inline-block;
          vertical-align: middle;
          color: #18bb49;
          margin-left: 5px; }
    .block_tests_wrapper .views_row .right_wrap {
      width: 36.8%;
      float: right; }
    .block_tests_wrapper .views_row .field_image img {
      display: block;
      margin: 0; }
    .block_tests_wrapper .views_row .body {
      line-height: 20px; }
      .block_tests_wrapper .views_row .body p {
        margin-bottom: 18px; }

.block_test_result .views_row .left_wrap {
  width: 350px;
  margin-right: 19px; }
  .block_test_result .views_row .left_wrap .title {
    margin-bottom: 19px; }

.block_test_result .views_row .right_wrap {
  width: auto;
  float: none;
  overflow: hidden; }

.block_test_result .read_more {
  height: 46px; }
  .block_test_result .read_more a {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    color: #fff;
    font: 500 20px "PFDinTextCompPro", sans-serif;
    text-transform: uppercase;
    background-color: #17bb49;
    height: 45px;
    cursor: pointer;
    padding: 0 24px;
    background: #1cc755;
    background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
    background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
    background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
    background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
    background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transition: opacity .3s;
    height: 46px;
    line-height: 46px;
    border: none;
    padding: 0 40px; }
    .block_test_result .read_more a:hover {
      opacity: .7; }

.block_test_passing {
  margin-top: -180px; }
  .block_test_passing .views_row {
    background: #fff;
    padding: 30px 29px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    text-align: center;
    box-sizing: border-box; }
    .block_test_passing .views_row .title {
      text-transform: uppercase;
      margin-bottom: 30px;
      font: 500 36px 'PFDinTextCompPro', sans-serif; }
    .block_test_passing .views_row .webform_client_form {
      text-align: left;
      max-width: 464px; }
      .block_test_passing .views_row .webform_client_form label {
        font-size: 18px; }
        .block_test_passing .views_row .webform_client_form label::after {
          top: 2px; }
      .block_test_passing .views_row .webform_client_form .form_radios {
        margin-bottom: 0; }
  .block_test_passing .webform_client_form {
    max-width: 441px;
    margin: auto;
    text-align: left; }
    .block_test_passing .webform_client_form .form_radio {
      margin-bottom: 27px; }
      .block_test_passing .webform_client_form .form_radio input:checked + label {
        font-weight: 400; }
      .block_test_passing .webform_client_form .form_radio:last-child {
        margin-bottom: 0; }

.block_newbuilding_row .block_content .view_content .views_row {
  margin: 0 0 30px !important;
  width: 100%;
  max-width: 100%;
  padding: 18px 20px;
  box-sizing: border-box; }
  .block_newbuilding_row .block_content .view_content .views_row .title {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0; }
  .block_newbuilding_row .block_content .view_content .views_row .left_wrap {
    width: 469px;
    margin-right: 30px; }
    .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_images {
      position: relative; }
      .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_images .star {
        left: auto;
        right: 17px; }
    .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_image .slick-arrow {
      position: absolute;
      top: 50%;
      background: url(images/next.png) no-repeat center;
      width: 38px;
      margin-top: -19px;
      z-index: 1;
      height: 38px;
      cursor: pointer;
      outline: none;
      border: none;
      text-indent: -99999px;
      overflow: hidden;
      transition: .3s; }
      .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_image .slick-arrow.slick-prev {
        left: 0;
        background-image: url(images/prev.png); }
      .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_image .slick-arrow.slick-next {
        right: 0; }
      .block_newbuilding_row .block_content .view_content .views_row .left_wrap .field_image .slick-arrow:hover {
        opacity: .7; }
  .block_newbuilding_row .block_content .view_content .views_row .right_wrap {
    width: 31%;
    overflow: hidden;
    float: right; }
    .block_newbuilding_row .block_content .view_content .views_row .right_wrap .views_row {
      padding: 0;
      box-shadow: none;
      text-align: center;
      margin-bottom: 21px !important; }
      .block_newbuilding_row .block_content .view_content .views_row .right_wrap .views_row:last-child {
        margin-bottom: 0 !important; }
    .block_newbuilding_row .block_content .view_content .views_row .right_wrap .field_img {
      text-align: center; }
      .block_newbuilding_row .block_content .view_content .views_row .right_wrap .field_img img {
        display: block;
        margin: 0 0 7px; }
    .block_newbuilding_row .block_content .view_content .views_row .right_wrap .see_more a {
      text-align: center;
      display: block;
      color: #18bb49; }
  .block_newbuilding_row .block_content .view_content .views_row .views_body {
    padding: 11px 0; }
    .block_newbuilding_row .block_content .view_content .views_row .views_body .text {
      margin-bottom: 34px; }
  .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap {
    clear: both; }
    .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap {
      width: 35%;
      margin: 0; }
      .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap .info {
        display: inline-block;
        vertical-align: top;
        margin-right: 30px; }
        .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap .info .label {
          margin-bottom: 11px; }
        .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap .info.price .label {
          font-weight: 700;
          color: #18bb49; }
        .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap .info .item {
          font: 400 24px 'PFDinTextCompPro', sans-serif; }
    .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap {
      width: auto;
      float: right;
      margin-top: 10px; }
      .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .statistics {
        display: inline-block;
        vertical-align: middle;
        margin: 0 18px 0 0; }
      .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more {
        margin-top: 0;
        margin-right: 4px; }
        .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview {
          display: inline-block;
          vertical-align: middle;
          text-transform: uppercase;
          text-align: center;
          color: #03a9f4;
          border: 2px solid #03a9f4;
          font: 500 18px/36px PFDinTextCompPro, sans-serif;
          height: 36px;
          background: #fff;
          overflow: hidden;
          box-sizing: border-box;
          transition: .3s; }
          .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview a {
            text-decoration: none;
            display: block;
            color: #03a9f4;
            padding: 0 20px;
            transition: .3s;
            box-sizing: border-box; }
            .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview a:hover {
              color: #fff;
              background: #03a9f4; }
  .block_newbuilding_row .block_content .view_content .views_row .area {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px; }
  .block_newbuilding_row .block_content .view_content .views_row .price {
    color: #d0021b;
    font-weight: 700;
    display: inline-block;
    vertical-align: middle; }

.newbuilding .block_newbuilding_row .block_content .view_content .views_row .price {
  color: #3b4047; }

.block_process .block_title {
  text-align: left;
  font-size: 36px;
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle; }

.block_process .create {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  color: #03a9f4;
  border: 2px solid #03a9f4;
  font: 500 18px/36px PFDinTextCompPro, sans-serif;
  height: 36px;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  transition: .3s;
  float: right;
  margin-bottom: 10px; }
  .block_process .create a {
    text-decoration: none;
    display: block;
    color: #03a9f4;
    padding: 0 20px;
    transition: .3s;
    box-sizing: border-box; }
    .block_process .create a:hover {
      color: #fff;
      background: #03a9f4; }

.block_process .views_row {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 0 20px;
  margin-bottom: 30px;
  box-sizing: border-box; }
  .block_process .views_row .left_wrap {
    float: left;
    padding: 17px 0;
    margin-right: 20px; }
    .block_process .views_row .left_wrap .read_more {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      text-align: center;
      color: #03a9f4;
      border: 2px solid #03a9f4;
      font: 500 18px/36px PFDinTextCompPro, sans-serif;
      height: 36px;
      background: #fff;
      overflow: hidden;
      box-sizing: border-box;
      transition: .3s; }
      .block_process .views_row .left_wrap .read_more a {
        text-decoration: none;
        display: block;
        color: #03a9f4;
        padding: 0 20px;
        transition: .3s;
        box-sizing: border-box; }
        .block_process .views_row .left_wrap .read_more a:hover {
          color: #fff;
          background: #03a9f4; }
  .block_process .views_row .right_wrap {
    border-left: 1px solid rgba(151, 151, 151, 0.2);
    overflow: hidden;
    padding: 17px 0 20px 20px;
    box-sizing: border-box; }
    .block_process .views_row .right_wrap .title {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 15px; }
    .block_process .views_row .right_wrap .text {
      line-height: 20px;
      margin-bottom: 42px; }
    .block_process .views_row .right_wrap .webform_client_form {
      text-align: left; }
      .block_process .views_row .right_wrap .webform_client_form label {
        margin-bottom: 4px;
        display: block; }
      .block_process .views_row .right_wrap .webform_client_form .form_textarea {
        float: left;
        width: 390px;
        margin-right: 30px !important; }
        .block_process .views_row .right_wrap .webform_client_form .form_textarea textarea {
          border: 1px solid #bec7d1;
          height: 175px;
          padding-left: 20px;
          padding-top: 15px;
          background: #fff;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
          box-sizing: border-box; }
      .block_process .views_row .right_wrap .webform_client_form .form_item {
        width: 100%; }
        .block_process .views_row .right_wrap .webform_client_form .form_item input {
          border: 1px solid #bec7d1;
          height: 32px;
          box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
          box-sizing: border-box; }
      .block_process .views_row .right_wrap .webform_client_form .group {
        overflow: hidden; }
        .block_process .views_row .right_wrap .webform_client_form .group .form_item {
          margin-bottom: 20px; }
          .block_process .views_row .right_wrap .webform_client_form .group .form_item:last-child {
            margin-bottom: 0; }

form .status .status_wrap {
  position: relative; }
  form .status .status_wrap span {
    position: absolute;
    top: 7px;
    left: 9px;
    width: 16px;
    height: 16px;
    background: #000;
    border-radius: 100%;
    z-index: 1; }
  form .status .status_wrap.red span {
    background: #ff1919; }

form .status select {
  position: relative;
  padding-left: 30px;
  height: 32px; }

form .status option {
  position: relative; }

body.process .block_banner {
  text-align: center;
  margin-bottom: 32px; }

.block_process.during .block_content .read_more {
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  text-align: center;
  color: #18bb49;
  border: 2px solid #18bb49;
  font: 500 18px/36px PFDinTextCompPro, sans-serif;
  height: 36px;
  background: #fff;
  overflow: hidden;
  box-sizing: border-box;
  transition: .3s; }
  .block_process.during .block_content .read_more a {
    text-decoration: none;
    display: block;
    color: #18bb49;
    padding: 0 20px;
    transition: .3s;
    box-sizing: border-box; }
    .block_process.during .block_content .read_more a:hover {
      color: #fff;
      background: #18bb49; }

.block_services_wrap .views_row {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  height: 247px;
  box-sizing: border-box; }
  .block_services_wrap .views_row .views_inner {
    overflow: hidden; }
  .block_services_wrap .views_row .left_wrap {
    float: left;
    width: 50%; }
  .block_services_wrap .views_row .right_wrap {
    float: right;
    width: 50%; }
  .block_services_wrap .views_row .field_image a {
    transition: .3s; }
    .block_services_wrap .views_row .field_image a:hover {
      opacity: .7; }
  .block_services_wrap .views_row .title {
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 20px;
    font: 500 36px 'PFDinTextCompPro', sans-serif; }
    .block_services_wrap .views_row .title::after {
      content: '';
      width: 114px;
      height: 1px;
      background: rgba(151, 151, 151, 0.5);
      position: absolute;
      left: 0;
      bottom: 0; }
  .block_services_wrap .views_row .text {
    font: 400 24px 'PFDinTextCompPro', sans-serif; }
  .block_services_wrap .views_row.odd .left_wrap {
    padding: 29px;
    box-sizing: border-box; }
  .block_services_wrap .views_row.even .right_wrap {
    padding: 29px;
    box-sizing: border-box; }

.social_likes_wrapper .label {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
  font: 400 24px/36px 'PFDinTextCompPro', sans-serif;
  margin-bottom: 2px; }

.articles_inner .social_likes_wrapper .label {
  font-size: 20px; }

.social-likes {
  margin: 0 0 -80px; }

.social-likes_vertical .social-likes__widget {
  clear: none;
  min-width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 0 15px;
  text-indent: -999999px; }
  .social-likes_vertical .social-likes__widget span {
    display: none; }
  .articles_inner .social-likes_vertical .social-likes__widget {
    margin: 0 10px; }

.social-likes__widget_facebook {
  background: url(images/fb_soc.png) no-repeat center/contain; }

.social-likes__widget_twitter {
  background: url(images/tw_soc.png) no-repeat center/contain; }

.social-likes__widget_vkontakte {
  background: url(images/vk_soc.png) no-repeat center/contain; }

.social-likes__widget_mailru {
  background: url(images/mr.png) no-repeat center/contain; }

.social-likes__widget_plusone {
  background: url(images/plusone.png) no-repeat center/contain; }

.block_work_client {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  padding: 30px;
  margin-bottom: 40px;
  box-sizing: border-box; }
  .block_work_client .title {
    text-transform: uppercase;
    font: 500 36px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 25px; }
  .block_work_client .views_row {
    display: inline-block;
    vertical-align: top;
    width: 24%; }
    .block_work_client .views_row .label {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 5px; }
    .block_work_client .views_row a {
      text-decoration: none; }

.block_contacts_specialists .views_row {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 30px;
  margin-bottom: 30px;
  box-sizing: border-box; }
  .block_contacts_specialists .views_row .views_row_inner {
    overflow: hidden; }
  .block_contacts_specialists .views_row .left_wrap {
    float: left;
    margin-right: 28px; }
  .block_contacts_specialists .views_row .right_wrap {
    overflow: hidden; }
    .block_contacts_specialists .views_row .right_wrap .name {
      text-transform: uppercase;
      margin-bottom: 23px;
      font: 500 36px 'PFDinTextCompPro', sans-serif; }
    .block_contacts_specialists .views_row .right_wrap .about_block .field_item {
      display: inline-block;
      vertical-align: top;
      width: 24%;
      padding-right: 15px;
      margin-bottom: 26px;
      box-sizing: border-box; }
      .block_contacts_specialists .views_row .right_wrap .about_block .field_item .label {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 8px; }
    .block_contacts_specialists .views_row .right_wrap .body_wrap {
      line-height: 20px;
      margin-bottom: 16px; }
    .block_contacts_specialists .views_row .right_wrap .video {
      text-align: center;
      max-width: 595px;
      margin: auto; }
      .block_contacts_specialists .views_row .right_wrap .video .title {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 15px; }

.block_benefits {
  text-align: center;
  padding-bottom: 48px;
  box-sizing: border-box; }
  .block_benefits .block_title {
    margin-bottom: 40px; }
  .block_benefits .views_row {
    display: inline-block;
    vertical-align: top;
    width: 19.7%; }
    .repair_premises .block_benefits .views_row,
    .installation_water .block_benefits .views_row {
      width: 24%; }
    .block_benefits .views_row .field_img {
      line-height: 104px;
      height: 104px;
      margin-bottom: 25px; }
      .block_benefits .views_row .field_img img {
        vertical-align: middle; }
    .block_benefits .views_row .text {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      padding: 0 25px;
      line-height: 1; }
  .superlending .block_benefits {
    padding-bottom: 12px;
    padding-top: 28px; }
    .superlending .block_benefits .block_title {
      margin-bottom: 30px; }
    .superlending .block_benefits .views_row {
      width: 24%; }
      .superlending .block_benefits .views_row .field_img {
        line-height: 120px;
        height: 120px; }
  .installation_water .block_benefits .field_img {
    line-height: 120px;
    margin-bottom: 15px;
    height: 120px; }
  .block_benefits.steps {
    padding: 50px 0 35px;
    box-sizing: border-box; }
    .block_benefits.steps .block_title {
      margin-bottom: 40px; }
    .block_benefits.steps .views_row {
      width: 33%;
      position: relative; }
      .block_benefits.steps .views_row::after {
        content: '';
        position: absolute;
        width: 188px;
        height: 36px;
        top: 34px;
        right: -80px;
        background: url(images/arrow.png) no-repeat center/contain; }
      .block_benefits.steps .views_row:last-child::after {
        content: none; }
      .block_benefits.steps .views_row .text {
        max-width: 215px;
        padding: 0;
        margin: auto; }

.block_benefits.steps.second .group:last-child .views_row {
  width: 24%; }
  .block_benefits.steps.second .group:last-child .views_row::after {
    width: 103px;
    top: 127px !important;
    right: -65px;
    background-position: center right;
    background-size: auto; }
  .block_benefits.steps.second .group:last-child .views_row .text {
    height: 76px; }

.real_services .block_benefits:not(.steps) {
  padding-bottom: 0; }
  .real_services .block_benefits:not(.steps) .views_row {
    width: 24%;
    margin-bottom: 68px; }

.real_services .block_benefits.steps .group,
.property_valuation .block_benefits.steps .group {
  padding-bottom: 50px;
  margin-bottom: 46px;
  position: relative; }
  .real_services .block_benefits.steps .group:first-child,
  .property_valuation .block_benefits.steps .group:first-child {
    background: url(images/line_1.png) no-repeat bottom right; }
  .real_services .block_benefits.steps .group:last-child .field_img,
  .property_valuation .block_benefits.steps .group:last-child .field_img {
    margin-bottom: 0; }
  .real_services .block_benefits.steps .group:last-child .text,
  .property_valuation .block_benefits.steps .group:last-child .text {
    margin-bottom: 25px; }
  .real_services .block_benefits.steps .group:last-child .views_row::after,
  .property_valuation .block_benefits.steps .group:last-child .views_row::after {
    top: 110px; }
  .real_services .block_benefits.steps .group:last-child .views_row:first-child::before,
  .property_valuation .block_benefits.steps .group:last-child .views_row:first-child::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 36px;
    top: 110px;
    left: -80px;
    background: url(images/arrow.png) no-repeat top right; }

.real_services .block_benefits.steps.second,
.property_valuation .block_benefits.steps.second {
  background: url(images/line_2.png) no-repeat top 237px center; }
  .real_services .block_benefits.steps.second .group:first-child,
  .property_valuation .block_benefits.steps.second .group:first-child {
    background: none; }
  .real_services .block_benefits.steps.second .group:last-child .views_row:first-child::before,
  .property_valuation .block_benefits.steps.second .group:last-child .views_row:first-child::before {
    content: none; }

.block_numbers {
  background: #fff;
  text-align: center;
  padding: 50px 0 20px 0;
  margin-bottom: 40px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_numbers .block_title {
    margin-bottom: 40px; }
  .block_numbers .views_row {
    width: 22%;
    text-align: center;
    margin: 0 15px 63px;
    display: inline-block;
    vertical-align: top; }
    .block_numbers .views_row .count {
      color: #03a9f4;
      font: 400 72px/72px 'PFDinTextCompPro', sans-serif;
      position: relative;
      margin-bottom: 11px; }
      .block_numbers .views_row .count::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -60px;
        background: #03a9f4;
        height: 1px;
        width: 120px; }
    .block_numbers .views_row .text {
      font: 400 24px 'PFDinTextCompPro', sans-serif; }

.block_diplomas {
  padding: 50px 0 20px 0;
  margin-bottom: 40px;
  box-sizing: border-box; }
  .trust .block_diplomas {
    padding-bottom: 0px;
    margin-bottom: 10px; }
  .block_diplomas .views_row {
    width: 32%;
    max-width: 370px;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    margin: 0 25px 30px 0;
    background: #fff;
    line-height: 269px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
    .block_diplomas .views_row:nth-child(3n+3) {
      margin-right: 0; }
    .block_diplomas .views_row img {
      vertical-align: middle; }

.block_reviews .slider_video {
  margin: 0 -20px 30px; }
  .block_reviews .slider_video .views_row {
    width: 50%;
    max-width: 566px;
    float: left;
    height: 368px;
    margin: 0 20px;
    display: none; }
    .block_reviews .slider_video .views_row.slick-slide, .block_reviews .slider_video .views_row:first-child {
      display: block; }
  .block_reviews .slider_video .slick-arrow {
    text-indent: -99999px;
    position: absolute;
    overflow: hidden;
    border: none;
    background: none;
    outline: none;
    width: 25px;
    height: 48px;
    top: 50%;
    cursor: pointer;
    margin-top: -24px;
    background: url(images/arrow_next.png) no-repeat center/contain;
    transition: opacity .3s; }
    .block_reviews .slider_video .slick-arrow:hover {
      opacity: .7; }
    .block_reviews .slider_video .slick-arrow.slick-prev {
      left: -35px;
      transform: rotate(-180deg); }
    .block_reviews .slider_video .slick-arrow.slick-next {
      right: -35px; }

.block_reviews .reviews_text {
  padding-bottom: 10px; }
  .block_reviews .reviews_text .view_row {
    width: 370px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    box-sizing: border-box; }
    .block_reviews .reviews_text .view_row .field_image {
      margin-bottom: 15px; }
      .block_reviews .reviews_text .view_row .field_image img {
        display: block;
        margin: auto; }
    .block_reviews .reviews_text .view_row .name {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 10px; }
    .block_reviews .reviews_text .view_row .city {
      color: #89919e;
      margin-bottom: 13px; }
    .block_reviews .reviews_text .view_row .views_body {
      padding: 0 19px 26px;
      box-sizing: border-box; }
    .block_reviews .reviews_text .view_row .text {
      line-height: 20px; }

.block_questions {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: #fff;
  padding: 38px 0 40px;
  box-sizing: border-box; }
  .block_questions .views_row {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    border: 1px solid #e3e2e2;
    padding: 15px;
    background: #fbfbfb;
    box-sizing: border-box;
    transition: .3s; }
    .block_questions .views_row .qty_text {
      font: 400 24px/46px 'PFDinTextCompPro', sans-serif;
      position: relative;
      padding-left: 54px;
      padding-right: 70px;
      cursor: pointer;
      background: url(images/qty.png) no-repeat top left;
      box-sizing: border-box;
      transition: .3s; }
      .block_questions .views_row .qty_text .item {
        width: 70%; }
      .block_questions .views_row .qty_text span {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 10px;
        right: 0px;
        cursor: pointer;
        background: url(images/max.png) no-repeat center/contain; }
    .block_questions .views_row.active {
      background: #fff;
      position: relative;
      z-index: 10;
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15); }
      .block_questions .views_row.active .qty_text {
        border-color: transparent;
        margin-bottom: 15px; }
        .block_questions .views_row.active .qty_text span {
          background-image: url(images/min.png); }
      .block_questions .views_row.active .body {
        display: block; }
      .block_questions .views_row.active + .views_row .qty_text {
        border-top-color: transparent; }
    .block_questions .views_row .body {
      display: none;
      padding-left: 55px;
      line-height: 20px;
      box-sizing: border-box; }

.block_price_wrapper {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 40px 0 50px;
  margin-bottom: 30px;
  box-sizing: border-box; }
  .block_price_wrapper .thead {
    overflow: hidden;
    line-height: 20px;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
    padding-bottom: 13px;
    box-sizing: border-box; }
    .block_price_wrapper .thead .left {
      float: left; }
    .block_price_wrapper .thead .right {
      float: right; }
  .block_price_wrapper .tbody .views_row {
    padding: 15px 0;
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    overflow: hidden;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
    box-sizing: border-box; }
    .block_price_wrapper .tbody .views_row .left {
      float: left;
      width: 65%; }
    .block_price_wrapper .tbody .views_row .right {
      float: right;
      width: 200px;
      text-align: right; }
    .block_price_wrapper .tbody .views_row:last-child {
      border-bottom: 0; }
  .block_price_wrapper .calculate_form {
    text-align: center; }
    .block_price_wrapper .calculate_form .title {
      text-transform: uppercase;
      font: 500 36px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 10px; }
    .block_price_wrapper .calculate_form .subtitle {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 12px; }
    .block_price_wrapper .calculate_form .form_actions a {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #fff;
      font: 500 20px "PFDinTextCompPro", sans-serif;
      text-transform: uppercase;
      background-color: #17bb49;
      height: 45px;
      cursor: pointer;
      padding: 0 24px;
      background: #1cc755;
      background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
      background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      transition: opacity .3s;
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 46px; }
      .block_price_wrapper .calculate_form .form_actions a:hover {
        opacity: .7; }

.block_our_works {
  margin-bottom: 60px; }
  .block_our_works .views_row {
    display: inline-block;
    vertical-align: top;
    margin: 0 25px 30px 0; }
    .block_our_works .views_row:nth-child(3n+3) {
      margin-right: 0; }
    .block_our_works .views_row img {
      display: block;
      margin: auto; }
  .block_our_works .footnote {
    text-align: center;
    font: 400 24px 'PFDinTextCompPro', sans-serif; }

.region_map img {
  display: block;
  margin: auto; }

.block_ready {
  padding-bottom: 40px;
  box-sizing: border-box; }
  .block_ready .view_content {
    text-align: center; }
    .block_ready .view_content > div {
      display: inline-block;
      vertical-align: middle;
      margin: 0 11px; }
    .block_ready .view_content .submit a {
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
      color: #fff;
      font: 500 20px "PFDinTextCompPro", sans-serif;
      text-transform: uppercase;
      background-color: #17bb49;
      height: 45px;
      cursor: pointer;
      padding: 0 24px;
      background: #1cc755;
      background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
      background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
      background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
      transition: opacity .3s;
      text-decoration: none;
      display: inline-block;
      vertical-align: middle;
      line-height: 46px; }
      .block_ready .view_content .submit a:hover {
        opacity: .7; }
    .block_ready .view_content .order {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      text-align: center;
      color: #03a9f4;
      border: 2px solid #03a9f4;
      font: 500 18px/36px PFDinTextCompPro, sans-serif;
      height: 36px;
      background: #fff;
      overflow: hidden;
      box-sizing: border-box;
      transition: .3s; }
      .block_ready .view_content .order a {
        text-decoration: none;
        display: block;
        color: #03a9f4;
        padding: 0 20px;
        transition: .3s;
        box-sizing: border-box; }
        .block_ready .view_content .order a:hover {
          color: #fff;
          background: #03a9f4; }

.block_hydrometers {
  padding: 45px 0 32px;
  box-sizing: border-box; }
  .block_hydrometers .views_row {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    margin: 0 26px 30px 0; }
    .block_hydrometers .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_hydrometers .views_row .field_img {
      margin-bottom: 13px; }
      .block_hydrometers .views_row .field_img img {
        display: block;
        margin: auto; }
    .block_hydrometers .views_row .title {
      text-align: center;
      font: 400 24px 'PFDinTextCompPro', sans-serif; }

.block_photo {
  text-align: center;
  margin-bottom: 27px; }
  .block_photo .view_content {
    margin: 0 -13px; }
    .block_photo .view_content .views_row {
      display: inline-block;
      vertical-align: top;
      margin: 0 13px 30px; }
      .block_photo .view_content .views_row .filed_img img {
        display: block;
        margin: auto; }
  .universal .block_photo {
    margin-bottom: 0; }

.block_package .views_row {
  display: inline-block;
  vertical-align: top;
  max-width: 370px;
  width: 100%;
  padding-bottom: 30px;
  margin: 0 26px 30px 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_package .views_row:nth-child(3n+3) {
    margin-right: 0; }
  .block_package .views_row .title {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    height: 74px;
    font: 400 36px/74px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 29px; }
  .block_package .views_row.beginner .title {
    background: #03baf7;
    background: -moz-linear-gradient(top, #03baf7 0%, #0090ec 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #03baf7), color-stop(100%, #0090ec));
    background: -webkit-linear-gradient(top, #03baf7 0%, #0090ec 100%);
    background: -o-linear-gradient(top, #03baf7 0%, #0090ec 100%);
    background: -ms-linear-gradient(top, #03baf7 0%, #0090ec 100%);
    background: linear-gradient(to bottom, #03baf7 0%, #0090ec 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#03baf7', endColorstr='#0090ec', GradientType=0 ); }
  .block_package .views_row.advanced .title {
    background: #20ce5d;
    background: -moz-linear-gradient(top, #20ce5d 0%, #0ea531 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #20ce5d), color-stop(100%, #0ea531));
    background: -webkit-linear-gradient(top, #20ce5d 0%, #0ea531 100%);
    background: -o-linear-gradient(top, #20ce5d 0%, #0ea531 100%);
    background: -ms-linear-gradient(top, #20ce5d 0%, #0ea531 100%);
    background: linear-gradient(to bottom, #20ce5d 0%, #0ea531 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#20ce5d', endColorstr='#0ea531', GradientType=0 ); }
  .block_package .views_row.expert .title {
    background: #f8bd2f;
    background: -moz-linear-gradient(top, #f8bd2f 0%, #f18d16 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, #f8bd2f), color-stop(100%, #f18d16));
    background: -webkit-linear-gradient(top, #f8bd2f 0%, #f18d16 100%);
    background: -o-linear-gradient(top, #f8bd2f 0%, #f18d16 100%);
    background: -ms-linear-gradient(top, #f8bd2f 0%, #f18d16 100%);
    background: linear-gradient(to bottom, #f8bd2f 0%, #f18d16 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8bd2f', endColorstr='#f18d16', GradientType=0 ); }
  .block_package .views_row .views_body {
    line-height: 20px;
    padding: 0 17px 0 28px;
    box-sizing: border-box; }
    .block_package .views_row .views_body ul {
      list-style: none; }
      .block_package .views_row .views_body ul li {
        position: relative;
        padding-left: 24px;
        margin-bottom: 23px;
        box-sizing: border-box; }
        .block_package .views_row .views_body ul li::after {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 3px;
          height: 3px;
          border-radius: 100%;
          background: #3b4047; }
    .block_package .views_row .views_body .submit {
      text-align: center; }
      .block_package .views_row .views_body .submit a {
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        color: #fff;
        font: 500 20px "PFDinTextCompPro", sans-serif;
        text-transform: uppercase;
        background-color: #17bb49;
        height: 45px;
        cursor: pointer;
        padding: 0 24px;
        background: #1cc755;
        background: -moz-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -webkit-gradient(left top, left bottom, color-stop(0%, #1cc755), color-stop(100%, #13ac39));
        background: -webkit-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -o-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: -ms-linear-gradient(top, #1cc755 0%, #13ac39 100%);
        background: linear-gradient(to bottom, #1cc755 0%, #13ac39 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1cc755', endColorstr='#13ac39', GradientType=0 );
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        transition: opacity .3s;
        display: inline-block;
        vertical-align: middle;
        margin: auto;
        line-height: 46px;
        text-align: center;
        text-decoration: none; }
        .block_package .views_row .views_body .submit a:hover {
          opacity: .7; }

.block_our_advantages {
  padding-top: 45px;
  text-align: center; }
  .insurance .block_our_advantages {
    margin-bottom: 20px; }
    .insurance .block_our_advantages .block_title {
      margin-bottom: 45px; }
    .insurance .block_our_advantages .field_image {
      height: 105px; }
  .block_our_advantages .views_row {
    display: inline-block;
    vertical-align: top;
    width: 370px;
    margin: 0 26px 30px 0; }
    .block_our_advantages .views_row:nth-child(3n+3) {
      margin-right: 0; }
    .block_our_advantages .views_row .field_image {
      margin-bottom: 13px; }
      .block_our_advantages .views_row .field_image img {
        display: block;
        margin: auto; }
    .block_our_advantages .views_row .title {
      position: relative;
      font: 400 36px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 10px;
      padding-bottom: 7px; }
      .block_our_advantages .views_row .title::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -58px;
        width: 116px;
        height: 3px;
        background: #17bb49; }
    .block_our_advantages .views_row .subtitle {
      font: 400 24px 'PFDinTextCompPro', sans-serif; }

.block_compensate {
  text-align: center; }
  .block_compensate .views_row {
    display: inline-block;
    vertical-align: top;
    margin: 0 26px 30px 0; }
    .block_compensate .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_compensate .views_row .field_image {
      margin-bottom: 13px; }
      .block_compensate .views_row .field_image img {
        margin: auto;
        display: block; }
    .block_compensate .views_row .title {
      font: 400 24px/26px 'PFDinTextCompPro', sans-serif; }

.block_should_use {
  text-align: center; }
  .block_should_use .block_title {
    margin-bottom: 0;
    line-height: 1.1; }
  .block_should_use .subtitle {
    font: 400 36px 'PFDinTextCompPro', sans-serif;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 12px; }
    .block_should_use .subtitle::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 3px;
      margin-left: -79.5px;
      width: 159px;
      background: #17bb49; }
  .block_should_use .title_text {
    font: 400 24px 'PFDinTextCompPro', sans-serif;
    margin-bottom: 21px; }
  .block_should_use .views_row {
    display: inline-block;
    vertical-align: top;
    width: 270px;
    margin: 0 26px 35px 0; }
    .block_should_use .views_row:last-child {
      margin-right: 0; }
    .block_should_use .views_row .field_image {
      position: relative;
      margin-bottom: 45px; }
      .block_should_use .views_row .field_image > img {
        display: block;
        margin: auto; }
      .block_should_use .views_row .field_image .icon {
        position: absolute;
        bottom: -35.5px;
        left: 50%;
        width: 71px;
        height: 71px;
        margin-left: -35.5px;
        line-height: 71px;
        border-radius: 100%;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        background: #ffffff;
        box-sizing: border-box; }
        .block_should_use .views_row .field_image .icon img {
          vertical-align: middle; }
    .block_should_use .views_row .text {
      line-height: 20px; }

.block_we_have {
  background: url(images/bg_3.jpg) no-repeat top center/cover;
  color: #fff;
  padding: 54px 0 64px;
  margin-bottom: 42px;
  box-sizing: border-box; }
  .block_we_have .block_title {
    font-size: 64px;
    font-weight: 400;
    position: relative;
    margin-bottom: 58px;
    padding-bottom: 24px;
    box-sizing: border-box; }
    .block_we_have .block_title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      height: 3px;
      background: #fff;
      width: 220px;
      margin-left: -110px; }
  .block_we_have .group {
    display: inline-block;
    vertical-align: top;
    width: 48%;
    margin-bottom: 35px; }
    .block_we_have .group ul {
      list-style: none; }
      .block_we_have .group ul li {
        position: relative;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
        font: 500 20px/34px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 24px;
        min-height: 32px;
        padding-left: 43px;
        box-sizing: border-box; }
        .block_we_have .group ul li::after {
          width: 32px;
          height: 32px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          background: url(images/check.png) no-repeat center; }
  .block_we_have .bottom_wrap .title {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
    text-align: center;
    font: 400 36px 'PFDinTextCompPro', sans-serif;
    text-transform: uppercase;
    margin-bottom: 38px; }
  .block_we_have .bottom_wrap .webform_client_form .form_item {
    margin-right: 6px; }
    .block_we_have .bottom_wrap .webform_client_form .form_item.name input {
      background-image: url(images/user.png); }

.block_advantages_1 {
  text-align: center;
  padding: 13px 0 35px 0;
  box-sizing: border-box; }
  .block_advantages_1 .views_row {
    display: inline-block;
    vertical-align: top;
    margin: 0 26px 30px 0; }
    .block_advantages_1 .views_row:nth-child(4n+4) {
      margin-right: 0; }
    .block_advantages_1 .views_row .field_image {
      margin-bottom: 15px; }
      .block_advantages_1 .views_row .field_image img {
        display: block;
        margin: auto; }
    .block_advantages_1 .views_row .title {
      text-transform: uppercase;
      font: 400 24px/26px 'PFDinTextCompPro', sans-serif; }

.block_node_content {
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  padding: 25px 30px 30px;
  box-sizing: border-box; }
  .block_node_content .field_body {
    line-height: 20px; }
    .block_node_content .field_body span {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      display: block;
      margin-bottom: 16px; }
    .block_node_content .field_body img.left {
      float: left;
      margin: 0 25px 17px 0; }
    .block_node_content .field_body iframe {
      margin-bottom: 20px; }
    .block_node_content .field_body h2 {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 17px; }
    .block_node_content .field_body p:last-child {
      margin-bottom: 0; }

.articles_inner #content {
  margin-bottom: 37px; }

.block_video_transfer {
  text-align: center;
  margin-bottom: 24px; }
  .block_video_transfer .views_row {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
    background: #fff;
    margin-bottom: 30px;
    text-align: left;
    padding: 17px 30px 30px;
    box-sizing: border-box; }
    .block_video_transfer .views_row .views_row_inner {
      overflow: hidden; }
    .block_video_transfer .views_row .title {
      font: 400 24px 'PFDinTextCompPro', sans-serif;
      margin-bottom: 12px; }
    .block_video_transfer .views_row .field_image {
      float: left;
      margin-right: 20px; }
      .block_video_transfer .views_row .field_image img {
        display: block;
        margin: auto; }
    .block_video_transfer .views_row .field_nid {
      overflow: hidden;
      margin-bottom: 3px; }
    .block_video_transfer .views_row ul {
      list-style: none; }
      .block_video_transfer .views_row ul li {
        position: relative;
        line-height: 20px;
        margin-bottom: 23px;
        padding-left: 25px;
        box-sizing: border-box; }
        .block_video_transfer .views_row ul li::after {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          background: #3b4047;
          width: 3px;
          height: 3px;
          border-radius: 100%; }
    .block_video_transfer .views_row .field_info {
      overflow: hidden;
      color: #89919e;
      font: 400 24px 'PFDinTextCompPro', sans-serif; }
      .block_video_transfer .views_row .field_info .stat {
        padding-left: 45px;
        background: url(images/stat.png) no-repeat top 1px left;
        margin-right: 23px;
        display: inline-block;
        vertical-align: middle; }
      .block_video_transfer .views_row .field_info .time {
        display: inline-block;
        vertical-align: middle;
        padding-left: 39px;
        background: url(images/hours.png) no-repeat top left;
        line-height: 31px; }

.favorites .block_shops,
.favorites .block_earth {
  padding-top: 0; }
  .favorites .block_shops .block_title,
  .favorites .block_earth .block_title {
    text-transform: uppercase;
    color: #03a9f4;
    font: 500 36px 'PFDinTextCompPro', sans-serif;
    text-align: left;
    margin-bottom: 8px; }

.favorites .block_newbuilding_row .views_row .views_body .address {
  margin-bottom: 0; }

.favorites .block_newbuilding_row .views_row .left_wrap .price.info {
  color: #3b4047; }

.favorites .block_earth .views_row .left_wrap,
.favorites .block_earth .views_row .right_wrap {
  max-width: 347px; }

.block_final_wrap .group_title {
  text-transform: uppercase;
  color: #03a9f4;
  text-align: left;
  font: 500 36px 'PFDinTextCompPro', sans-serif;
  margin-bottom: 10px; }

.block_final_wrap .group_wrap {
  margin-bottom: 4px; }

.block_final_wrap .views_row {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 30px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .block_final_wrap .views_row .webform_client_form {
    text-align: left;
    overflow: hidden; }
    .block_final_wrap .views_row .webform_client_form input,
    .block_final_wrap .views_row .webform_client_form textarea {
      border: 1px solid #bec7d1;
      -webkit-box-shadow: 0 1px 0 0 #e5e5e5;
      box-shadow: 0 1px 0 0 #e5e5e5;
      box-sizing: border-box; }
    .block_final_wrap .views_row .webform_client_form .left_wrap {
      float: left;
      width: 864px;
      margin-right: 30px; }
      .block_final_wrap .views_row .webform_client_form .left_wrap .title {
        font: 400 24px 'PFDinTextCompPro', sans-serif;
        margin-bottom: 8px; }
      .block_final_wrap .views_row .webform_client_form .left_wrap .text {
        line-height: 20px;
        margin-bottom: 30px; }
      .block_final_wrap .views_row .webform_client_form .left_wrap .form_item.textarea label {
        display: block;
        margin-bottom: 4px; }
      .block_final_wrap .views_row .webform_client_form .left_wrap .form_item.textarea textarea {
        width: 100%;
        height: 64px;
        background: #fff;
        padding-left: 12px; }
    .block_final_wrap .views_row .webform_client_form .right_wrap {
      overflow: hidden; }
      .block_final_wrap .views_row .webform_client_form .right_wrap .status {
        padding-left: 25px;
        margin-bottom: 23px;
        line-height: 20px; }
        .block_final_wrap .views_row .webform_client_form .right_wrap .status div {
          position: relative; }
          .block_final_wrap .views_row .webform_client_form .right_wrap .status div::after {
            content: '';
            background: #3b4047;
            width: 16px;
            height: 16px;
            border-radius: 100%;
            position: absolute;
            top: 1px;
            left: -25px;
            box-sizing: border-box; }
          .block_final_wrap .views_row .webform_client_form .right_wrap .status div.important::after {
            background: #ff1919; }
      .block_final_wrap .views_row .webform_client_form .right_wrap label {
        margin-bottom: 4px;
        display: block; }
      .block_final_wrap .views_row .webform_client_form .right_wrap .form_item {
        width: 100%;
        margin-bottom: 22px; }
        .block_final_wrap .views_row .webform_client_form .right_wrap .form_item input {
          width: 100%;
          height: 32px; }

.block_webform {
  background: #fff;
  max-width: 1170px;
  margin: auto;
  position: fixed;
  z-index: 100;
  width: 100%;
  margin-left: -585px;
  top: 50%;
  left: 50%;
  padding: 30px;
  transition: .3s;
  box-sizing: border-box; }
  .block_webform#block-webform-confirm {
    max-width: 350px;
    margin-left: -175px;
    margin-top: -39px; }
    .block_webform#block-webform-confirm .close {
      width: 30px;
      height: 30px;
      top: 24px; }
  .block_webform .block_title {
    margin: 0 auto 30px;
    max-width: 790px; }
  .block_webform .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: url(images/close.png) no-repeat center/contain;
    transition: .3s; }
    .block_webform .close:hover {
      transform: rotate(180deg); }
  .block_webform.block_order_form {
    display: none;
    max-width: 710px;
    margin-left: -355px; }
    .block_webform.block_order_form .webform_client_form .top_wrap {
      width: auto;
      float: left;
      margin-right: 30px; }
      .block_webform.block_order_form .webform_client_form .top_wrap .form_item.title_item {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px; }
    .block_webform.block_order_form .webform_client_form .form_actions {
      width: auto;
      vertical-align: bottom;
      float: left;
      margin-top: 7px;
      margin-bottom: 30px; }
  .block_webform .webform_client_form {
    text-align: left;
    max-width: 632px;
    margin: auto; }
    .block_webform .webform_client_form input,
    .block_webform .webform_client_form select {
      height: 32px; }
    .block_webform .webform_client_form input,
    .block_webform .webform_client_form textarea {
      border: 1px solid #bec7d1;
      -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
    .block_webform .webform_client_form label {
      margin-bottom: 3px;
      display: block; }
    .block_webform .webform_client_form .top_wrap {
      margin-bottom: 30px; }
      .block_webform .webform_client_form .top_wrap .form_item {
        width: 200px;
        margin-right: 27px; }
        .block_webform .webform_client_form .top_wrap .form_item.date {
          width: 184px; }
        .block_webform .webform_client_form .top_wrap .form_item:last-child {
          margin-right: 0; }
    .block_webform .webform_client_form .bottom_wrap .form_item.textarea {
      float: left;
      width: 414px;
      margin-right: 30px !important; }
      .block_webform .webform_client_form .bottom_wrap .form_item.textarea textarea {
        background: #fff;
        padding-left: 12px;
        height: 118px; }
    .block_webform .webform_client_form .bottom_wrap .form_item.form_select {
      overflow: hidden;
      width: 184px;
      display: block;
      margin-bottom: 33px; }
    .block_webform .webform_client_form .form_actions {
      text-align: center;
      width: 100%; }
      .block_webform .webform_client_form .form_actions input {
        display: inline-block;
        vertical-align: middle;
        height: 46px;
        border: none;
        outline: none; }
  .block_webform .text {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px; }
    .block_webform .text a {
      text-decoration: none; }
  .block_webform.webform_client .block_title, .block_webform.webform_object .block_title {
    line-height: 1;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px; }
    .block_webform.webform_client .block_title::after, .block_webform.webform_object .block_title::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 220px;
      height: 3px;
      margin-left: -110px;
      background-color: #3b4047; }
  .block_webform.webform_client .webform_client_form, .block_webform.webform_object .webform_client_form {
    max-width: 920px; }
    .block_webform.webform_client .webform_client_form .form_item, .block_webform.webform_object .webform_client_form .form_item {
      margin-right: 6px; }
      .block_webform.webform_client .webform_client_form .form_item input, .block_webform.webform_object .webform_client_form .form_item input {
        height: 42px; }
    .block_webform.webform_client .webform_client_form .address, .block_webform.webform_object .webform_client_form .address {
      width: 386px; }
      .block_webform.webform_client .webform_client_form .address input, .block_webform.webform_object .webform_client_form .address input {
        background: url(images/address.png) no-repeat center left 15px;
        padding-left: 45px; }
    .block_webform.webform_client .webform_client_form .name input, .block_webform.webform_object .webform_client_form .name input {
      background-image: url(images/user.png); }
    .block_webform.webform_client .webform_client_form .price, .block_webform.webform_object .webform_client_form .price {
      width: 104px;
      margin-right: 0; }
      .block_webform.webform_client .webform_client_form .price input, .block_webform.webform_object .webform_client_form .price input {
        background: url(images/price.png) no-repeat center left 15px;
        padding-left: 45px; }
    .block_webform.webform_client .webform_client_form .form_item.textarea, .block_webform.webform_object .webform_client_form .form_item.textarea {
      width: 100%;
      float: none;
      margin: 0 0 16px; }
      .block_webform.webform_client .webform_client_form .form_item.textarea textarea, .block_webform.webform_object .webform_client_form .form_item.textarea textarea {
        padding-left: 45px;
        background: url(images/email.png) no-repeat top 15px left 15px; }
    .block_webform.webform_client .webform_client_form .top_wrap, .block_webform.webform_object .webform_client_form .top_wrap {
      margin-bottom: 10px; }
  .block_webform.webform_object .webform_client_form {
    text-align: center; }
    .block_webform.webform_object .webform_client_form .form_actions {
      display: inline-block;
      vertical-align: middle;
      width: auto; }
    .block_webform.webform_object .webform_client_form .form_item {
      width: 200px;
      margin-right: 25px; }
      .block_webform.webform_object .webform_client_form .form_item input {
        height: 46px; }
  .block_webform .filters {
    text-align: center;
    margin-bottom: 30px; }
    .block_webform .filters .item {
      font-size: 14px;
      font-weight: 400;
      border: 1px solid #a9d5e8;
      display: inline-block;
      vertical-align: middle;
      height: 36px;
      line-height: 36px;
      padding: 0 37px 0 11px;
      position: relative;
      background: #fff;
      margin-right: 37px;
      box-sizing: border-box; }
      .block_webform .filters .item .close {
        background: #fff url(images/filter.png) no-repeat center right 0px;
        content: '';
        top: 10px;
        right: 10px;
        width: 15px;
        height: 15px;
        cursor: pointer;
        position: absolute; }

/*----------------------------------------------------------------------------
									FOOTER
----------------------------------------------------------------------------*/
.footer.active {
  height: 325px; }
  .footer.active .footer_bottom {
    display: block; }

.footer {
  width: 100%;
  margin: -180px auto 0;
  height: 180px;
  position: relative;
  clear: both;
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center/cover;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  box-sizing: border-box; }
  .footer .footer_content {
    max-width: 1170px;
    margin: 0 auto; }
  .footer .footer_top {
    float: left;
    width: 100%;
    border-bottom: 1px solid rgba(151, 151, 151, 0.5);
    padding-bottom: 30px; }
    .footer .footer_top .region_footer .menu_footer a {
      margin-bottom: 0; }
  .footer .footer_bottom {
    clear: both;
    display: none;
    padding-top: 30px; }
  .footer .region_footer {
    max-width: 1170px;
    margin: auto;
    position: relative; }
    .footer .region_footer .logo_wrapper {
      float: left;
      width: 230px;
      margin-right: 35px; }
      .footer .region_footer .logo_wrapper a.logo {
        margin-bottom: 10px;
        display: block;
        text-decoration: none;
        text-transform: uppercase; }
        .footer .region_footer .logo_wrapper a.logo img {
          margin-right: 10px; }
        .footer .region_footer .logo_wrapper a.logo span {
          display: inline-block;
          vertical-align: bottom;
          margin-bottom: 10px; }
        .footer .region_footer .logo_wrapper a.logo .site_name {
          color: #03a9f4;
          font: 500 31px 'PFDinTextCompPro', sans-serif; }
        .footer .region_footer .logo_wrapper a.logo .domen {
          font: 400 19px 'PFDinTextCompPro', sans-serif;
          color: #3b4047;
          margin-bottom: 14px; }
      .footer .region_footer .logo_wrapper .copyright {
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        padding-right: 7px;
        box-sizing: border-box; }
    .footer .region_footer .footer_center {
      width: 480px;
      float: left;
      margin-right: 20px; }
    .footer .region_footer .menu_footer li.group_item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 57px; }
      .footer .region_footer .menu_footer li.group_item:nth-child(3n+3) {
        margin-right: 0; }
      .footer .region_footer .menu_footer li.group_item .expanded {
        position: relative; }
        .footer .region_footer .menu_footer li.group_item .expanded .expanded_btn {
          position: relative;
          padding-right: 15px; }
          .footer .region_footer .menu_footer li.group_item .expanded .expanded_btn::after {
            content: '';
            position: absolute;
            top: 9px;
            right: 0;
            border: 4.5px solid transparent;
            border-top: 4.5px solid #4a4a4a; }
      .footer .region_footer .menu_footer li.group_item .disabled {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        background: #fff;
        padding: 5px 15px; }
    .footer .region_footer .menu_footer a {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      display: block;
      margin-bottom: 7px;
      text-decoration: none;
      transition: .3s; }
      .footer .region_footer .menu_footer a:hover {
        color: #1fb239;
        text-decoration: underline; }
    .footer .region_footer .footer_right {
      padding-top: 40px;
      float: right; }
      .footer .region_footer .footer_right > div {
        float: left; }
      .footer .region_footer .footer_right .buttons_header {
        margin-left: 20px; }
    .footer .region_footer .visit {
      margin-top: 15px; }
      .footer .region_footer .visit .item {
        display: inline-block;
        vertical-align: middle; }
        .footer .region_footer .visit .item + .item {
          margin-left: 20px; }
  .footer .footer_bottom .menu_footer li.group_item {
    margin-right: 75px; }
    .footer .footer_bottom .menu_footer li.group_item:nth-child(3n+3) {
      margin-right: 88px; }
    .footer .footer_bottom .menu_footer li.group_item:last-child {
      margin-right: 0px; }
  .footer .phone_number a {
    font-size: 36px; }
  .footer .email {
    margin-bottom: 10px; }
  .footer .social_links {
    position: absolute;
    top: -46px;
    right: 0;
    z-index: 1; }
    .footer .social_links .item {
      display: inline-block;
      vertical-align: middle; }
      .footer .social_links .item + .item {
        margin-left: 17px; }
    .footer .social_links a {
      width: 52px;
      height: 52px;
      display: inline-block;
      vertical-align: middle; }
    .footer .social_links .fb a {
      background: url(images/fb.png) no-repeat center/contain; }
    .footer .social_links .vk a {
      background: url(images/vk.png) no-repeat center/contain; }
    .footer .social_links .tw a {
      background: url(images/tw.png) no-repeat center/contain; }
    .footer .social_links .gp a {
      background: url(images/gp.png) no-repeat center/contain; }

/*----------------------------------------------------------------------------
									PAGER
----------------------------------------------------------------------------*/
.pager {
  position: relative;
  width: 310px;
  margin: 0 auto !important;
  clear: both; }
  .pager li {
    padding: 0 !important;
    margin: 0 !important; }
    .pager li a {
      font: 700 24px 'Arial';
      color: #514e4e;
      text-decoration: none; }
      .pager li a:hover {
        color: #a34fb0; }
    .pager li.pager-item a {
      padding: 7px 14px 2px 14px !important;
      margin: 0 !important;
      line-height: 1 !important;
      display: inline-block !important; }
    .pager li.pager-next a, .pager li.pager-previous a {
      display: inline-block;
      width: 24px;
      height: 14px;
      text-indent: -9999px;
      position: absolute;
      top: 50%;
      margin-top: -7px; }
    .pager li.pager-next a {
      background: url("images/pager_arrows.png") no-repeat scroll right 0 transparent;
      right: 0; }
      .pager li.pager-next a:hover {
        background: url("images/pager_arrows.png") no-repeat scroll right bottom transparent; }
    .pager li.pager-previous a {
      background: url("images/pager_arrows.png") no-repeat scroll 0 0 transparent;
      left: 0; }
      .pager li.pager-previous a:hover {
        background: url("images/pager_arrows.png") no-repeat scroll 0 bottom transparent; }
    .pager li.pager-current {
      color: #fff;
      background: #02ae5f !important;
      font: 700 24px 'Arial';
      padding: 7px 14px 2px 14px !important;
      margin: 0 !important;
      line-height: 1 !important;
      display: inline-block !important; }
  .pager .pager-last, .pager .pager-first {
    display: none !important; }

.item-list.pager_my_ui .pager-current {
  background: none;
  border: none; }

/*----------------------------------------------------------------------------
									LOADER
----------------------------------------------------------------------------*/
.ajax-progress {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
  margin: 0;
  padding: 0;
  z-index: 1000; }

.ajax-progress .throbber {
  background: none;
  border-radius: 6px;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  text-indent: -9999px;
  margin: 0;
  padding: 0;
  border: 8px solid rgba(0, 0, 0, 0.3);
  border-left: 8px solid #000;
  border-radius: 100%;
  -webkit-animation: rotate 0.7s linear infinite;
  -moz-animation: rotate 0.7s linear infinite;
  animation: rotate 0.7s linear infinite;
  margin-top: -25px;
  margin-left: -25px; }

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes fadeOut {
  0% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
    opacity: 0.1; }
  100% {
    -webkit-transform: scale(0.1);
    opacity: 0.8; } }

@-webkit-keyframes strechdelay {
  0%, 50%, 100% {
    -webkit-transform: scaleY(0.4); }
  20% {
    -webkit-transform: scaleY(1); } }

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg); }
  100% {
    -moz-transform: rotate(360deg); } }

@-moz-keyframes fadeOut {
  0% {
    -moz-transform: scale(0); }
  50% {
    -moz-transform: scale(1);
    opacity: 0.1; }
  100% {
    -moz-transform: scale(0.1);
    opacity: 0.8; } }

@-moz-keyframes strechdelay {
  0%, 50%, 100% {
    -moz-transform: scaleY(0.4); }
  20% {
    -moz-transform: scaleY(1); } }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes fadeOut {
  0% {
    transform: scale(0); }
  50% {
    transform: scale(1);
    opacity: 0.1; }
  100% {
    transform: scale(0.1);
    opacity: 0.8; } }

@keyframes strechdelay {
  0%, 50%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

/*----------------------------------------------------------------------------
									BASIC STYLES
----------------------------------------------------------------------------*/
.grippie {
  display: none !important; }

.webform-confirmation + .links {
  display: none !important; }

ul.menu li, ul li {
  margin: 0;
  padding: 0;
  list-style: none; }

input {
  vertical-align: middle;
  box-sizing: border-box; }
  input.form-submit {
    cursor: pointer;
    transition: 0.3s; }
    input.form-submit:hover {
      opacity: 0.8; }
  input.form-text {
    transition: 0.3s; }
    input.form-text:focus {
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important; }

textarea {
  box-sizing: border-box;
  transition: 0.3s; }
  textarea:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6) !important; }

input.error,
textarea.error,
input[type="text"].error,
input[type="password"].error {
  border: 1px solid red !important; }

.jGrowl-notification.error {
  background-color: #CC0000 !important; }

.jGrowl-notification.status {
  background-color: #009900 !important; }

#jGrowl .jGrowl-notification {
  background-position: 4px 8px !important; }

.form-item, .form-actions {
  margin: 0; }

a {
  color: #514e4e;
  text-decoration: underline; }
  a:hover {
    text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  border: none; }

* {
  margin: 0;
  padding: 0;
  outline: none; }

p {
  margin: 0 0 18px; }

body.admin-menu {
  margin-top: 0 !important;
  padding-top: 32px; }

.admin_tabs {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 95; }
  .admin_tabs .tabs {
    border-bottom: 0 !important; }
    .admin_tabs .tabs a {
      font-size: 16px;
      color: #fff !important;
      background: none !important;
      border: none !important; }
      .admin_tabs .tabs a.active, .admin_tabs .tabs a:hover {
        color: #64c300 !important; }

.page-user .page_title {
  text-align: center; }

.page-user #middle {
  margin: 0 auto;
  text-align: center; }

.page-user #block-system-main form {
  display: table;
  margin: 0 auto; }

.page-user #block-system-main .description {
  display: none; }

.page-user #block-system-main label {
  font-size: 16px;
  color: #000;
  margin-top: 10px; }

.page-user #block-system-main input {
  width: 230px;
  height: 33px;
  border: 1px solid #dededf;
  padding: 0 10px;
  color: #8a8989;
  font-size: 16px; }
  .page-user #block-system-main input.form-submit {
    height: 30px;
    border: 0;
    background: #888;
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    margin-top: 20px; }

.page-error-403 .page_title, .page-error-404 .page_title, .maintenance-page .page_title {
  text-align: center; }

.page-error-403 .clearfix, .page-error-403 .errores_page_content, .page-error-404 .clearfix, .page-error-404 .errores_page_content, .maintenance-page .clearfix, .maintenance-page .errores_page_content {
  text-align: center;
  color: #888;
  font-size: 16px; }

.maintenance-page .logo-floater {
  margin: 0 auto;
  padding-top: 15px;
  display: table; }

.popup_exit {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  z-index: 99; }
  .popup .popup_exit {
    display: block; }

#gl_devel {
  /*	position: absolute;
	left: 50%;
	margin-left: -585px;
	top: 110px;*/ }

#gl_devel {
  height: 20px;
  clear: both; }

#gl_devel span,
#gl_devel a {
  font: 12px/20px 'cuprumregular';
  text-transform: uppercase; }

#gl_devel span {
  color: #999999; }

#gl_devel img {
  margin-right: 5px;
  position: relative;
  top: 3px; }

#gl_devel a {
  color: #999999;
  text-decoration: none; }

body.ie_browser #gl_devel a,
body.ie_browser #gl_devel span {
  font-size: 8px; }

#gl_devel a:hover {
  text-decoration: underline; }

.fixed_mobile {
  display: none; }

.to_up {
  position: absolute;
  top: -26px;
  right: 40px;
  width: 52px;
  height: 52px;
  background: url(images/up.png) no-repeat center/contain;
  cursor: pointer;
  transition: .3s; }
  .to_up:hover {
    opacity: .7; }

/*----------------------------------------------------------------------------
									ADAPTIVE STYLES
----------------------------------------------------------------------------*/
@media (max-width: 767px) {
  .fixed_mobile {
    position: fixed;
    left: 0;
    bottom: 0px;
    z-index: 90;
    width: 100%;
    text-align: center;
    background: #fff;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.2);
    font-size: 20px;
    min-width: 320px;
    -webkit-transform: translateZ(0);
    display: block; }
    .fixed_mobile a {
      display: block; }
      .fixed_mobile a span {
        display: inline-block;
        vertical-align: top;
        padding: 10px 0 10px 30px;
        background: url(images/ico_phone.png) no-repeat scroll 0 center transparent;
        cursor: pointer; } }

@media (min-width: 991px) and (max-width: 1023px) {
  html {
    min-width: 320px; }
  .header .header_content {
    height: auto; }
    .header .header_content .benefits {
      width: 290px; }
  .header .region_header {
    float: none;
    overflow: visible;
    padding-top: 10px; }
    .header .region_header > div {
      float: right; }
    .header .region_header .benefits {
      margin-left: 0; }
    .header .region_header .buttons_header {
      float: none;
      clear: both;
      text-align: center;
      margin: 0;
      width: 100%; }
      .header .region_header .buttons_header .application,
      .header .region_header .buttons_header .add_advert {
        width: 192px;
        display: inline-block;
        vertical-align: top;
        margin: 5px 15px 30px; }
  .header .logo_wrapper {
    width: 215px;
    margin: 9px 25px 9px 0;
    display: block; }
    .header .logo_wrapper a.logo span {
      margin-bottom: 10px; }
    .header .logo_wrapper a.logo .site_name {
      font-size: 28px; }
    .header .logo_wrapper a.logo .domen {
      font-size: 18px;
      margin-bottom: 15px; }
  .header .block_main_menu .menu li.leaf > a {
    font-size: 18px; }
  .header .region_header > div + div {
    margin-left: 25px; }
  .slider_main {
    padding-left: 15px;
    padding-right: 15px; }
    .slider_main .slider_title {
      font-size: 43px;
      line-height: 1.2; }
  .block_title {
    font-size: 43px;
    line-height: 1.2; }
  .block_shops,
  .block_vip {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box; }
    .block_shops .view_content,
    .block_vip .view_content {
      text-align: center; }
    .block_shops .views_row,
    .block_vip .views_row {
      width: 30%;
      text-align: left; }
      .block_shops .views_row .views_body .text,
      .block_vip .views_row .views_body .text {
        font-size: 12px;
        line-height: 1.3; }
      .block_shops .views_row .views_body .price,
      .block_shops .views_row .views_body .statistics,
      .block_shops .views_row .views_body .area,
      .block_vip .views_row .views_body .price,
      .block_vip .views_row .views_body .statistics,
      .block_vip .views_row .views_body .area {
        font-size: 20px; }
      .block_shops .views_row .views_body .area,
      .block_vip .views_row .views_body .area {
        width: 33%; }
  .read_more {
    font-size: 15px; }
  .new_buildings .views_row,
  .block_search_results .views_row,
  .block_vip_shops .views_row {
    width: 45%; }
    .sidebar_first #content .new_buildings .views_row, .sidebar_first #content
    .block_search_results .views_row, .sidebar_first #content
    .block_vip_shops .views_row {
      width: 100%;
      margin-right: 0 !important; }
  .block_services .view_content {
    text-align: center;
    float: none; }
    .block_services .view_content .views_row {
      float: none; }
      .block_services .view_content .views_row:nth-child(even) {
        margin-right: 0; }
  .block_presentation {
    padding-left: 15px;
    padding-right: 15px; }
    .block_presentation .view_content {
      text-align: center; }
      .block_presentation .view_content .views_row {
        width: 30%; }
  .block_seo {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
    .block_seo .body {
      font-size: 20px;
      line-height: 1.3; }
    .block_seo .content_body {
      height: 252px; }
  .block_articles {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
    .block_articles .views_row {
      float: none;
      margin: 0 25px 30px !important; }
    .block_articles .read_more {
      clear: both; }
  .footer {
    height: 323px;
    margin-top: -323px; }
    .superlending .footer,
    .superlending_inner .footer {
      height: 180px;
      margin-top: -180px; }
    .footer.active {
      height: auto; }
    .footer .region_footer .footer_right {
      float: none;
      clear: both;
      display: block;
      margin: 0 auto;
      width: 400px;
      overflow: hidden; }
      .superlending .footer .region_footer .footer_right,
      .superlending_inner .footer .region_footer .footer_right {
        clear: none;
        float: right;
        width: auto; }
    .footer .region_footer .footer_center {
      width: auto;
      margin-right: 0;
      margin-top: 20px; }
    .footer .footer_bottom,
    .footer .footer_top {
      padding-left: 15px;
      padding-right: 15px;
      box-sizing: border-box; }
    .footer .social_links {
      right: 50%;
      margin-right: -135px; }
  .superlending .middle,
  .superlending_inner .middle {
    padding-bottom: 180px; }
  .middle {
    padding-bottom: 323px; }
  .wrapper {
    overflow: hidden; }
  .slider_for .item {
    height: 475px; }
  .block_inform_appartament .left_wrap {
    width: 61%; }
  .block_inform_appartament .right_wrap {
    width: 38%; }
  .block_body .field_body {
    overflow: visible;
    text-align: justify; }
  .block_maps .map {
    width: 48%; }
  .block_vip_shops {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
    .block_vip_shops .view_content {
      text-align: center; }
      .block_vip_shops .view_content .views_row {
        width: 48% !important; }
        .block_vip_shops .view_content .views_row:nth-child(even) {
          margin-right: 0 !important; }
  .sidebar_first .middle_inner {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
  .block_node_content .field_body {
    text-align: justify; }
  .articles_inner #content,
  .favorites #content,
  .newbuilding #content,
  .process #content,
  .ranking #content,
  .video_transfer #content {
    padding-left: 0; }
  .articles_inner .left_sidebar,
  .favorites .left_sidebar,
  .newbuilding .left_sidebar,
  .process .left_sidebar,
  .ranking .left_sidebar,
  .video_transfer .left_sidebar {
    float: none;
    margin: auto;
    clear: both; }
  .block_should_use .views_row {
    margin: 0 15px 30px !important; }
  .block_we_have,
  .block_package {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
  .block_package {
    text-align: center; }
    .block_package .views_row {
      max-width: 48%;
      text-align: left; }
      .block_package .views_row:nth-child(even) {
        margin-right: 0; }
  .block_work_client .views_row {
    width: 49%;
    margin-bottom: 25px;
    padding-right: 10px;
    box-sizing: border-box; }
  .block_contacts_specialists .views_row .right_wrap .about_block .field_item {
    width: 47%; }
  .block_contacts_specialists .views_row .right_wrap .body_wrap {
    text-align: justify; }
  .block_contacts_specialists .views_row .right_wrap iframe {
    height: 238px; }
  .block_vip_shops .block_content .view_content .views_row,
  .block_daily_products.block_search_results .block_content .view_content .views_row {
    margin-right: 26px !important; }
  .block_vip_shops .view_content .views_row:nth-child(even),
  .block_daily_products.block_search_results .view_content .views_row:nth-child(even) {
    margin-right: 0 !important; }
  .search_results.search_products .webform_client_form .form_select.area_select select {
    width: 177px; }
  .search_results.search_products .webform_client_form .form_actions .links {
    margin: 15px 20px 0 0;
    display: inline-block;
    float: left;
    vertical-align: middle; }
  .earth .search_results form .form_actions::after, .ranking_daily .search_results form .form_actions::after {
    top: 13px;
    left: 79px; }
  .block_earth .views_row .left_wrap {
    width: 47%; }
  .block_earth .views_row .right_wrap {
    width: 47%; }
  .block_earth .views_row .bottom_wrap .left_wrap {
    width: 48%; }
  .block_earth .views_row .bottom_wrap .center_wrap {
    width: 47%;
    float: right; }
  .block_earth .views_row .bottom_wrap .right_wrap {
    float: left;
    margin-top: 25px;
    clear: both;
    max-width: 100%;
    width: 100%; }
    .block_earth .views_row .bottom_wrap .right_wrap .info_wrap {
      float: left;
      width: 50%; }
    .block_earth .views_row .bottom_wrap .right_wrap .contacts {
      float: right;
      width: 50%;
      padding-left: 20px;
      box-sizing: border-box; }
  .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn {
    float: none; }
  .slider_main .block_favorites .views_row {
    margin-right: 30px; }
  .sidebar_first .block_shops .views_row .views_body .statistics,
  .sidebar_first .block_vip .views_row .views_body .statistics {
    padding-left: 40px;
    font-size: 18px;
    margin-right: 7px;
    line-height: 23px; }
  .block_search_results .views_row .views_body .read_more a,
  .block_vip_shops .views_row .views_body .read_more a {
    padding: 0 10px; }
  .block_search_results .views_row .views_body .read_more.preview, .block_vip_shops .views_row .views_body .read_more.preview {
    font-size: 14px; }
  .sidebar_first.favorites #content .block_search_results .views_row,
  .sidebar_first.ranking #content .block_search_results .views_row {
    width: 47%;
    margin-right: 26px !important; }
  .sidebar_first.favorites #content .block_search_results .views_row:nth-child(even),
  .sidebar_first.ranking #content .block_search_results .views_row:nth-child(even) {
    margin-right: 0 !important; }
  .sidebar_first.favorites #content .block_search_results.block_newbuilding_row .views_row {
    width: 100%;
    margin-right: 0 !important; }
  .block_newbuilding_row .block_content .view_content .views_row .left_wrap {
    width: 64%; }
  .favorites .block_earth .views_row .bottom_wrap .right_wrap {
    clear: none;
    width: 47%;
    margin-top: 0; }
    .favorites .block_earth .views_row .bottom_wrap .right_wrap .info_wrap,
    .favorites .block_earth .views_row .bottom_wrap .right_wrap .contacts {
      float: none;
      width: 100%;
      padding-left: 0; }
  body.final .slider_main .social-likes {
    margin: 0; }
  body.final .slider_main .button_wrap {
    position: static;
    transform: none;
    clear: both;
    text-align: center; }
    body.final .slider_main .button_wrap .btn {
      display: inline-block;
      vertical-align: middle;
      margin: 25px 15px 0; }
  .block_final_wrap {
    padding: 0 15px;
    box-sizing: border-box; }
    .block_final_wrap .views_row .webform_client_form .left_wrap {
      width: 50%; }
  .block_webform {
    margin-left: 0;
    left: 0; }
  .block_webform.webform_client .webform_client_form .address,
  .block_webform.webform_object .webform_client_form .address {
    width: 175px; }
  .block_webform#block-webform-confirm {
    left: 50%; }
  .block_benefits.steps .views_row::after {
    width: 126px;
    right: -63px; }
  .block_hydrometers .view_content {
    text-align: center; }
  .block_questions .views_row .qty_text .item {
    width: 100%; }
  .block_our_advantages .views_row {
    width: 30%; }
  .multilending .search_results form.webform_client_form .form_item.metro {
    width: 100%;
    margin-right: 0;
    margin-bottom: 25px; }
  .multilending .block_search_results .view_content .views_row,
  .superlending .block_search_results .view_content .views_row {
    width: 46%;
    margin-right: 26px !important; }
  .multilending .block_search_results .view_content .views_row:nth-child(even),
  .superlending .block_search_results .view_content .views_row:nth-child(even) {
    margin-right: 0 !important; }
  .multilending .block_vip_shops .view_content {
    margin-bottom: 30px; }
  .newbuilding_inner .block_inform_appartament .left_wrap {
    width: 50%; }
  .newbuilding_inner .block_inform_appartament .right_wrap {
    width: 43%;
    clear: both;
    float: none;
    display: block;
    margin: 25px auto 0; }
  .block_accommodations .block_content div.item,
  .block_accommodations .block_content .accommodations div.item {
    width: 100%; }
  .plan .slider_main .slider_list .item {
    margin-bottom: 20px; }
  .block_benefits .views_row {
    width: 24%; }
  .process .slider_main .form_item {
    width: 220px; }
  .block_process .views_row .right_wrap .webform_client_form .form_textarea {
    width: 50%; }
  .property_valuation .block_benefits .views_row {
    width: 32%;
    margin-bottom: 20px; }
  .property_valuation .block_presentation form.webform_client_form .form_item:not(.textarea),
  .real_services .block_presentation form.webform_client_form .form_item:not(.textarea) {
    width: 238px; }
  .ranking_daily .slider_main .form_item {
    margin-bottom: 20px; }
  .ranking_daily .slider_main .webform_client_form .form_actions {
    vertical-align: top; }
  .ranking_daily .search_results.search_products .webform_client_form .form_select.area_select select {
    width: 275px; }
  .ranking_daily .search_results.search_products .webform_client_form .form_item:nth-child(3) {
    margin-right: 0; }
  .search_results .filters {
    max-width: 73%; }
    .search_results .filters .item {
      margin-bottom: 15px; }
  .search_results.search_products > div {
    overflow: hidden; }
  .block_vip_shops .view_content .views_row,
  .block_daily_products.block_search_results .view_content .views_row {
    width: 47%; }
  .trust .slider_main form .form_item {
    width: 200px; }
  .block_price_wrapper,
  .block_our_works {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
  .block_our_works .views_row {
    margin-right: 25px !important;
    width: 47%; }
    .block_our_works .views_row:nth-child(even) {
      margin-right: 0 !important; }
  .repair_premises .block_benefits .views_row,
  .installation_water .block_benefits .views_row {
    width: 45%;
    margin-bottom: 25px; }
  .block_ready .view_content > div {
    margin-bottom: 20px; }
  .block_services_wrap .views_row {
    margin-bottom: 30px; }
    .block_services_wrap .views_row .field_image {
      overflow: hidden; }
      .block_services_wrap .views_row .field_image img {
        display: block;
        height: 100%;
        max-width: none; }
  body.superlending .slider_main {
    padding-bottom: 83px; }
  .superlending .search_results form.webform_client_form .form_item {
    margin-bottom: 20px; }
  .superlending .block_search_results .view_content .views_row {
    margin-right: 26px !important; }
    .superlending .block_search_results .view_content .views_row:nth-child(even) {
      margin-right: 0 !important; }
  .block_diplomas {
    padding: 0 15px;
    box-sizing: border-box; }
    .block_diplomas .views_row {
      width: 48%;
      margin-right: 26px !important; }
      .block_diplomas .views_row:nth-child(even) {
        margin-right: 0 !important; }
  .block_test_result .views_row .left_wrap {
    margin-bottom: 15px; }
  .block_test_result .views_row .right_wrap {
    overflow: visible; }
  .tests .slider_main .slider_list .item {
    margin-right: 30px;
    margin-bottom: 20px; }
    .tests .slider_main .slider_list .item:nth-child(2) {
      min-width: auto; }
  .tests .slider_main .slider_list .item:last-child {
    margin: 0; }
  .trust .block_benefits .views_row {
    width: 30%;
    margin-bottom: 30px; }
  .trust .block_video {
    margin-bottom: 30px; }
  .block_reviews {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box; }
  .block_reviews .slider_video .views_row {
    height: 260px; }
    .block_reviews .slider_video .views_row iframe {
      height: 100% !important;
      width: 100% !important; }
  .block_reviews .reviews_text .view_row {
    width: 47%; }
  .slider_main .come_back {
    left: 10px; }
  .block_search_results .views_row.no_image .read_more, .block_vip_shops .views_row.no_image .read_more {
    float: none; } }

@media (max-width: 768px) {
  html {
    min-width: 320px; }
  .header {
    padding-top: 0; }
    .header .header_content {
      padding: 0;
      height: auto; }
      .header .header_content .logo_wrapper {
        float: none;
        margin: 0 auto;
        text-align: center;
        padding: 17px 0 8px;
        margin-bottom: 0;
        box-shadow: none;
        background: #fff url(images/shadow.png) repeat-x bottom;
        box-sizing: border-box; }
    .header .region_header {
      float: none;
      overflow: visible;
      padding: 20px 0 0 0;
      box-sizing: border-box; }
      .header .region_header .benefits {
        float: none;
        margin: 0;
        width: 100%;
        padding: 0 20px 6px;
        box-shadow: none;
        background: #fff url(images/shadow.png) repeat-x bottom;
        margin-bottom: 20px;
        box-sizing: border-box; }
      .header .region_header .contacts_header {
        float: none;
        padding: 0 20px 21px;
        box-shadow: none;
        background: #fff url(images/shadow.png) repeat-x bottom;
        margin-bottom: 20px;
        position: relative;
        box-sizing: border-box; }
        .header .region_header .contacts_header .slicknav_menu {
          position: absolute;
          top: 11px;
          right: 20px;
          padding: 0;
          background: none;
          width: 100%; }
          .header .region_header .contacts_header .slicknav_menu .slicknav_btn {
            border-radius: 100%;
            background: #17bb49;
            height: 54px;
            width: 54px;
            display: block;
            box-sizing: border-box; }
            .header .region_header .contacts_header .slicknav_menu .slicknav_btn .slicknav_icon {
              width: 100%;
              height: 100%;
              padding-top: 8px; }
              .header .region_header .contacts_header .slicknav_menu .slicknav_btn .slicknav_icon::before {
                width: 100%;
                height: 100%; }
            .header .region_header .contacts_header .slicknav_menu .slicknav_btn .slicknav_icon-bar {
              height: 2px;
              width: 24px;
              margin: auto;
              box-shadow: none; }
              .header .region_header .contacts_header .slicknav_menu .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
                margin-top: 9px; }
          .header .region_header .contacts_header .slicknav_menu .slicknav_nav {
            background: #fff;
            padding: 15px; }
            .header .region_header .contacts_header .slicknav_menu .slicknav_nav a {
              color: #000;
              font-size: 16px;
              margin: 5px 0;
              text-transform: uppercase; }
      .header .region_header .buttons_header {
        margin: 0 auto;
        float: none;
        padding-bottom: 11px; }
        .header .region_header .buttons_header > div + div {
          margin-top: 9px; }
  .block_main_menu {
    display: none; }
  .slider_main {
    padding: 28px 20px !important; }
    .services .slider_main {
      padding: 20px !important; }
      .services .slider_main .top_inner_content .main_breadcrumbs {
        margin-bottom: 18px; }
      .services .slider_main .slider_title {
        margin-bottom: 0 !important; }
    .apartment .slider_main {
      padding-bottom: 181px !important; }
    .slider_main .slider_title {
      font-size: 26px;
      line-height: 1.1;
      font-weight: 400;
      padding-bottom: 16px !important;
      margin-bottom: 20px !important;
      text-transform: uppercase !important; }
      .slider_main .slider_title::after {
        width: 40px !important;
        height: 2px;
        margin-left: -20px !important; }
      .ranking .slider_main .slider_title,
      .contacts .slider_main .slider_title {
        padding-bottom: 0 !important; }
      .process .slider_main .slider_title,
      .tests .slider_main .slider_title {
        text-transform: uppercase;
        padding-bottom: 0; }
        .process .slider_main .slider_title::after,
        .tests .slider_main .slider_title::after {
          content: none; }
      .repair_premises .slider_main .slider_title {
        padding-bottom: 18px;
        margin-bottom: 20px; }
    .slider_main .block_favorites {
      text-align: left; }
      .slider_main .block_favorites .views_row {
        margin-right: 13px;
        margin-bottom: 20px; }
    .tests .slider_main .slider_list {
      margin-bottom: 0;
      text-align: left; }
      .tests .slider_main .slider_list .item {
        margin-bottom: 30px;
        padding: 0 10px;
        box-sizing: border-box; }
        .tests .slider_main .slider_list .item:last-child {
          margin-bottom: 0; }
    .slider_main .slider_subtitle {
      text-transform: none !important; }
    .slider_main .title_text {
      font: 500 14px Roboto;
      margin: 0 auto 25px; }
    .cleaning .slider_main {
      background-image: url(images/bg_mob.jpg) !important; }
      .cleaning .slider_main .slider_title {
        padding-bottom: 19px;
        margin-bottom: 19px; }
        .cleaning .slider_main .slider_title::after {
          width: 40px;
          margin-left: -20px; }
      .cleaning .slider_main .title_text {
        max-width: 171px; }
      .cleaning .slider_main .webform_client_form {
        width: 240px;
        margin: auto; }
        .cleaning .slider_main .webform_client_form .form_item {
          width: 100%;
          margin-bottom: 20px; }
        .cleaning .slider_main .webform_client_form .form_actions {
          width: 100%; }
          .cleaning .slider_main .webform_client_form .form_actions input {
            width: 100%; }
    .slider_main .webform_client_form {
      width: 240px;
      margin: auto; }
      .slider_main .webform_client_form .form_item {
        width: 100%;
        margin-bottom: 20px !important; }
      .slider_main .webform_client_form .form_actions {
        width: 100%; }
        .slider_main .webform_client_form .form_actions input {
          width: 100%; }
    .slider_main .slider_subtitle {
      font-size: 20px !important;
      margin-bottom: 20px !important; }
      .trust .slider_main .slider_subtitle {
        text-transform: none !important;
        margin-bottom: 14px !important; }
    .slider_main .text {
      width: 250px !important;
      margin: auto !important;
      margin-bottom: 24px !important;
      font: 500 14px Roboto !important; }
      .earth .slider_main .text {
        width: auto !important; }
    .repair_premises .slider_main .webform_client_form .form_actions input {
      font-size: 15px; }
    .front .slider_main .webform_client_form .form_item.phone {
      width: 100%;
      margin: 0 0 20px; }
    .front .slider_main .webform_client_form .form_actions input {
      font-size: 15px; }
  .sidebar_first #content {
    padding: 0; }
  .block_title {
    font-size: 26px;
    font-weight: 400; }
  .block_process {
    padding: 0 !important; }
    .block_process.during .block_content .block_title {
      margin-bottom: 25px; }
    .block_process .block_content {
      text-align: center; }
      .block_process .block_content .block_title {
        text-align: center;
        font-size: 26px;
        display: block; }
      .block_process .block_content .create {
        float: none;
        display: inline-block;
        vertical-align: middle; }
      .block_process .block_content .views_row {
        padding: 20px 0;
        text-align: left;
        box-sizing: border-box; }
        .block_process .block_content .views_row .left_wrap {
          float: none;
          width: 100%;
          margin: 0;
          padding: 0;
          text-align: center; }
        .block_process .block_content .views_row .right_wrap {
          padding: 0;
          border: none; }
          .block_process .block_content .views_row .right_wrap .webform_client_form {
            padding-bottom: 20px;
            padding-left: 20px;
            padding-right: 20px;
            box-sizing: border-box;
            margin-bottom: 20px;
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
            .block_process .block_content .views_row .right_wrap .webform_client_form .form_textarea {
              width: 100%;
              float: none;
              margin-bottom: 0; }
              .block_process .block_content .views_row .right_wrap .webform_client_form .form_textarea textarea {
                width: 100%;
                height: 104px;
                box-sizing: border-box; }
          .block_process .block_content .views_row .right_wrap .group {
            margin-bottom: 20px; }
        .block_process .block_content .views_row .text {
          margin-bottom: 20px;
          padding: 0 20px;
          box-sizing: border-box; }
        .block_process .block_content .views_row .title {
          padding: 0 20px;
          box-sizing: border-box; }
  body.process .block_banner,
  body.process .block_vip,
  body.process .block_services,
  body.process .region_banner {
    display: none; }
  .process .block_presentation {
    padding: 30px 0; }
    .process .block_presentation .block_content {
      max-width: 280px;
      margin: auto; }
      .process .block_presentation .block_content .block_title {
        margin-bottom: 18px; }
  .process .banner {
    background: #fff; }
  .sidebar_first .container,
  .left_sidebar {
    margin: 0;
    float: none;
    width: 100%; }
  .process .middle_inner {
    padding: 20px 10px 0; }
  .tests .middle_inner,
  .apartment .middle_inner {
    padding: 20px 10px 0; }
  .cleaning .middle_inner {
    padding-top: 0 !important; }
  .earth .middle_inner,
  .ranking .middle_inner {
    padding: 0 0; }
  .block_shops .views_row,
  .block_vip .views_row {
    width: 100%;
    margin: 0 0 20px; }
  body:not(.trust) .block .slick-list {
    margin: auto;
    box-sizing: border-box; }
    body:not(.trust) .block .slick-list .views_row {
      margin: 0 0px 20px !important; }
  .middle_inner,
  .block {
    padding: 20px;
    margin-bottom: 0;
    box-sizing: border-box; }
  .slick-dots {
    text-align: center; }
    .slick-dots li {
      display: inline-block;
      vertical-align: middle;
      margin: 0 12px; }
      .slick-dots li button {
        text-indent: -99999px;
        border-radius: 100%;
        overflow: hidden;
        width: 15px;
        height: 15px;
        box-shadow: inset 0 1px 3px #89919e;
        background-color: rgba(137, 145, 158, 0.1);
        outline: none;
        border: none; }
      .slick-dots li.slick-active button {
        border: 1px solid #18bb49;
        background-color: #ffffff;
        box-shadow: none; }
  .new_buildings .views_row,
  .block_search_results .views_row {
    width: 100%;
    margin: 0 0 20px !important; }
  .block_services {
    padding-bottom: 30px; }
    .block_services .block_title {
      max-width: 250px;
      margin: 0 auto 20px; }
    .block_services .views_row {
      margin: 0 auto 20px !important;
      float: none;
      display: block; }
      .block_services .views_row .field_image {
        display: none; }
      .block_services .views_row:last-child {
        margin-bottom: 0 !important; }
  .block_presentation {
    padding: 20px 25px; }
    .trust .block_presentation {
      padding-bottom: 55px; }
    .front .block_presentation {
      padding: 20px 1px; }
    .block_presentation .view_content {
      text-align: center; }
      .block_presentation .view_content form.webform_client_form .form_item,
      .block_presentation .view_content form.webform_client_form .form_textarea {
        width: 240px !important;
        margin: 0 auto 20px !important;
        display: block; }
      .block_presentation .view_content .form_actions input {
        width: 240px; }
    .block_presentation .block_title {
      line-height: 1.3;
      margin-bottom: 30px; }
      .block_presentation .block_title::after {
        width: 40px !important;
        margin-left: -20px !important; }
    .block_presentation .present_wrap {
      margin-bottom: 30px; }
    .block_presentation .see_present {
      font-size: 20px;
      background-position: center  left 11px;
      background-size: 52px;
      width: 270px; }
    .block_presentation .views_row {
      margin: 0 10px 30px; }
    .block_presentation .sub_title {
      font-size: 20px !important;
      text-transform: none;
      margin-bottom: 16px !important; }
    .block_presentation .title_text {
      font: 500 14px Roboto;
      margin-bottom: 25px; }
  .block_seo {
    padding-left: 17px;
    padding-right: 17px; }
    .block_seo .view_content {
      text-align: center; }
    .block_seo .body {
      text-align: center;
      font-size: 20px;
      line-height: 1.2; }
      .block_seo .body ul {
        text-align: left; }
      .block_seo .body .image {
        display: none; }
  .block_articles .slick-list {
    width: 270px; }
  .block_articles .view_content_inner {
    margin-bottom: 20px; }
  .block_articles .views_row {
    margin: 0px 0 20px !important; }
  .footer {
    height: 540px;
    margin-top: -540px;
    padding: 45px 20px 35px;
    text-align: center;
    box-sizing: border-box; }
    .footer .social_links {
      right: auto;
      left: 50%;
      margin: 0 0 0 -135px;
      width: 270px;
      top: -70px; }
    .footer .region_footer .logo_wrapper {
      float: none;
      margin: 0 auto;
      width: 100%;
      text-align: center; }
      .footer .region_footer .logo_wrapper .copyright {
        max-width: 210px;
        margin: auto; }
    .footer .region_footer .visit {
      margin: 25px auto 21px;
      width: 250px; }
      .footer .region_footer .visit .item {
        margin: 0 12px !important; }
    .footer .region_footer .footer_center {
      float: none;
      width: 100%;
      margin: 0; }
      .footer .region_footer .footer_center .menu_footer ul {
        display: none; }
    .footer .region_footer .footer_right {
      padding-top: 20px;
      float: none;
      clear: both;
      overflow: hidden; }
      .footer .region_footer .footer_right .buttons_header {
        float: none;
        margin: 0 auto 25px; }
      .footer .region_footer .footer_right .contacts_header {
        float: none; }
    .footer .footer_top {
      padding: 0;
      float: none;
      border: none; }
  .to_up {
    position: fixed;
    bottom: 15px;
    right: 15px;
    top: auto;
    left: auto; }
  .middle {
    padding-bottom: 540px; }
  .block_tests_wrapper {
    padding: 0; }
    .block_tests_wrapper .views_row {
      padding: 0 0 30px; }
      .block_tests_wrapper .views_row .view_row_inner {
        text-align: center; }
        .block_tests_wrapper .views_row .view_row_inner .read_more {
          display: inline-block;
          vertical-align: middle;
          text-transform: uppercase;
          text-align: center;
          color: #03a9f4;
          border: 2px solid #03a9f4;
          font: 500 18px/36px PFDinTextCompPro, sans-serif;
          height: 36px;
          background: #fff;
          overflow: hidden;
          box-sizing: border-box;
          transition: .3s; }
          .block_tests_wrapper .views_row .view_row_inner .read_more a {
            text-decoration: none;
            display: block;
            color: #03a9f4;
            padding: 0 20px;
            transition: .3s;
            box-sizing: border-box; }
            .block_tests_wrapper .views_row .view_row_inner .read_more a:hover {
              color: #fff;
              background: #03a9f4; }
      .block_tests_wrapper .views_row .right_wrap,
      .block_tests_wrapper .views_row .left_wrap {
        float: none;
        width: 100%; }
      .block_tests_wrapper .views_row .left_wrap {
        padding: 20px 25px;
        text-align: center;
        box-sizing: border-box; }
        .block_tests_wrapper .views_row .left_wrap .title {
          margin-bottom: 16px;
          font-weight: 400;
          font-size: 24px;
          line-height: 1.3; }
        .block_tests_wrapper .views_row .left_wrap .subtitle {
          margin-bottom: 20px; }
        .block_tests_wrapper .views_row .left_wrap ul {
          text-align: left; }
  .tests .load_more_wrap {
    margin-bottom: 30px; }
  .block_should_use .subtitle {
    color: #3a3f47;
    font-size: 20px;
    font-weight: 400;
    text-transform: lowercase;
    line-height: 1.3;
    padding-bottom: 18px;
    margin-bottom: 20px; }
  .block_should_use .block_title {
    margin-bottom: 25px; }
  .block_should_use .title_text {
    font: 500 14px Roboto;
    margin-bottom: 20px; }
  .block_should_use .view_content {
    width: 270px;
    margin: auto; }
  .block_should_use .views_row {
    margin-right: 0 !important; }
    .block_should_use .views_row .field_image .icon img {
      display: inline; }
  .block_we_have {
    padding-top: 28px; }
    .block_we_have .block_title {
      font-size: 26px;
      font-weight: 400;
      padding-bottom: 18px;
      margin-bottom: 20px; }
    .block_we_have .group {
      width: 100%;
      margin-bottom: 0; }
      .block_we_have .group li {
        margin-bottom: 24px; }
    .block_we_have .bottom_wrap .title {
      font-size: 26px;
      margin-bottom: 20px; }
    .block_we_have .webform_client_form {
      width: 240px;
      margin: auto; }
      .block_we_have .webform_client_form .form_item {
        width: 100%;
        margin-bottom: 20px; }
      .block_we_have .webform_client_form .form_actions {
        width: 100%; }
        .block_we_have .webform_client_form .form_actions input {
          width: 100%;
          font-size: 15px; }
  .block_video {
    margin-bottom: 4px !important;
    padding-left: 10px;
    padding-right: 10px; }
    .block_video iframe {
      height: 181px !important;
      width: 100% !important;
      margin: auto; }
    .insurance .block_video {
      margin-bottom: 20px !important; }
    .real_services .block_video,
    .repair_premises .block_video {
      margin-bottom: 30px !important; }
    .trust .block_video {
      padding-top: 30px;
      margin-bottom: 0; }
    .apartment .block_video {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none; }
  .block_package {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0; }
  .block_photo {
    padding-top: 0;
    padding-left: 10px;
    padding-right: 10px; }
    .block_photo .views_row {
      margin: 0 0 20px !important; }
  .block_benefits {
    background: none !important; }
    .repair_premises .block_benefits,
    .trust .block_benefits {
      padding-bottom: 0; }
    .block_benefits .block_title {
      margin-bottom: 27px; }
    .block_benefits .views_row {
      width: 100% !important;
      margin-bottom: 21px !important; }
      .block_benefits .views_row .field_img img {
        display: inline; }
  .block_benefits.steps .block_title {
    margin-bottom: 27px; }
  .block_benefits.steps .views_row {
    margin-bottom: 93px !important; }
    .block_benefits.steps .views_row::after {
      right: 50% !important;
      top: 117% !important;
      margin-right: -18px !important;
      width: 50px;
      background: url(images/arrow.png) no-repeat center left -143px;
      transform: rotate(90deg); }
    .block_benefits.steps .views_row:last-child::after {
      content: ''; }
  .block_benefits.steps .group {
    background: none !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important; }
    .block_benefits.steps .group::after {
      content: none; }
    .block_benefits.steps .group:last-child .views_row:last-child {
      margin-bottom: 0 !important; }
      .block_benefits.steps .group:last-child .views_row:last-child::after {
        content: none; }
  .block_benefits.steps.second .group:last-child .views_row::after,
  .real_services .block_benefits.steps .group:last-child .views_row::after, .property_valuation .block_benefits.steps .group:last-child .views_row::after {
    right: 50% !important;
    top: 117% !important;
    margin-right: -18px !important;
    width: 50px;
    background: url(images/arrow.png) no-repeat center left -143px;
    transform: rotate(90deg); }
  .real_services .block_benefits.steps .group:last-child .views_row:first-child::before,
  .property_valuation .block_benefits.steps .group:last-child .views_row:first-child::before {
    content: none; }
  .block_presentation.order_block {
    padding: 30px 0 !important;
    margin-bottom: 0 !important; }
  .real_services .block_presentation form.webform_client_form .form_item.price {
    margin-right: auto !important; }
  .block_questions .views_row .qty_text {
    padding-right: 0;
    line-height: 1.3; }
    .block_questions .views_row .qty_text .item {
      width: 100%; }
    .block_questions .views_row .qty_text span {
      display: none; }
  .block_seo {
    border-bottom: none !important; }
    .block_seo .block_title {
      margin-bottom: 20px !important; }
    .real_services .block_seo {
      background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center/cover; }
    .repair_premises .block_seo {
      margin-bottom: 10px !important; }
      .repair_premises .block_seo .content_body {
        height: 353px !important; }
    .services .block_seo {
      padding-top: 10px; }
      .services .block_seo .block_title {
        margin-bottom: 23px; }
    .earth .block_seo {
      padding-top: 30px; }
  .block_price_wrapper {
    padding: 20px 0 0;
    margin-bottom: 0;
    box-shadow: none;
    box-sizing: border-box; }
    .block_price_wrapper .thead {
      display: none; }
    .block_price_wrapper .tbody {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15); }
      .block_price_wrapper .tbody .views_row {
        padding: 17px 25px;
        border-bottom: 2px solid #e5e5e5; }
        .block_price_wrapper .tbody .views_row .left {
          width: auto;
          margin-right: 7px; }
        .block_price_wrapper .tbody .views_row .right {
          width: auto;
          text-align: left;
          float: left;
          position: relative; }
          .block_price_wrapper .tbody .views_row .right::before {
            content: '....';
            display: inline-block;
            vertical-align: bottom;
            margin-right: 7px; }
          .block_price_wrapper .tbody .views_row .right::after {
            content: 'грн';
            display: inline-block;
            vertical-align: bottom;
            margin-left: 7px; }
    .block_price_wrapper .calculate_form {
      padding-top: 30px;
      padding-bottom: 30px;
      background: rgba(255, 255, 255, 0.15) url(images/bg_footer.jpg) no-repeat center/cover; }
      .block_price_wrapper .calculate_form .title {
        font-size: 26px;
        font-weight: 400;
        padding: 0 20px;
        box-sizing: border-box; }
  .repair_premises .block_our_works {
    box-shadow: none;
    padding: 0 10px 7px;
    margin-bottom: 0;
    box-sizing: border-box;
    text-align: center; }
    .repair_premises .block_our_works .views_row {
      margin: 0 auto 18px;
      border: 1px solid #979797; }
    .repair_premises .block_our_works .footnote {
      margin: 20px auto 0;
      width: 260px;
      box-sizing: border-box; }
  .block_ready .view_content > div {
    margin-bottom: 20px; }
  .region_banner {
    padding-bottom: 55px; }
  .services .middle_inner,
  .trust .middle_inner {
    padding: 0 !important; }
  .block_services_wrap {
    padding-bottom: 0; }
    .block_services_wrap .views_row {
      height: auto;
      margin-bottom: 20px; }
      .block_services_wrap .views_row .left_wrap,
      .block_services_wrap .views_row .right_wrap {
        float: none;
        width: 100%; }
      .block_services_wrap .views_row.odd .left_wrap {
        padding: 17px 20px 20px !important; }
      .block_services_wrap .views_row:not(.odd) .right_wrap {
        padding: 17px 20px 20px !important; }
      .block_services_wrap .views_row .title {
        padding-bottom: 16px; }
  .block_vip_shops .view_content .views_row {
    width: 100%;
    margin: 0 0 20px !important; }
    .block_vip_shops .view_content .views_row .info.price {
      display: block;
      vertical-align: top;
      margin: 0 0 17px 0;
      width: auto;
      float: none;
      padding: 0;
      font: 400 24px 'PFDinTextCompPro', sans-serif; }
      .block_vip_shops .view_content .views_row .info.price .label {
        font: 700 14px Roboto, sans-serif;
        color: #18bb49;
        display: block;
        margin-bottom: 8px; }
  .block_numbers {
    margin-bottom: 0; }
    .block_numbers .views_row {
      width: 100%;
      margin: 0 0 30px; }
      .block_numbers .views_row:last-child {
        margin-bottom: 0; }
  .block_diplomas {
    padding-top: 20px;
    margin-bottom: 19px !important; }
    .block_diplomas .views_row {
      width: 100%;
      max-width: 100%;
      margin: 0 0 20px; }
      .block_diplomas .views_row img {
        margin: auto;
        display: block; }
  .block_reviews {
    padding: 0 8px;
    box-sizing: border-box; }
    .block_reviews .slider_video {
      margin-bottom: 25px; }
      .block_reviews .slider_video .views_row {
        margin: 0 1px 20px;
        height: 181px; }
        .block_reviews .slider_video .views_row iframe {
          height: 100% !important; }
  .block_questions {
    padding: 30px 20px; }
  .search_results {
    padding-bottom: 30px; }
    .search_results .form_title {
      font-size: 26px !important;
      font-weight: 400 !important;
      margin: 0 0 23px !important;
      padding-bottom: 0 !important;
      text-align: center; }
    .search_results .webform_client_form {
      border-bottom: none; }
      .search_results .webform_client_form .form_number input {
        width: 90px; }
      .search_results .webform_client_form .form_select {
        width: 100%; }
        .search_results .webform_client_form .form_select select {
          width: 100% !important; }
      .search_results .webform_client_form .form_actions .links {
        margin: 27px 0 0 26px !important;
        float: right; }
      .search_results .webform_client_form .form_actions::after {
        top: 14px !important; }
    .search_results .left_wrap {
      margin-bottom: 25px;
      float: none; }
    .search_results .right_wrap {
      float: none;
      margin-bottom: 0px; }
    .search_results .filters .item {
      margin-bottom: 18px; }
  .block_earth {
    padding: 0 10px; }
    .block_earth .views_row {
      padding: 5px 20px 20px; }
      .block_earth .views_row .title {
        font-size: 24px;
        font-weight: 400; }
      .block_earth .views_row .top_wrap {
        margin-bottom: 23px; }
        .block_earth .views_row .top_wrap .left_wrap {
          float: none;
          width: 100%;
          margin-bottom: 20px;
          height: 267px;
          overflow: hidden; }
          .block_earth .views_row .top_wrap .left_wrap iframe {
            height: 100% !important; }
          .block_earth .views_row .top_wrap .left_wrap img {
            height: 100%;
            max-width: none; }
        .block_earth .views_row .top_wrap .right_wrap {
          float: none;
          width: 100%;
          height: 267px; }
          .block_earth .views_row .top_wrap .right_wrap .slick-list {
            width: 100% !important; }
            .block_earth .views_row .top_wrap .right_wrap .slick-list img {
              height: 100%;
              max-width: none; }
      .block_earth .views_row .bottom_wrap .left_wrap {
        float: none; }
      .block_earth .views_row .bottom_wrap .center_wrap {
        width: auto;
        float: none;
        margin-bottom: 30px; }
      .block_earth .views_row .bottom_wrap .right_wrap {
        width: 100%;
        max-width: 100%;
        float: none; }
        .block_earth .views_row .bottom_wrap .right_wrap .contacts .btn {
          float: none; }
  .icons_wrap {
    overflow: hidden;
    padding-bottom: 10px; }
    .icons_wrap::before {
      right: -159px; }
    .icons_wrap::after {
      left: -159px; }
  .slider_for .item {
    height: auto; }
  .slider_for .slick-arrow {
    display: none !important; }
  .slider_nav {
    padding: 0 19px; }
    .slider_nav .item {
      margin: 0 7px; }
    .slider_nav .slick-arrow {
      width: 15px;
      height: 29px;
      opacity: .5;
      margin-top: -18px;
      background-size: contain; }
      .slider_nav .slick-arrow.slick-prev {
        left: 0; }
      .slider_nav .slick-arrow.slick-next {
        right: 0; }
  .block_appartament_slider {
    padding: 10px 10px 20px;
    margin-bottom: 20px; }
  .block_inform_appartament {
    background: none;
    padding: 0;
    box-shadow: none; }
    .block_inform_appartament .left_wrap,
    .block_inform_appartament .right_wrap {
      float: none;
      width: 100%;
      margin-bottom: 20px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      background: #fff;
      padding: 18px 20px 20px;
      box-sizing: border-box; }
    .block_inform_appartament .left_wrap .price_wrapper div {
      margin-bottom: 15px;
      width: 100%; }
    .block_inform_appartament .left_wrap .price_wrapper span {
      transform: none;
      width: 49px;
      height: 1px;
      margin: 0 0 20px; }
    .block_inform_appartament .left_wrap .char_wrapper .group {
      float: none;
      width: 100%; }
      .block_inform_appartament .left_wrap .char_wrapper .group + .group {
        margin-top: 40px; }
    .block_inform_appartament .ya-share2__container_size_m .ya-share2__icon {
      width: 21px;
      height: 21px; }
  .block_body {
    margin-bottom: 20px; }
    .block_body .title {
      text-transform: none;
      font-size: 24px;
      font-weight: 400;
      float: none;
      width: auto;
      margin: 0 0 10px; }
  .block_presentation.order_block .block_title {
    margin-bottom: 28px; }
  .apartment .block_presentation:not(.block_picking) .webform_client_form .form_item, .daily_inner .block_presentation:not(.block_picking) .webform_client_form .form_item {
    margin-right: auto !important; }
  .block_maps {
    margin: 20px 10px 0;
    background: none;
    box-shadow: none;
    padding: 0; }
    .block_maps .map {
      margin-right: 0 !important;
      width: 100%;
      padding: 20px;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
      box-sizing: border-box; }
      .block_maps .map .title {
        margin-bottom: 10px; }
  .new_buildings .views_row .left_wrap,
  .new_buildings .views_row .right_wrap {
    float: none;
    width: 100%; }
    .new_buildings .views_row .left_wrap .info,
    .new_buildings .views_row .right_wrap .info {
      clear: both; }
  .new_buildings .views_row .address {
    float: left;
    margin-bottom: 20px; }
  .new_buildings .views_row .statistics {
    float: right;
    margin-bottom: 20px; }
  .new_buildings .views_row .read_more {
    float: right;
    margin-top: 10px; }
  .new_buildings .views_row .right_wrap .price {
    margin: 23px 20px 0 0;
    overflow: hidden; }
  .apartment .block_vip_shops {
    padding-top: 5px; }
  .property_search {
    padding-bottom: 30px;
    border-bottom: 2px solid #e5e5e5; }
    .property_search .form_title {
      text-align: center;
      font-size: 26px;
      font-weight: 400; }
    .property_search .webform_client_form .price_currency {
      width: 30%; }
  .search_results {
    padding-top: 30px; }
  .block_search_results .views_row .views_body .price {
    margin-top: 0;
    margin-bottom: 20px; }
    .block_search_results .views_row .views_body .price .label {
      display: block;
      margin-bottom: 10px; }
  .block_search_results .views_row .views_body .statistics,
  .block_vip_shops .views_row .views_body .statistics {
    margin-top: 25px; }
  .ranking .block_vip {
    padding-top: 10px; }
    .ranking .block_vip .block_title {
      text-align: center;
      font-size: 24px;
      font-weight: 400; }
    .ranking .block_vip .views_row .views_body .address {
      margin-bottom: 24px; }
  .ranking .block_services {
    background: #fff;
    border-bottom: 2px solid #e5e5e5; }
    .ranking .block_services .block_title {
      text-align: center;
      font-size: 24px;
      font-weight: 400; }
  .ranking .block_seo {
    padding-top: 30px; }
  .ranking .block_presentation {
    padding-bottom: 55px; }
  .block_presentation .block_title {
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box; }
  .articles .block_search_results {
    margin-bottom: 20px;
    padding: 0; }
    .articles .block_search_results .slick-dots {
      margin-bottom: 20px; }
  .articles .block_seo {
    padding-top: 30px; }
  .left_sidebar .block_left_menu {
    padding: 0;
    margin-bottom: 20px; }
  .left_sidebar .banner {
    margin-bottom: 20px; }
  .left_sidebar .block_services {
    background: #fff;
    border-bottom: 2px solid #e5e5e5; }
    .left_sidebar .block_services .block_title {
      text-align: center;
      font-size: 24px;
      font-weight: 400; }
  .articles_inner .top_inner_content .main_breadcrumbs {
    margin-bottom: 25px; }
  .articles_inner .slider_title {
    margin-bottom: 5px !important; }
  .social-likes_vertical .social-likes__widget {
    min-width: 45px;
    height: 45px; }
  .social-likes {
    margin-bottom: -68px; }
  .block_node_content .field_body iframe {
    height: 181px !important; }
  .articles_inner #content {
    margin-bottom: 20px; }
  .block_articles {
    padding: 0 20px;
    margin-bottom: 20px; }
    .block_articles .block_title {
      margin-bottom: 20px; }
    .block_articles .view_content {
      float: none; }
    .front .block_articles {
      padding-bottom: 55px; }
  .webform_client_form .form_textarea {
    margin-bottom: 20px !important; }
  .contacts .slider_main .top_inner_content .title {
    margin-bottom: 20px; }
  .block_work_client {
    margin-bottom: 22px; }
    .block_work_client .title {
      margin-bottom: 20px;
      text-align: center;
      font-size: 24px;
      font-weight: 400; }
    .block_work_client .views_row {
      width: 100%;
      margin-bottom: 20px; }
  .block_contacts_specialists .views_row_inner .left_wrap {
    float: none;
    margin-right: 0;
    margin-bottom: 20px; }
  .block_contacts_specialists .views_row_inner .right_wrap .name {
    text-align: center;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 19px; }
  .block_contacts_specialists .views_row_inner .right_wrap .about_block .field_item {
    width: 100%; }
  .block_contacts_specialists .views_row_inner .right_wrap .video_field iframe {
    height: 181px !important; }
  .contacts .block_vip_shops,
  .contacts .middle_inner {
    padding: 20px 10px; }
  .block_accommodations .left_wrap,
  .block_accommodations .rigth_wrap {
    width: 100%;
    float: none; }
  .block_accommodations .rigth_wrap .group {
    width: 100%;
    margin-bottom: 0; }
  .daily_inner .block_video {
    margin-bottom: 20px !important; }
  .daily_inner .block_daily_products {
    padding: 0 10px 20px; }
    .daily_inner .block_daily_products .block_title {
      text-align: center;
      margin-bottom: 20px;
      font-size: 24px;
      font-weight: 400; }
  .block_daily_products.block_search_results .view_content .views_row {
    margin: 0 0 20px !important; }
  .favorites .middle_inner {
    padding: 20px 10px;
    box-sizing: border-box; }
  .favorites .block_shops {
    padding: 0;
    margin-bottom: 20px; }
    .favorites .block_shops .block_title {
      font-size: 24px;
      text-align: center;
      font-weight: 400; }
    .favorites .block_shops .views_row .views_body .price {
      float: none;
      width: 100%;
      margin-bottom: 20px; }
  .favorites .block_newbuilding_row .block_content .view_content .views_row .left_wrap {
    width: 100%;
    margin-right: 0; }
    .favorites .block_newbuilding_row .block_content .view_content .views_row .left_wrap .views_body .text {
      margin-bottom: 20px; }
  .favorites .block_newbuilding_row .block_content .view_content .views_row .right_wrap {
    overflow: visible;
    clear: both;
    float: none;
    width: 100%;
    text-align: center; }
    .favorites .block_newbuilding_row .block_content .view_content .views_row .right_wrap .field_img img {
      margin: 0 auto 7px; }
  .favorites .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap {
    width: auto;
    margin-top: 15px;
    margin-bottom: 20px; }
  .favorites .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap {
    clear: none;
    width: auto; }
    .favorites .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .statistics {
      float: none;
      width: 100%;
      margin-top: 25px; }
    .favorites .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more {
      font-size: 16px; }
      .favorites .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more a {
        padding: 0 10px; }
  .favorites .block_earth {
    padding: 0; }
    .favorites .block_earth .block_title {
      font-size: 24px !important;
      text-align: center !important;
      font-weight: 400 !important; }
  .final .slider_main .slider_list {
    margin-bottom: 0; }
    .final .slider_main .slider_list .item {
      width: 100%;
      margin-bottom: 20px; }
  .final .social-likes_vertical .social-likes__widget {
    margin: 0 5px; }
  .final .middle_inner {
    padding: 0; }
  .final .block_final_wrap {
    padding: 10px; }
    .final .block_final_wrap .group_title {
      font-size: 24px !important;
      text-align: center !important;
      font-weight: 400 !important; }
    .final .block_final_wrap .views_row .webform_client_form .left_wrap {
      width: 100%;
      float: none;
      margin-bottom: 20px; }
      .final .block_final_wrap .views_row .webform_client_form .left_wrap .text {
        margin-bottom: 20px; }
  #block-webform {
    left: 0;
    margin-left: 0;
    margin-top: 0 !important;
    top: 0;
    overflow-y: scroll;
    height: 100%; }
    #block-webform .webform_client_form .top_wrap {
      margin-bottom: 0; }
      #block-webform .webform_client_form .top_wrap .form_item {
        width: 100%;
        margin: 0 0 20px; }
    #block-webform .webform_client_form .bottom_wrap .form_item.textarea {
      width: 100%;
      float: none;
      margin-right: 0 !important;
      margin-bottom: 20px; }
    #block-webform .webform_client_form .bottom_wrap .form_item.form_select {
      margin-bottom: 20px;
      width: 100%; }
    #block-webform .block_content > .close {
      width: 30px;
      height: 30px;
      top: 10px;
      right: 15px; }
    #block-webform .filters {
      margin-bottom: 0; }
      #block-webform .filters .item {
        margin: 0 20px 20px 0; }
  .block_webform.webform_object .webform_client_form .form_item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px; }
  .block_webform.webform_object .webform_client_form .form_actions {
    width: 100%; }
    .block_webform.webform_object .webform_client_form .form_actions input {
      width: 100%; }
  .block_webform#block-webform-confirm {
    width: 320px;
    margin-left: -160px; }
    .block_webform#block-webform-confirm .close {
      top: 11px;
      right: 10px; }
    .block_webform#block-webform-confirm .webform_confirmation {
      text-align: center;
      padding: 0 30px 0 0;
      box-sizing: border-box; }
  .block_compensate {
    padding: 0; }
    .block_compensate .views_row {
      margin-right: 0; }
  .block_our_advantages {
    padding: 0; }
  .insurance .middle_inner {
    padding: 20px 10px; }
  .block_our_advantages .views_row {
    width: 100%; }
  .multilending .header .benefits .item,
  .superlending .header .benefits .item {
    padding-right: 0; }
  .multilending .search_results,
  .superlending .search_results {
    padding: 20px 10px; }
  .multilending .middle_inner,
  .superlending .middle_inner {
    padding: 20px 0; }
  .multilending .block_search_results,
  .superlending .block_search_results {
    padding: 20px 10px 0; }
  .multilending .block_seo,
  .superlending .block_seo {
    padding-top: 20px !important; }
  .multilending .block_search_results .views_row,
  .superlending .block_search_results .views_row {
    margin: 0 auto 20px !important; }
  .superlending .header .region_header .buttons_header,
  .superlending_inner .header .region_header .buttons_header {
    float: none; }
  .block_newbuilding_row {
    padding: 0;
    margin-bottom: 20px; }
    .block_newbuilding_row .block_content .view_content .views_row .left_wrap,
    .block_newbuilding_row .block_content .view_content .views_row .right_wrap {
      width: 100%;
      margin: 0;
      float: none; }
  .block_newbuilding_row .block_content .view_content .views_row .right_wrap .field_img img {
    margin: 0 auto 7px; }
  .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .left_wrap {
    width: auto;
    margin-right: 20px;
    float: left; }
  .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap {
    clear: none;
    width: auto; }
    .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .statistics {
      width: 100%;
      text-align: left;
      margin-bottom: 20px; }
  .newbuilding .block_seo {
    padding-bottom: 55px; }
  .newbuilding_inner .slider_main {
    padding-bottom: 180px !important; }
  .newbuilding_inner .block_inform_appartament .left_wrap {
    width: 100%; }
  .newbuilding_inner .block_free {
    margin-bottom: 0px; }
  .plan .slider_main .slider_list,
  .ranking_daily .slider_main .slider_list {
    margin-bottom: 20px;
    text-align: center; }
    .plan .slider_main .slider_list .item,
    .ranking_daily .slider_main .slider_list .item {
      margin-bottom: 20px;
      width: 100%;
      margin-right: 10px;
      text-align: left; }
  .property_valuation .block_presentation form.webform_client_form .form_item.email, .real_services .block_presentation form.webform_client_form .form_item.email {
    margin-right: auto !important; }
  .property_valuation .block_presentation {
    margin-bottom: 20px; }
  .property_valuation .block_benefits.steps,
  .property_valuation .block_services {
    padding: 20px 10px; }
  .block_hydrometers {
    text-align: center; }
    .block_hydrometers .views_row {
      margin-right: 0; }
  .installation_water .block_video {
    margin-bottom: 20px !important; }
  .installation_water .block_package .views_row:last-child {
    margin-bottom: 0; }
  .header .benefits .item {
    padding-right: 0; }
  .ranking_daily .block_daily_products {
    padding: 20px 0px; }
  .ranking_daily .block_seo {
    padding-top: 0; }
  .superlending .search_results form.webform_client_form .form_checkboxes {
    position: static; }
  .superlending .search_results form.webform_client_form {
    padding: 0 10px; }
    .superlending .search_results form.webform_client_form .form_item.form_select {
      width: 100%; }
    .superlending .search_results form.webform_client_form .form_select.currency,
    .superlending .search_results form.webform_client_form .area_wrap .form_select {
      width: 30%; }
    .superlending .search_results form.webform_client_form .form_wrapper {
      width: 100%; }
  .superlending .search_results form.webform_client_form .form_item {
    margin: 0 0 20px; }
  .superlending .block_presentation:not(.second) .block_title {
    margin-bottom: 20px; }
  .superlending .block_presentation:not(.second) form.webform_client_form .form_item {
    margin: 0 auto 20px !important; }
  .superlending .block_presentation.second {
    padding-bottom: 55px; }
  .superlending_inner .footer,
  .superlending .footer {
    height: 440px;
    margin-top: -440px; }
  .superlending_inner .middle,
  .superlending .middle {
    padding-bottom: 440px; }
  .superlending_inner .block_video {
    margin-bottom: 20px !important; }
  .superlending_inner .block_vip_shops {
    padding-left: 10px;
    padding-right: 10px; }
  .test_passing .slider_main {
    padding-bottom: 130px !important; }
  .test_result .block_tests_wrapper .views_row {
    padding: 20px 10px; }
  .universal .block_advantages_1 {
    padding: 0;
    text-align: center; }
    .universal .block_advantages_1 .views_row {
      margin: 0 10px 30px; }
  .video_inner .slider_main {
    padding-bottom: 170px !important; }
    .video_inner .slider_main .come_back {
      position: static;
      float: none;
      margin-bottom: 25px; }
  .video_inner .block_video {
    padding: 10px; }
  .video_inner .middle_inner {
    padding: 10px; }
  .video_inner .block_body .left_wrap {
    width: 100%;
    margin: 0 0 20px;
    float: none; }
  .video_transfer .block_video_transfer {
    padding: 0; }
    .video_transfer .block_video_transfer .views_row {
      padding: 18px 10px;
      text-align: center; }
    .video_transfer .block_video_transfer .field_image {
      float: none;
      margin: 0 auto 20px; }
    .video_transfer .block_video_transfer .field_nid {
      text-align: left; }
    .video_transfer .block_video_transfer .load_more {
      margin-bottom: 30px; }
  .video_transfer .middle_inner {
    padding: 10px; }
  .front .block_shops {
    padding: 20px 10px; }
    .front .block_shops .view_content .slick-list {
      padding: 0px; }
      .front .block_shops .view_content .slick-list .views_row {
        margin: 0 10px 20px !important; }
  .block_webform.block_order_form .webform_client_form .top_wrap {
    float: none;
    margin: 0; }
  .articles_inner .social-likes_vertical .social-likes__widget {
    margin: 0 5px; }
  .block_services .views_row .field_title a {
    font-size: 22px; }
  .block_should_use {
    padding: 20px 0; }
  .block_vip_shops .view_content .views_row .views_body .text {
    font-size: 14px; }
  input, textarea, select {
    border-radius: 0; }
  .insurance .block_seo {
    margin-bottom: 20px; }
  .multilending .search_results {
    margin-top: 0; }
    .multilending .search_results .form_select.currency {
      width: 30%; }
    .multilending .search_results form.webform_client_form .form_item {
      margin: 0 0 20px; }
    .multilending .search_results .form_wrapper {
      width: 100%; }
  .multilending .block_presentation {
    padding-bottom: 55px; }
  .search_results .form_item.metro {
    width: 100%;
    margin-bottom: 20px !important; }
  .newbuilding .middle_inner {
    padding: 20px 10px; }
  .newbuilding .see_more {
    margin-bottom: 20px; }
  .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview {
    font-size: 16px; }
    .block_newbuilding_row .block_content .view_content .views_row .bottom_wrap .right_wrap .read_more.preview a {
      padding: 0 10px; }
  .webform_client_form .form_number input {
    width: 76px; }
  .slider_for {
    margin-bottom: 0; }
  .block_inform_appartament .right_wrap .contacts_person .label {
    font-size: 16px;
    margin-top: 2px; }
  .ya-share2__list_direction_horizontal > .ya-share2__item {
    margin-bottom: 5px !important; }
  .newbuilding_inner .block_maps {
    margin-bottom: 0; }
  .newbuilding_inner .block_vip_shops .block_title {
    text-align: center;
    font-size: 24px; }
  .plan .block_benefits,
  .property_valuation .block_benefits {
    padding-bottom: 0; }
  .plan .block_video,
  .property_valuation .block_video {
    margin-bottom: 30px !important; }
  .process .banner {
    padding-bottom: 55px; }
  .property_valuation .block_presentation form.webform_client_form .form_actions input {
    font-size: 18px; }
  .ranking .block_search_results .views_row .views_body .read_more a {
    font-size: 14px; }
  .search_results.search_products .webform_client_form .form_wrapper {
    margin-right: 0; }
  .search_results .filters {
    max-width: 100%;
    float: none;
    margin-right: 0; }
  .superlending .webform_client_form .form_number {
    margin-right: 0; }
  .superlending .block_presentation {
    padding-left: 0;
    padding-right: 0; }
    .superlending .block_presentation.second {
      padding-right: 15px;
      padding-left: 15px; }
  .apartment .block_vip_shops .block_title {
    text-align: center;
    font-size: 24px; }
  .trust .slider_main {
    margin-bottom: 20px; }
  .universal .block_seo {
    margin-bottom: 20px; } }

@media (min-width: 330px) and (max-width: 374px) {
  .block_seo .content_body {
    height: 330px; } }

@media (min-width: 375px) and (max-width: 768px) {
  .block_seo .content_body {
    height: 301px; } }
